import { noop } from '../toolkit'
import { createEventProperty } from './eventProperty'
import { storageKey } from './storageKey'
import type { Tracker } from './type'

const eventProperty = createEventProperty()

export const consoleTracker = {
  key: storageKey,
  init: async ({ ipGetter, userPropertiesGetter }) => {
    ipGetter?.()
      .then((ip) => ip && consoleTracker.setUserIp(ip))
      .catch(noop)
    userPropertiesGetter?.()
      .then((userProperties) => consoleTracker.setUserProperties(userProperties))
      .catch(noop)
  },
  track: (event, eventProperties) => {
    console.info('[DEBUG] consoleTracker\n', event, eventProperty.format(event, eventProperties))
  },
  setUserId: (userId: string) => (eventProperty.userId = userId),
  setDeviceId: (deviceId: string) => (eventProperty.deviceId = deviceId),
  setUserIp: (userIp: string) => (eventProperty.userIp = userIp),
  setUserProperties: (userProperties: Record<string, unknown>) => (eventProperty.userProperties = userProperties),
} as Tracker
