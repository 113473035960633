import type { ReactNode } from 'react'
import { cloneElement, isValidElement } from 'react'

import type { IconProps } from '@lbox/shared/components'
import { polymorphicFactory } from '@lbox/shared/utils'

import cn from 'classnames'

import { LoadingSpinnerIcon } from './LoadingSpinnerIcon'
import { twMergeLDS } from '../../../utils/twMergeLDS'

type ButtonWidth = 'full' | 'fit'
export type ButtonSize = 'small' | 'medium' | 'large'
export type ButtonType = 'filled' | 'tonal' | 'outlined' | 'ghost' | 'destructive-filled' | 'destructive-outlined'

export interface ButtonProps {
  buttonType: ButtonType
  /** 버튼 label text */
  label?: string
  /** 버튼 크기 */
  size?: ButtonSize
  /** 버튼 너비 */
  width?: ButtonWidth
  /** 버튼 왼쪽 아이콘 */
  leftIcon?: ReactNode
  /** 버튼 오른쪽 아이콘 */
  rightIcon?: ReactNode
  /** 버튼 비활성화 여부 */
  disabled?: boolean
  /** 로딩 스피너 노출 여부. true인 경우 버튼은 disabled 상태가 됩니다 */
  isLoading?: boolean
}

/**
 * 모든 버튼의 토대가 되는 버튼입니다. 요소가 추가 될 경우, 해당버튼을 수정해서 전체 버튼에 반영합니다.
 * Base버튼은 재료가 되는 버튼으로 이를 다시 컴포넌트화 하거나 컴포넌트를 깨서 인스턴트화 할 때 그 재료가 되는 Base 버튼에 스타일을 수정하지 않습니다.
 * 다만, State를 정의하는 State layer color를 적용할 때이 Base 버튼에 적용하게 됩니다.
 */
export const Button = polymorphicFactory<{ defaultAs: 'button'; defaultRef: HTMLButtonElement; props: ButtonProps }>(
  function Button(props, forwardedRef) {
    const {
      className: classNameProp,
      as,
      label,
      buttonType,
      size = 'large',
      width = 'fit',
      leftIcon,
      rightIcon,
      disabled = false,
      isLoading = false,
      type,
      ...rest
    } = props

    const Component = as || 'button'

    const iconClassName = cn(
      { 'fill-foreground-primary-on-brand': buttonType === 'filled' },
      { 'group-enabled:fill-foreground-tertiary group-disabled:fill-foreground-mute': buttonType === 'tonal' },
      { 'group-enabled:fill-foreground-tertiary group-disabled:fill-foreground-mute': buttonType === 'outlined' },
      { 'group-enabled:fill-foreground-tertiary group-disabled:fill-foreground-mute': buttonType === 'ghost' },
      { 'fill-foreground-white': buttonType === 'destructive-filled' },
      {
        'group-enabled:fill-foreground-error group-disabled:fill-foreground-error-mute':
          buttonType === 'destructive-outlined',
      }
    )

    const LeftIcon = isValidElement<IconProps>(leftIcon)
      ? cloneElement(leftIcon, { ...leftIcon.props, className: twMergeLDS(iconClassName, leftIcon.props.className) })
      : leftIcon
    const RightIcon = isValidElement<IconProps>(rightIcon)
      ? cloneElement(rightIcon, {
          ...rightIcon.props,
          className: twMergeLDS(iconClassName, rightIcon.props.className),
        })
      : rightIcon

    return (
      <Component
        ref={forwardedRef}
        className={twMergeLDS(
          cn(
            'group flex items-center justify-center',
            'rounded-[4px]',
            'whitespace-nowrap text-lds2-body2-medium-trimmed',
            'cursor-pointer',
            'disabled:cursor-not-allowed',
            'bg-gradient-to-r from-transparent to-transparent',
            'focus-visible:focused-double-ring',
            {
              // buttonType === 'filled'
              'bg-background-brand text-text-primary-on-brand': buttonType === 'filled',
              'interaction-state-high': buttonType === 'filled',
              'disabled:bg-background-brand-mute': buttonType === 'filled',
            },
            {
              // buttonType === 'tonal'
              'bg-background-mute text-text-primary': buttonType === 'tonal',
              'interaction-state-high': buttonType === 'tonal',
              'disabled:bg-background-mute disabled:text-text-mute': buttonType === 'tonal',
            },
            {
              // buttonType === 'outlined'
              'bg-background-primary text-text-primary': buttonType === 'outlined',
              'border border-border-primary': buttonType === 'outlined',
              'interaction-state-high': buttonType === 'outlined',
              'disabled:bg-background-mute disabled:text-text-mute': buttonType === 'outlined',
            },
            {
              // buttonType === 'ghost'
              'text-text-primary': buttonType === 'ghost',
              'interaction-state-high': buttonType === 'ghost',
              'disabled:text-text-mute': buttonType === 'ghost',
            },
            {
              // buttonType === 'destructive-filled'
              'bg-utility-rose-dark text-text-white': buttonType === 'destructive-filled',
              'interaction-state-high': buttonType === 'destructive-filled',
              'disabled:bg-utility-rose-medium': buttonType === 'destructive-filled',
            },
            {
              // buttonType === 'destructive-outlined'
              'bg-background-primary text-utility-rose-dark': buttonType === 'destructive-outlined',
              'interaction-state-high': buttonType === 'destructive-outlined',
              'border border-utility-rose-lighter': buttonType === 'destructive-outlined',
              'disabled:text-utility-rose-medium': buttonType === 'destructive-outlined',
            },
            {
              // width
              'w-fit': width === 'fit',
              'w-full': width === 'full',
            },
            {
              // small
              'px-[10px]': size === 'small' && !(leftIcon || isLoading) && !rightIcon,
              'pl-[6px] pr-[10px]': size === 'small' && (leftIcon || isLoading) && !rightIcon,
              'pl-[10px] pr-[6px]': size === 'small' && !(leftIcon || isLoading) && rightIcon,
              'px-[6px]': size === 'small' && (leftIcon || isLoading) && rightIcon,

              'min-h-[32px]': size === 'small',
              'max-h-[32px]': size === 'small',

              'gap-[4px]': size === 'small',

              'text-lds2-body3-medium-trimmed': size === 'small',
            },
            {
              // medium
              'px-[19px]': size === 'medium' && !(leftIcon || isLoading) && !rightIcon,
              'pl-[9px] pr-[19px]': size === 'medium' && (leftIcon || isLoading) && !rightIcon,
              'pl-[19px] pr-[9px]': size === 'medium' && !(leftIcon || isLoading) && rightIcon,
              'px-[9px]': size === 'medium' && (leftIcon || isLoading) && rightIcon,

              'min-h-[40px]': size === 'medium',
              'max-h-[40px]': size === 'medium',

              'gap-[10px]': size === 'medium',

              'text-lds2-body2-medium-trimmed': size === 'medium',
            },
            {
              // large
              'px-[24px]': size === 'large' && !(leftIcon || isLoading) && !rightIcon,
              'pl-[12px] pr-[24px]': size === 'large' && (leftIcon || isLoading) && !rightIcon,
              'pl-[24px] pr-[12px]': size === 'large' && !(leftIcon || isLoading) && rightIcon,
              'px-[12px]': size === 'large' && (leftIcon || isLoading) && rightIcon,

              'min-h-[48px]': size === 'large',
              'max-h-[48px]': size === 'large',

              'gap-[12px]': size === 'large',

              'text-lds2-body1-medium-trimmed': size === 'large',
            },
            `${classNameProp}`
          )
        )}
        type={Component === 'button' ? (type ? type : 'button') : undefined}
        disabled={disabled || isLoading}
        {...rest}
      >
        {(isLoading || LeftIcon) && (
          <div>
            {!isLoading && LeftIcon}
            {isLoading && (
              <LoadingSpinnerIcon
                size={size === 'small' ? 20 : size === 'medium' ? 22 : 24}
                className={cn('animate-spin', {
                  'stroke-foreground-brand': !['destructive-filled', 'destructive-outlined'].includes(buttonType),
                  'stroke-foreground-secondary': ['destructive-filled', 'destructive-outlined'].includes(buttonType),
                })}
              />
            )}
          </div>
        )}

        {label && <p>{label}</p>}

        {RightIcon && <div className={cn('flex items-center')}>{RightIcon}</div>}
      </Component>
    )
  }
)
