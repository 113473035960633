import { API_ENDPOINT } from '../../constants/api/endpoint'
import type { User } from '../../types/user/user'
import { lboxHttpClient } from '../../utils/api'

export const postUserLogout = ({ deviceId }: { deviceId: string }) => {
  return lboxHttpClient.post(API_ENDPOINT.user.logout, null, { params: { deviceId } })
}

// Heedo: userData는 태초부터 any였기에...
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export async function postUserUpdateData(userData: any): Promise<User> {
  const { data } = await lboxHttpClient.post(API_ENDPOINT.user.updateData, userData)
  return data
}
