import { isClient } from '@toss/utils'

export const LFIND_API_HOST =
  isClient() &&
  (location.hostname.includes('-pg.') || location.hostname.includes('dev.') || location.hostname.includes('localhost'))
    ? 'be.dev.lfind.kr'
    : 'be.lfind.kr'

export const LFIND_HOST =
  isClient() &&
  (location.hostname.includes('-pg.') || location.hostname.includes('dev.') || location.hostname.includes('localhost'))
    ? 'dev.lfind.kr'
    : 'lfind.kr'
