import FlagProvider from '@unleash/proxy-client-react'

import type { StrictPropsWithChildren } from '../../../types'

interface FeatureFlagProviderProps {
  url: string
  clientKey: string
  refreshInterval?: number
  appName: string
}

export const FeatureFlagProvider = ({ children, ...props }: StrictPropsWithChildren<FeatureFlagProviderProps>) => {
  const { refreshInterval = 15 } = props

  return (
    <FlagProvider
      config={{
        ...props,
        refreshInterval,
      }}
    >
      {children}
    </FlagProvider>
  )
}
