import type { ReactNode, Ref } from 'react'
import { forwardRef } from 'react'

import type { StrictPropsWithChildren } from '@lbox/shared/types'

import cn from 'classnames'

import { twMergeLDS } from '../../../utils/twMergeLDS'

export interface ButtonBoxProps {
  rootClassName?: string
  className?: string
  type?: 'horizontal' | 'vertical' | 'horizontal-reverse' | 'vertical-reverse'
  topComponent?: ReactNode
  topComponentClassName?: string
}

/**
 * 버튼이 상위레이어에서 상시 고정 및 노출되어야할 경우 사용하는 컴포넌트입니다.
 */
export const ButtonBox = forwardRef(function ButtonBox(
  {
    children,
    type = 'horizontal',
    rootClassName,
    className,
    topComponent,
    topComponentClassName,
  }: StrictPropsWithChildren<ButtonBoxProps>,
  forwardedRef: Ref<HTMLDivElement>
) {
  return (
    <div ref={forwardedRef} className={twMergeLDS(cn('w-full'), rootClassName)}>
      {topComponent && (
        <div
          className={twMergeLDS(
            cn('bg-background-elevation-level1 p-[0_16px_16px] lds2-tablet:p-[0_24px_16px]'),
            topComponentClassName
          )}
        >
          {topComponent}
        </div>
      )}
      <div
        className={twMergeLDS(
          cn('flex gap-[12px] p-[0_16px_16px] lds2-tablet:p-[0_24px_24px]', 'bg-background-elevation-level1', {
            'flex-col': type === 'vertical',
            'flex-row': type === 'horizontal',
            'flex-col-reverse': type === 'vertical-reverse',
            'flex-row-reverse': type === 'horizontal-reverse',
          }),
          className
        )}
      >
        {children}
      </div>
    </div>
  )
})
