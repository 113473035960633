import { useEffect } from 'react'

import { useRouter } from 'next/compat/router'

import { SEARCH_LOG_LOCAL_STORAGE_KEY } from '@lbox/shared/constants'
import { generateUUID, webLocalStorage } from '@lbox/shared/utils'

const handleRouteChangeComplete = (path: string) => {
  const [currentPath] = path.split('?')
  const currentPathFromLocalStorage = webLocalStorage.getItem<string | null>(
    SEARCH_LOG_LOCAL_STORAGE_KEY.CURRENT_PAGE_PATH_KEY,
    null
  )

  // 파라미터 제외한 경로 변경 시 페이지 이동으로 계산
  const isPathChanged = currentPathFromLocalStorage?.replace(/\/v2/, '') !== currentPath?.replace(/\/v2/, '')

  if (!isPathChanged) {
    return
  }

  const previousPageId = webLocalStorage.getItem<string | null>(SEARCH_LOG_LOCAL_STORAGE_KEY.CURRENT_PAGE_ID_KEY, null)
  const newPageId = generateUUID()

  webLocalStorage.setItem(SEARCH_LOG_LOCAL_STORAGE_KEY.CURRENT_PAGE_ID_KEY, newPageId)
  webLocalStorage.setItem(SEARCH_LOG_LOCAL_STORAGE_KEY.PREV_PAGE_ID_KEY, previousPageId)

  webLocalStorage.setItem(SEARCH_LOG_LOCAL_STORAGE_KEY.CURRENT_PAGE_PATH_KEY, currentPath)
  webLocalStorage.setItem(SEARCH_LOG_LOCAL_STORAGE_KEY.PREV_PAGE_PATH_KEY, currentPathFromLocalStorage)
}

export const useHandleRouteChangeComplete = () => {
  const router = useRouter()

  useEffect(() => {
    if (!router) {
      return
    }

    router.events.on('routeChangeComplete', handleRouteChangeComplete)

    return () => {
      router.events.off('routeChangeComplete', handleRouteChangeComplete)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (!router) {
      return
    }

    handleRouteChangeComplete(router.asPath)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
}
