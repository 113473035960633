import type { RefObject } from 'react'
import { useCallback, useState } from 'react'

import { useRefEffect } from '@toss/react'

import type { TooltipProps } from './Tooltip'
import type { TooltipBodyDirection, TooltipDirection } from './types'
import { isTooltipBodyDirection, isTooltipTipDirection } from './types'

const TIP_WIDTH = 10
const TIP_HEIGHT = 6
export const TIP_ADJUST = {
  default: 1,
  angular: -4,
} // 팁을 툴팁 박스와 겹치게 하기 위한 값

export type UseTooltipPositionParams = Required<Pick<TooltipProps, 'tipAdjust' | 'initialDirection'>> &
  Pick<TooltipProps, 'title' | 'description'> & {
    triggerRef: RefObject<HTMLElement>
    offset: number
  }

export const useTooltipPosition = ({
  tipAdjust,
  initialDirection,
  title,
  description,
  triggerRef,
  offset,
}: UseTooltipPositionParams) => {
  const [position, setPosition] = useState({ top: 0, left: 0 })
  const [tipPosition, setTipPosition] = useState({ top: 0, left: 0, rotate: 0 })

  const updatePosition = useCallback(
    (tooltipBoxElem: HTMLDivElement) => {
      const { current: triggerElem } = triggerRef

      if (!tooltipBoxElem || !triggerElem || typeof window === 'undefined' || !initialDirection) {
        setPosition({ top: 0, left: 0 })

        return
      }

      const [valueAsTooltipBodyDirection, valueAsTooltipTipDirection] = initialDirection.split('-')

      if (!isTooltipBodyDirection(valueAsTooltipBodyDirection) || !isTooltipTipDirection(valueAsTooltipTipDirection)) {
        setPosition({ top: 0, left: 0 })

        return
      }

      const tooltipRect = tooltipBoxElem.getBoundingClientRect()
      const triggerRect = triggerElem.getBoundingClientRect()

      const positionRecord = computePositionRecord({
        tipAdjust,
        triggerRect,
        tooltipRect,
        offset,
      })
      const tooltipBodyDirection = computeTooltipBodyDirection({
        tooltipBodyDirection: valueAsTooltipBodyDirection,
        tooltipRect,
        triggerRect,
        offset,
      })

      const { position, tipPosition } = positionRecord[`${tooltipBodyDirection}-${valueAsTooltipTipDirection}`]

      setPosition(position)
      setTipPosition(tipPosition)
    },
    [initialDirection, triggerRef]
  )

  const tooltipBoxRef = useRefEffect<HTMLDivElement>(
    (tooltipBoxElem) => {
      updatePosition(tooltipBoxElem)

      let frame: number

      const debouncedUpdatePosition = () => {
        if (frame) {
          cancelAnimationFrame(frame)
        }
        frame = requestAnimationFrame(() => {
          updatePosition(tooltipBoxElem)
        })
      }

      window.addEventListener('resize', debouncedUpdatePosition)
      window.addEventListener('scroll', debouncedUpdatePosition)

      return () => {
        window.removeEventListener('resize', debouncedUpdatePosition)
        window.removeEventListener('scroll', debouncedUpdatePosition)
      }
    },
    [updatePosition, title, description]
  )

  return {
    position,
    tipPosition,
    tooltipBoxRef,
  }
}

type PositionRecord = Record<
  TooltipDirection,
  { position: { top: number; left: number }; tipPosition: { top: number; left: number; rotate: number } }
>

const computePositionRecord = ({
  tipAdjust,
  triggerRect,
  tooltipRect,
  offset,
}: {
  tipAdjust: number
  triggerRect: DOMRect
  tooltipRect: DOMRect
  offset: number
}): PositionRecord => ({
  /**
   * tooltipPosition : top
   * tipPosition : center | start | end
   */
  'top-center': {
    position: {
      top: triggerRect.top - tooltipRect.height - offset,
      left: triggerRect.left + triggerRect.width / 2 - tooltipRect.width / 2,
    },
    tipPosition: {
      top: triggerRect.top - offset - tipAdjust,
      left: triggerRect.left + triggerRect.width / 2 - TIP_WIDTH / 2,
      rotate: 0,
    },
  },
  'top-start': {
    position: {
      top: triggerRect.top - tooltipRect.height - offset,
      left: triggerRect.left,
    },
    tipPosition: {
      top: triggerRect.top - offset - tipAdjust,
      left: triggerRect.left + TIP_WIDTH,
      rotate: 0,
    },
  },
  'top-end': {
    position: {
      top: triggerRect.top - tooltipRect.height - offset,
      left: triggerRect.right - tooltipRect.width,
    },
    tipPosition: {
      top: triggerRect.top - offset - tipAdjust,
      left: triggerRect.right - 2 * TIP_WIDTH,
      rotate: 0,
    },
  },
  /**
   * tooltipPosition : bottom
   * tipPosition : center | start | end
   */
  'bottom-center': {
    position: {
      top: triggerRect.top + triggerRect.height + offset,
      left: triggerRect.left + triggerRect.width / 2 - tooltipRect.width / 2,
    },
    tipPosition: {
      top: triggerRect.top + triggerRect.height + offset - TIP_HEIGHT + tipAdjust,
      left: triggerRect.left + triggerRect.width / 2 - TIP_WIDTH / 2,
      rotate: 180,
    },
  },
  'bottom-start': {
    position: {
      top: triggerRect.top + triggerRect.height + offset,
      left: triggerRect.left,
    },
    tipPosition: {
      top: triggerRect.top + triggerRect.height + offset - TIP_HEIGHT + tipAdjust,
      left: triggerRect.left + TIP_WIDTH,
      rotate: 180,
    },
  },
  'bottom-end': {
    position: {
      top: triggerRect.top + triggerRect.height + offset,
      left: triggerRect.right - tooltipRect.width,
    },
    tipPosition: {
      top: triggerRect.top + triggerRect.height + offset - TIP_HEIGHT + tipAdjust,
      left: triggerRect.right - 2 * TIP_WIDTH,
      rotate: 180,
    },
  },
  /**
   * tooltipPosition : left
   * tipPosition : center | start | end
   */
  'left-center': {
    position: {
      top: triggerRect.top + triggerRect.height / 2 - tooltipRect.height / 2,
      left: triggerRect.left - tooltipRect.width - offset,
    },
    tipPosition: {
      top: triggerRect.top + triggerRect.height / 2 - TIP_WIDTH / 2,
      left: triggerRect.left - offset - (TIP_WIDTH - TIP_HEIGHT) / 2 - tipAdjust,
      rotate: 270,
    },
  },
  'left-start': {
    position: {
      top: triggerRect.top,
      left: triggerRect.left - tooltipRect.width - offset,
    },
    tipPosition: {
      top: triggerRect.top + TIP_WIDTH,
      left: triggerRect.left - offset - (TIP_WIDTH - TIP_HEIGHT) / 2 - tipAdjust,
      rotate: 270,
    },
  },
  'left-end': {
    position: {
      top: triggerRect.bottom - tooltipRect.height,
      left: triggerRect.left - tooltipRect.width - offset,
    },
    tipPosition: {
      top: triggerRect.bottom - 2 * TIP_WIDTH,
      left: triggerRect.left - offset - (TIP_WIDTH - TIP_HEIGHT) / 2 - tipAdjust,
      rotate: 270,
    },
  },
  /**
   * tooltipPosition : right
   * tipPosition : center | start | end
   */
  'right-center': {
    position: {
      top: triggerRect.top + triggerRect.height / 2 - tooltipRect.height / 2,
      left: triggerRect.left + triggerRect.width + offset,
    },
    tipPosition: {
      top: triggerRect.top + triggerRect.height / 2 - TIP_WIDTH / 2,
      left: triggerRect.left + triggerRect.width + offset - TIP_HEIGHT - (TIP_WIDTH - TIP_HEIGHT) / 2 + tipAdjust,
      rotate: 90,
    },
  },
  'right-start': {
    position: {
      top: triggerRect.top,
      left: triggerRect.left + triggerRect.width + offset,
    },
    tipPosition: {
      top: triggerRect.top + TIP_WIDTH,
      left: triggerRect.left + triggerRect.width + offset - TIP_HEIGHT - (TIP_WIDTH - TIP_HEIGHT) / 2 + tipAdjust,
      rotate: 90,
    },
  },
  'right-end': {
    position: {
      top: triggerRect.bottom - tooltipRect.height,
      left: triggerRect.left + triggerRect.width + offset,
    },
    tipPosition: {
      top: triggerRect.bottom - 2 * TIP_WIDTH,
      left: triggerRect.left + triggerRect.width + offset - TIP_HEIGHT - (TIP_WIDTH - TIP_HEIGHT) / 2 + tipAdjust,
      rotate: 90,
    },
  },
})

const computeTooltipBodyDirection = ({
  tooltipBodyDirection,
  triggerRect,
  tooltipRect,
  offset,
}: {
  tooltipBodyDirection: TooltipBodyDirection
  triggerRect: DOMRect
  tooltipRect: DOMRect
  offset: number
}) => {
  if (tooltipBodyDirection === 'left' && triggerRect.left - tooltipRect.width - offset < 0) {
    return 'right'
  }

  if (
    tooltipBodyDirection === 'right' &&
    triggerRect.left + triggerRect.width + tooltipRect.width + offset > window.innerWidth
  ) {
    return 'left'
  }

  if (tooltipBodyDirection === 'top' && triggerRect.top - tooltipRect.height - offset < 0) {
    return 'bottom'
  }

  if (
    tooltipBodyDirection === 'bottom' &&
    triggerRect.top + triggerRect.height + tooltipRect.height + offset > window.innerHeight
  ) {
    return 'top'
  }

  return tooltipBodyDirection
}
