import type { ReactNode } from 'react'

import Image from 'next/image'
import Link from 'next/link'

import { LBOX_PAGE_PATH, externalUrl } from '@lbox/shared/constants'

import { Button } from '@lbox-kr/libra/v2'
import cn from 'classnames'

export interface ExceptionErrorProps {
  title: ReactNode
  description: ReactNode
}

export default function ExceptionError({ title, description }: ExceptionErrorProps) {
  return (
    <main className={cn('flex min-h-screen w-screen items-center justify-center bg-background-mute')}>
      <div className={cn('flex flex-col items-center gap-y-[16px] break-keep px-[16px] text-center')}>
        <Image
          src={`${externalUrl.staticAssetsCloudFrontUrl}/images/lbox/service/lbox-re-brand-logo.webp`}
          alt="LBOX"
          width={100}
          height={40}
        />
        <h1 className={cn('text-lds2-heading3-semibold text-text-primary')}>{title}</h1>
        <p className={cn('text-lds2-body1-regular text-text-primary')}>{description}</p>
        <div className="flex items-center justify-center gap-x-[8px]">
          <Link href={externalUrl.고객센터}>
            <Button buttonType="outlined" size="large" label="고객센터" />
          </Link>
          <Link href={LBOX_PAGE_PATH.root}>
            <Button label="홈으로" buttonType="filled" size="large" />
          </Link>
        </div>
      </div>
    </main>
  )
}
