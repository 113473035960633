import { extendTailwindMerge } from 'tailwind-merge'

import twConfigLDS from '../../tailwind.config'

export const twMergeLDS = extendTailwindMerge({
  extend: {
    classGroups: {
      'font-size': Object.keys(twConfigLDS?.theme?.extend?.fontSize ?? {}).map((key) => `text-${key}`),
    },
  },
})
