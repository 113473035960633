import { webSessionStorage } from '@lbox/shared/utils'

import type { DOCUMENT_PAGE_TYPE } from '@/apis/legal-viewers/constants'
import { KOREAN_DOCUMENT_PAGE_TYPE_LIST } from '@/apis/legal-viewers/constants'
import { DOCUMENT_TYPE_KOR } from '@/components/shared/viewer/$constants'
import { PDP_FUNNEL_KEY } from '@/constants/localStorage'

interface GetAmplitudeContentParams {
  documentType?: keyof typeof DOCUMENT_TYPE_KOR
  documentPageType?: (typeof DOCUMENT_PAGE_TYPE)[number]
  pathname: string
}

export const getAmplitudeContent = ({ documentType, documentPageType, pathname }: GetAmplitudeContentParams) => {
  if (documentType) {
    return DOCUMENT_TYPE_KOR[documentType]
  }

  if (documentPageType) {
    return KOREAN_DOCUMENT_PAGE_TYPE_LIST[documentPageType]
  }

  if (pathname.includes('statute')) {
    return '법령'
  }

  if (pathname.includes('commentary')) {
    return '주석서'
  }

  if (pathname.includes('practice')) {
    return '실무서'
  }

  return null
}

export const getAmplitudeFunnelRootId = ({ pathname }: GetAmplitudeContentParams) => {
  if (pathname.includes('statute')) {
    return webSessionStorage.getItem<string | null>(PDP_FUNNEL_KEY.statute, null)
  }

  if (pathname.includes('practice')) {
    return webSessionStorage.getItem<string | null>(PDP_FUNNEL_KEY.practice, null)
  }

  return null
}

export const getAmplitudeFunnelRootIdKey = ({ pathname }: GetAmplitudeContentParams) => {
  if (pathname.includes('statute')) {
    return PDP_FUNNEL_KEY.statute
  }

  if (pathname.includes('practice')) {
    return PDP_FUNNEL_KEY.practice
  }

  return null
}
