import type { ButtonHTMLAttributes, ReactNode } from 'react'

import { CircleNotch as CircleNotchIcon } from '@phosphor-icons/react'
import cn from 'classnames'

import type { ButtonColor } from './buttonColors'
import { getButtonColors } from './buttonColors'

type ButtonType = 'filled' | 'outlined' | 'ghost'
type ButtonSize = 'large' | 'small'
type ButtonState = 'active' | 'disabled' | 'loading'
type ButtonWidth = 'full' | 'fit'

export interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  label: string
  buttonType: ButtonType
  size: ButtonSize
  state: ButtonState
  width: ButtonWidth
  color: ButtonColor
  leftIcon?: ReactNode
  rightIcon?: ReactNode
}

/**
 * Button
 * - label: buttonText
 * - buttonType: filled, outline, ghost
 * - size: large, small
 * - state: active, disabled
 * - width: fit, full
 * - color: blue, black, red
 * - icon: Icon component at left or right of label
 */
export const Button = ({
  label,
  buttonType,
  size,
  state,
  width,
  color,
  leftIcon,
  rightIcon,
  type = 'button',
  ...props
}: ButtonProps) => {
  // icon
  const hasNoIcon = !leftIcon && !rightIcon
  const hasLeftIcon = !!leftIcon
  const hasRightIcon = !!rightIcon

  // color
  const { backgroundColor, borderColor, textColor } = getButtonColors(color)

  const { className: classNameProp, ...restProps } = props

  return (
    <button
      className={cn(
        'flex items-center justify-center gap-1 whitespace-nowrap rounded-[4px]',
        {
          // disabled
          'disabled:cursor-not-allowed': true,
          'disabled:bg-zinc-300 disabled:text-white': buttonType === 'filled',
          'disabled:border-[1px] disabled:border-zinc-300 disabled:bg-white disabled:text-zinc-300':
            buttonType === 'outlined',
          'disabled:text-zinc-300': buttonType === 'ghost',
        },
        {
          // color
          [`${backgroundColor} text-white`]: buttonType === 'filled',
          [`border-[1px] ${borderColor} bg-white ${textColor} `]: buttonType === 'outlined',
          [`${textColor}`]: buttonType === 'ghost',
          // size
          'py-2.5 text-body1': size === 'large',
          'py-2 text-body3': size === 'small',
          // width
          'w-fit': width === 'fit',
          'w-full': width === 'full',
          // icon
          'pl-4 pr-[26px]': hasLeftIcon,
          'pl-[26px] pr-4': hasRightIcon,
          'px-4': hasNoIcon,
        },
        `${classNameProp}`
      )}
      disabled={state === 'disabled' || state === 'loading'}
      // eslint-disable-next-line react/button-has-type
      type={type}
      {...restProps}
    >
      {size === 'large' && state !== 'loading' && leftIcon}
      {size === 'large' && state === 'loading' && <LoadingSpinner />}
      {label}
      {size === 'large' && rightIcon}
    </button>
  )
}

const LoadingSpinner = () => {
  return <CircleNotchIcon size={20} className={cn('animate-spin')} />
}
