import containerQueriesPlugin from '@tailwindcss/container-queries'
import type { Config } from 'tailwindcss'
import plugin from 'tailwindcss/plugin'

/** @desc : 디자인 토큰 설정 파일 객체 */
import colorTokenTheme from './token/color/dist/lbox/config/tailwind.theme'

const config = {
  content: ['./src/**/*.{ts,tsx}'],
  darkMode: ['class', ':root.dark-theme'],
  theme: {
    extend: {
      colors: {
        /** @desc : 디자인 토큰 테마 주입 */
        ...colorTokenTheme.colors,
        'lbox-brand': {
          50: '#EDF7FF',
          100: '#E0F2FF',
          200: '#C3E4FE',
          300: '#90CCFE',
          400: '#53B2FD',
          500: '#30A1FD',
          600: '#0084EC',
          700: '#0270CA',
          800: '#01579D',
          900: '#024379',
          950: '#002B4B',
        },
        current: 'currentColor',
        // Brand
        lbox: {
          yellow: '#FFB601',
          blue: '#2448FF',
          black: '#14161A',
          black2: '#111827',
        },
        // Primary
        primary: {
          'blue-light-1': '#E9EDFF',
          'blue-light-2': '#738AFF',
          'blue-light-3': '#4E6BFF',
        },
        /** @desc : State */
        state: {
          green: '#00891A',
          red: '#DD0000',
          orange: '#FF8200',
          layer: {
            'on-light-01': 'rgba(38, 77, 94, 0.12)',
            'on-light-02': 'rgba(38, 77, 94, 0.24)',
            'on-light-03': 'rgba(38, 77, 94, 0.04)',
            'on-light-04': 'rgba(38, 77, 94, 0.08)',
            'on-dark-01': 'rgba(255, 255, 255, 0.12)',
            'on-dark-02': 'rgba(255, 255, 255, 0.24)',
          },
        },
        // Highlight
        highlight: {
          yellow: '#F8CE4E',
          green: '#A6DE40',
          pink: '#EE8EC9',
          'yellow-light': '#FFF2C6',
          'green-light': '#E2F7BF',
          'pink-light': '#FFDDF1',
        },
        tag: {
          green: '#DFF2E4',
          blue: '#EAEDFF',
        },
        /** @description : 하위호환성 유지를 위해 dim 객체의 DEFAULT 속성에 값 바인딩 */
        dim: {
          DEFAULT: colorTokenTheme.colors.dim,
          10: 'rgba(0, 0, 0, 0.1)',
          20: 'rgba(0, 0, 0, 0.2)',
          backdrop: 'rgba(107, 114, 128, 0.75)',
        },
        transparent: 'transparent',
      },
      fontFamily: {
        notoSerif: ['Noto Serif KR', 'sans-serif'],
        gmarketSans: ['Gmarket Sans', 'Pretendard Variable', 'sans-serif'],
      },
      fontSize: {
        heading1: [
          '24px',
          {
            fontWeight: 500,
            lineHeight: '35px',
          },
        ],
        heading2: [
          '20px',
          {
            fontWeight: 500,
            lineHeight: '29px',
          },
        ],
        heading3: [
          '18px',
          {
            fontWeight: 500,
            lineHeight: '26px',
          },
        ],
        body1: [
          '16px',
          {
            fontWeight: 500,
            lineHeight: '26px',
          },
        ],
        body2: [
          '16px',
          {
            fontWeight: 400,
            lineHeight: '26px',
          },
        ],
        body3: [
          '14px',
          {
            fontWeight: 500,
            lineHeight: '23px',
          },
        ],
        body4: [
          '14px',
          {
            fontWeight: 400,
            lineHeight: '23px',
          },
        ],
        caption1: [
          '12px',
          {
            fontWeight: 500,
            lineHeight: '20px',
          },
        ],
        caption2: [
          '12px',
          {
            fontWeight: 400,
            lineHeight: '20px',
          },
        ],
        'lds2-display1-extrabold': ['68px', { fontWeight: 800, lineHeight: '94px', letterSpacing: '-1.7px' }],
        'lds2-display2-bold': ['48px', { fontWeight: 700, lineHeight: '72px', letterSpacing: '-1.056px ' }],
        'lds2-heading1-medium': ['36px', { fontWeight: 500, lineHeight: '52px', letterSpacing: '-0.648px' }],
        'lds2-heading1-bold': ['36px', { fontWeight: 700, lineHeight: '52px', letterSpacing: '-0.648px' }],
        'lds2-heading2-medium': ['30px', { fontWeight: 500, lineHeight: '44px', letterSpacing: '-0.36px' }],
        'lds2-heading2-semibold': ['30px', { fontWeight: 600, lineHeight: '44px', letterSpacing: '-0.36px' }],
        'lds2-heading2-bold': ['30px', { fontWeight: 700, lineHeight: '44px', letterSpacing: '-0.36px' }],
        'lds2-heading3-medium': ['24px', { fontWeight: 500, lineHeight: '36px', letterSpacing: '-0.288px' }],
        'lds2-heading3-semibold': ['24px', { fontWeight: 600, lineHeight: '36px', letterSpacing: '-0.288px' }],
        'lds2-heading3-bold': ['24px', { fontWeight: 700, lineHeight: '36px', letterSpacing: '-0.288px' }],
        'lds2-heading4-medium': ['20px', { fontWeight: 500, lineHeight: '30px', letterSpacing: '-0.24px' }],
        'lds2-heading4-semibold': ['20px', { fontWeight: 600, lineHeight: '30px', letterSpacing: '-0.24px' }],
        'lds2-heading4-bold': ['20px', { fontWeight: 700, lineHeight: '30px', letterSpacing: '-0.24px' }],
        'lds2-body1-regular': ['18px', { fontWeight: 400, lineHeight: '28px', letterSpacing: '-0.27px' }],
        'lds2-body1-regular-trimmed': ['18px', { fontWeight: 400, lineHeight: '18px', letterSpacing: '-0.27px' }],
        'lds2-body1-medium': ['18px', { fontWeight: 500, lineHeight: '28px', letterSpacing: '-0.27px' }],
        'lds2-body1-semibold': ['18px', { fontWeight: 600, lineHeight: '28px', letterSpacing: '-0.27px' }],
        'lds2-body1-regular-underline': ['18px', { fontWeight: 400, lineHeight: '28px', letterSpacing: '-0.18px' }],
        'lds2-body1-medium-trimmed': ['18px', { fontWeight: 500, lineHeight: '18px', letterSpacing: '-0.27px' }],
        'lds2-body2-regular': ['16px', { fontWeight: 400, lineHeight: '24px', letterSpacing: '-0.16px' }],
        'lds2-body2-regular-p': ['16px', { fontWeight: 400, lineHeight: '32px', letterSpacing: '-0.16px' }],
        'lds2-body2-medium': ['16px', { fontWeight: 500, lineHeight: '24px', letterSpacing: '-0.16px' }],
        'lds2-body2-semibold': ['16px', { fontWeight: 600, lineHeight: '24px', letterSpacing: '-0.16px' }],
        'lds2-body2-bold': ['16px', { fontWeight: 600, lineHeight: '24px', letterSpacing: '-0.16px' }],
        'lds2-body2-regular-underline': ['16px', { fontWeight: 400, lineHeight: '24px', letterSpacing: '-0.16px' }],
        'lds2-body2-regular-trimmed': ['16px', { fontWeight: 400, lineHeight: '16px', letterSpacing: '-0.16px' }],
        'lds2-body2-medium-trimmed': ['16px', { fontWeight: 500, lineHeight: '16px', letterSpacing: '-0.16px' }],
        'lds2-body3-regular': ['14px', { fontWeight: 400, lineHeight: '22px', letterSpacing: '-0.14px' }],
        'lds2-body3-medium': ['14px', { fontWeight: 500, lineHeight: '22px', letterSpacing: '-0.14px' }],
        'lds2-body3-semibold': ['14px', { fontWeight: 600, lineHeight: '22px', letterSpacing: '-0.14px' }],
        'lds2-body3-regular-underline': ['14px', { fontWeight: 400, lineHeight: '22px', letterSpacing: '-0.14px' }],
        'lds2-body3-regular-trimmed': ['14px', { fontWeight: 400, lineHeight: '14px', letterSpacing: '-0.14px' }],
        'lds2-body3-medium-trimmed': ['14px', { fontWeight: 500, lineHeight: '14px', letterSpacing: '-0.14px' }],
        'lds2-caption1-regular': ['12px', { fontWeight: 400, lineHeight: '18px', letterSpacing: '-0.12px' }],
        'lds2-caption1-regular-trimmed': ['12px', { fontWeight: 400, lineHeight: '12px', letterSpacing: '-0.12px' }],
        'lds2-caption1-medium': ['12px', { fontWeight: 500, lineHeight: '18px', letterSpacing: '-0.12px' }],
        'lds2-caption1-medium-trimmed': ['12px', { fontWeight: 500, lineHeight: '12px', letterSpacing: '-0.12px' }],
        'lds2-caption2-regular': ['10px', { fontWeight: 400, lineHeight: '16px' }],
        'lds2-caption2-regular-trimmed': ['10px', { fontWeight: 400, lineHeight: '10px' }],
        'lds2-caption2-medium': ['10px', { fontWeight: 500, lineHeight: '16px' }],
        'lds2-caption2-medium-trimmed': ['10px', { fontWeight: 500, lineHeight: '10px' }],

        'lds2-content-body1-regular': ['20px', { fontWeight: 400, lineHeight: '44px', letterSpacing: '-0.3px' }],
        'lds2-content-body1-medium': ['20px', { fontWeight: 500, lineHeight: '44px', letterSpacing: '-0.3px' }],
        'lds2-content-body2-regular': ['18px', { fontWeight: 400, lineHeight: '40px', letterSpacing: '-0.27px' }],
        'lds2-content-body2-medium': ['18px', { fontWeight: 500, lineHeight: '40px', letterSpacing: '-0.27px' }],
        'lds2-content-body3-regular': ['16px', { fontWeight: 400, lineHeight: '36px', letterSpacing: '-0.16px' }],
        'lds2-content-body3-medium': ['16px', { fontWeight: 500, lineHeight: '36px', letterSpacing: '-0.16px' }],
      },
      screens: {
        // mobile: default (Mobile First Design)
        tablet: '769px',
        desktop: '961px',
        'lds2-tablet': '768px',
        'lds2-desktop': '1024px',
      },
      boxShadow: {
        buttonShadow: '0px 0px 10px 8px rgb(255, 255, 255)',
        modalShadow: ' 0px 0px 120px -12px rgba(0, 0, 0, 0.35)',
        'focused-ring': '0px 0px 0px 3px rgba(2, 130, 234, 0.30)',
        DEFAULT: '0px 1px 2px 0px rgba(0, 0, 0, 0.06), 0px 1px 3px 0px rgba(0, 0, 0, 0.10)',
      },
      height: {
        screen: ['100vh', '100dvh'] as unknown as string,
      },
      maxHeight: {
        screen: ['100vh', '100dvh'] as unknown as string,
      },
      width: {
        screen: ['100vw', '100dvw'] as unknown as string,
      },
      maxWidth: {
        screen: ['100vw', '100dvw'] as unknown as string,
      },
      animation: {
        skeleton: 'skeleton 1s infinite linear forwards',
      },
      keyframes: {
        skeleton: {
          '0%': {
            transform: 'translateX(-100%)',
          },
          '100%': {
            transform: 'translateX(100%)',
          },
        },
      },
    },
  },
  plugins: [
    containerQueriesPlugin,
    plugin(function ({ addVariant }) {
      addVariant('first-child', '&:first-child')
      addVariant('last-child', '&:last-child')
      addVariant('not-first-child', '&:not(:first-child)')
      addVariant('not-last-child', '&:not(:last-child)')
      addVariant('not-first-of-type', '&:not(:first-of-type)')
      addVariant('not-last-of-type', '&:not(:last-of-type)')
    }),
    plugin(function ({ addBase }) {
      addBase({
        'html, body': {
          scrollbarColor: 'theme(colors.scroll-default) transparent',
        },
        '::-webkit-scrollbar': {
          width: '15px',
          backgroundColor: 'transparent',
        },
        '::-webkit-scrollbar-thumb': {
          backgroundColor: 'theme(colors.scroll-default)',
          borderRadius: '999px',
          backgroundClip: 'padding-box',
          border: '5px solid transparent',
        },
      })
    }),
    plugin(function ({ addBase }) {
      addBase({
        'html, body': {
          textUnderlineOffset: '2px',
        },
      })
    }),
    plugin(function ({ addUtilities }) {
      addUtilities({
        '.no-scrollbar': {
          '-ms-overflow-style': 'none', // IE and Edge
          'scrollbar-width': 'none', // Firefox
          '&::-webkit-scrollbar': {
            display: 'none', // Safari and Chrome
          },
        },
        '.fixed-center': {
          position: 'fixed',
          top: '50%',
          left: '50%',
          transform: 'translateX(-50%) translateY(-50%)',
        },
        '.max-h-screen-custom': {
          maxHeight: 'calc(var(--custom-vh,1dvh)*100)',
        },
        '.bottom-sheet-height-full': {
          minHeight: 'calc(var(--custom-vh,1dvh)*100)',
          maxHeight: 'calc(var(--custom-vh,1dvh)*100)',
          height: 'calc(var(--custom-vh,1dvh)*80)',
        },
        '.focus-visible-outline-yellow:focus-visible': {
          outlineStyle: 'solid',
          outlineWidth: '2px',
          outlineOffset: '2px',
          outlineColor: '#FFB601',
        },
        '.hover-state-light-01': {
          position: 'relative',
          '&::before': {
            content: '""',
            position: 'absolute',
            top: '0',
            left: '0',
            width: '100%',
            height: '100%',
            borderRadius: 'inherit',
            backgroundColor: 'transparent',
            transition: 'background-color 0.3s ease-out',
          },
          '&:not(:disabled):hover::before': {
            backgroundColor: 'theme(colors.state.layer.on-light-01)',
          },
        },
        '.interaction-state-low': {
          position: 'relative',
          '&::before': {
            content: '""',
            position: 'absolute',
            top: '0',
            left: '0',
            width: '100%',
            height: '100%',
            borderRadius: 'inherit',
            backgroundColor: 'transparent',
            transition: 'background-color 0.3s ease-out',
            pointerEvents: 'none',
          },
          '@media(pointer:fine)': {
            '&:not(:disabled):hover::before': {
              backgroundColor: 'theme(colors.state-hovered-secondary)',
            },
          },
          '&:not(:disabled):focus-visible': {
            outline: '2px solid theme(colors.border-brand)',
            outlineOffset: '2px',
          },
          '&:not(:disabled):focus-visible::before': {
            backgroundColor: 'theme(colors.state-hovered-secondary)',
          },
          '&:not(:disabled):active::before': {
            backgroundColor: 'theme(colors.state-active-secondary)',
          },
        },
        /**
         * @author 준혁
         * @description
         * 인라인 요소 (e.g. anchor tag)에 interaction-state-low 를 적용할 때 사용
         */
        '.interaction-state-low-inline': {
          transition: 'background-color 0.3s ease-out',
          '@media(pointer:fine)': {
            '&:hover': {
              backgroundColor: 'theme(colors.state-hovered-secondary)',
            },
          },
          '&:focus-visible': {
            outline: '2px solid theme(colors.border-brand)',
            outlineOffset: '2px',
            backgroundColor: 'theme(colors.state-hovered-secondary)',
          },
          '&:active': {
            backgroundColor: 'theme(colors.state-active-secondary)',
          },
        },
        '.interaction-state-high': {
          position: 'relative',
          '&::before': {
            content: '""',
            position: 'absolute',
            top: '0',
            left: '0',
            width: '100%',
            height: '100%',
            borderRadius: 'inherit',
            backgroundColor: 'transparent',
            transition: 'background-color 0.3s ease-out',
            pointerEvents: 'none',
          },
          '@media(pointer:fine)': {
            '&:not(:disabled):hover::before': {
              backgroundColor: 'theme(colors.state-hovered-primary)',
            },
          },
          '&:not(:disabled):focus-visible': {
            outline: '2px solid theme(colors.border-brand)',
            outlineOffset: '2px',
          },
          '&:not(:disabled):focus-visible::before': {
            backgroundColor: 'theme(colors.state-hovered-primary)',
          },
          '&:not(:disabled):active::before': {
            backgroundColor: 'theme(colors.state-active-primary)',
          },
        },
        /**
         * @author 준혁
         * @description
         * 인라인 요소 (e.g. anchor tag)에 interaction-state-high 를 적용할 때 사용
         */
        '.interaction-state-high-inline': {
          transition: 'background-color 0.3s ease-out',
          '@media(pointer:fine)': {
            '&:hover': {
              backgroundColor: 'theme(colors.state-hovered-primary)',
            },
          },
          '&:focus-visible': {
            outline: '2px solid theme(colors.border-brand)',
            outlineOffset: '2px',
            backgroundColor: 'theme(colors.state-hovered-primary)',
          },
          '&:active': {
            backgroundColor: 'theme(colors.state-active-primary)',
          },
        },
        /**
         * @author 준혁
         * @description
         * Table 에서 사용되는 interaction state
         */
        '.interaction-state-table': {
          transition: 'background-color 0.3s ease-out',
          '@media(pointer:fine)': {
            '&:hover': {
              backgroundColor: 'theme(colors.state-hovered-primary)',
            },
            '&:has(input[type="checkbox"]:checked):hover': {
              backgroundColor: 'rgb(var(--background-brand) / 0.32)',
            },
          },
          '&:has(input[type="checkbox"]:checked)': {
            backgroundColor: 'rgb(var(--background-brand) / 0.16)',
          },
        },
        '.input-disabled': {
          WebkitTextFillColor: 'theme(colors.text-mute)',
          textColor: 'theme(colors.text-mute)',
        },
        '.focused-ring': {
          borderStyle: 'solid',
          borderWidth: '1px',
          borderColor: 'theme(colors.border-brand)',
          boxShadow: 'theme(boxShadow.focused-ring)',
        },
        '.focused-double-ring': {
          outlineStyle: 'solid',
          outlineWidth: '2px',
          outlineOffset: '2px',
          outlineColor: 'theme(colors.border-brand)',
        },
        '.brand-gradient-shadow': {
          position: 'relative',
          transformStyle: 'preserve-3d',
          '&::before': {
            content: '""',
            position: 'absolute',
            inset: '0px',
            background:
              'linear-gradient(277deg, #BEE2FE 5.6%, #6DAFFD 12.91%, #ADDCFF 29.18%, #B8E1FF 40.78%, #D8E8FF 50.07%, #E1DCFF 61%, #D9E0FF 71.47%, #BCE8FB 82.5%, #ACF 100%)',
            filter: 'blur(18px)',
            transform: 'translate3d(0px,0px,-1px)',
            borderRadius: 'inherit',
            pointerEvents: 'none',
            transition: 'opacity 0.3s ease-out',
            opacity: '0.6',
          },
          '@media(pointer:fine)': {
            '&:hover::before': {
              opacity: '0.99',
            },
          },
        },
      })
    }),
  ],
} satisfies Config

export default config
