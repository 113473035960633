import { initTracker, logger } from '@lbox/shared/utils'

import { isServer } from '@toss/utils'

import { getIp } from '@/apis/route-handler/fetchers'
import { ENV } from '@/constants/env'

export const initTrackers = async () => {
  await initTracker({
    env: ENV.NEXT_PUBLIC_ENV,
    amplitude: {
      project: ENV.NEXT_PUBLIC_AMPLITUDE_PROJECT,
    },
    aws: {
      region: ENV.NEXT_PUBLIC_AWS_REGION,
      cognitoIdentityPoolId: ENV.NEXT_PUBLIC_LBOX_COGNITO_IDENTITY_POOL_ID,
      kinesisStreamName: ENV.NEXT_PUBLIC_AWS_KINESIS_STREAM_NAME,
    },
    onInitError: (error) => logger.warn('Amplitude Tracker Init Error', {}, error as Error),
    onTrackError: ({ error, eventProperties }) =>
      logger.warn('AWS Kinesis Data Stream 이벤트 전송 실패', { eventProperties }, error as Error),

    // Only for client
    ...(!isServer() && {
      ipGetter: () =>
        getIp()
          .then(({ ipAddress }) => ipAddress)
          .catch((err) => {
            logger.error('tracker - failed to get user ip', {}, err as Error)
            throw err
          }),
    }),
  })
}
