import type { CSSProperties } from 'react'

import cn from 'classnames'

import type { TooltipColors } from './types'

export type TipShapeProps = {
  color: TooltipColors
  style?: CSSProperties
}

export const TipShape = ({ color, style }: TipShapeProps) => {
  return (
    <span className={cn('fixed', 'inline-block')} style={style}>
      <svg xmlns="http://www.w3.org/2000/svg" width="10" height="6" viewBox="0 0 10 6" fill="none">
        <g clipPath="url(#clip0_2106_8274)">
          <path
            d="M3.76701 4.0983C4.36353 4.95931 5.63647 4.95931 6.23299 4.0983L9.51977 -0.645758C10.209 -1.64056 9.49701 -3 8.28678 -3L1.71322 -3C0.502992 -3 -0.20899 -1.64056 0.480229 -0.645759L3.76701 4.0983Z"
            fill="currentColor"
            stroke="currentColor"
            className={cn({
              'fill-green-700 stroke-green-800': color === 'green',
              'fill-white stroke-zinc-200': color === 'white',
              'fill-zinc-900 stroke-zinc-700': color === 'black',
            })}
          />
        </g>
        <defs>
          <clipPath id="clip0_2106_8274">
            <rect width="10" height="6" fill="white" transform="translate(10 6) rotate(-180)" />
          </clipPath>
        </defs>
      </svg>
    </span>
  )
}
