import { useEffect, useState } from 'react'

import { Types, init } from '@amplitude/analytics-browser'
import { isServer } from '@toss/utils'

import { ENV } from '@/constants/env'

/**
 * @description
 * 개발환경에서 amplitude를 확인하기 위한 amplitude project로 전송하기 위함
 */
const AMPLITUDE_API_KEY = ENV.NEXT_PUBLIC_AMPLITUDE_STAGE_API_KEY

export const useAmplitudeInit = () => {
  const [isInitialized, setIsInitialized] = useState(false)

  useEffect(() => {
    if (isServer() || isInitialized) {
      return
    }

    init(AMPLITUDE_API_KEY || '', undefined, {
      transport: 'beacon',
      minIdLength: 0,
      flushQueueSize: 100,
      logLevel: Types.LogLevel.Warn,
      // Tracking default events options
      // https://www.docs.developers.amplitude.com/data/sdks/browser-2/#tracking-default-events
      defaultTracking: {
        pageViews: false,
        fileDownloads: true,
        sessions: true,
        formInteractions: false,
      },
    })

    setIsInitialized(true)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return { isInitialized }
}
