import type { ToastStatusType } from '@lbox-kr/libra/v1'
import { atom } from 'jotai'

export const toastStatusAtom = atom<ToastStatusType>({
  isOpen: false,
  message: '',
  actionLabel: '',
  position: 'bottom',
  autoHideDuration: 6000,
})
