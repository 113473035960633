import type { StrictPropsWithChildren } from '@lbox/shared/types'

import cn from 'classnames'

import { twMergeLDS } from '../../../utils'
import type { TabsProps } from '../tabs'
import { Tabs as LDSTabs } from '../tabs'

export const Tabs = ({ children, className, ...props }: StrictPropsWithChildren<TabsProps>) => {
  return (
    <LDSTabs
      hasTabsBorderBottom
      className={twMergeLDS(cn('shrink-0 px-[16px] lds2-tablet:px-[24px]'), className)}
      size="large"
      {...props}
    >
      {children}
    </LDSTabs>
  )
}

Tabs.List = LDSTabs.List
Tabs.Item = LDSTabs.Item
