import type { HTMLAttributes, PropsWithChildren } from 'react'

import cn from 'classnames'

import { twMergeLDS } from '../../../utils'

type SpacingGradientType = 'vertical' | 'horizontal'
type SpacingGradientSize = 'small' | 'medium' | 'large'
type SpacingGradientColor = 'white' | 'grey'

export interface SpacingGradientProps {
  direction: 'up' | 'down' | 'left' | 'right'
  size?: SpacingGradientSize
  color?: SpacingGradientColor
}

export const SpacingGradient = (props: PropsWithChildren<SpacingGradientProps & HTMLAttributes<HTMLDivElement>>) => {
  const { className, size = 'medium', direction, color = 'white', children, ...rest } = props

  const type: SpacingGradientType = direction === 'up' || direction === 'down' ? 'horizontal' : 'vertical'

  return (
    <div
      className={twMergeLDS(
        cn(
          'absolute',
          'pointer-events-none',
          /**
           * @author 준혁
           * @description
           * 배경색을 inherit 으로 해볼 수도 있음.
           * 찬회님과 논의 후 -> 필요시 반영
           */
          { 'from-[#FFFFFF] to-transparent': color === 'white', 'from-[#FAFAFA] to-transparent': color === 'grey' },
          {
            'h-[24px] w-full': type === 'horizontal' && size === 'small',
            'h-[48px] w-full': type === 'horizontal' && size === 'medium',
            'h-[72px] w-full': type === 'horizontal' && size === 'large',

            'bg-gradient-to-b': direction === 'up',
            'bg-gradient-to-t': direction === 'down',
          },
          {
            'h-full w-[24px]': type === 'vertical' && size === 'small',
            'h-full w-[48px]': type === 'vertical' && size === 'medium',
            'h-full w-[72px]': type === 'vertical' && size === 'large',

            'bg-gradient-to-l': direction === 'right',
            'bg-gradient-to-r': direction === 'left',
          },
          /**
           * @author 준혁
           * @description
           * 요소 내부에서 absolute 로 그려져야 하는 경우 direction 값에 맞게 포지셔닝
           */
          {
            'bottom-0': direction === 'down',
            'top-0': direction === 'up',
            'left-0 top-0': direction === 'left',
            'right-0 top-0': direction === 'right',
          }
        ),
        className
      )}
      {...rest}
    >
      {children}
    </div>
  )
}
