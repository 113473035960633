import { SEARCH_LOG_LOCAL_STORAGE_KEY } from '@lbox/shared/constants'
import { generateUUID, noop, webLocalStorage, webSessionStorage } from '@lbox/shared/utils'

import { track } from '@amplitude/analytics-browser'
import { postLBoxUserTracking } from '@lbox-kr/components/src/utils/amplitude/postLboxUserTracking'
import { isServer } from '@toss/utils'
import { format } from 'date-fns'

import { getAmplitudeFunnelRootIdKey } from '@/components/shared/viewer/$utils/amplitudes'
import { ENV } from '@/constants/env'
import { getDeviceId } from '@/utils/fingerprint/fingerprint'
import getUserFromCookie from '@/utils/getUserFromCookie'

/**
 * Amplitude 에서 제공하는 `track()` 함수를 wrapping 한 함수
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const amplitudeTrack = (eventType: string, eventProperties: Record<string, any> = {}) => {
  if (isServer()) {
    return
  }

  const isProduction = ENV.NEXT_PUBLIC_ENV === 'production'

  const userId = getUserFromCookie()?.id
  const deviceId = getDeviceId() ?? ''

  const currentPageId = webLocalStorage.getItem(SEARCH_LOG_LOCAL_STORAGE_KEY.CURRENT_PAGE_ID_KEY, '')
  const currentPagePath = webLocalStorage.getItem(SEARCH_LOG_LOCAL_STORAGE_KEY.CURRENT_PAGE_PATH_KEY, '')
  const currentEventId = eventProperties.externalGenerateEventId || generateUUID()

  const prevPageId = webLocalStorage.getItem(SEARCH_LOG_LOCAL_STORAGE_KEY.PREV_PAGE_ID_KEY, '')
  const prevEventId = webLocalStorage.getItem(SEARCH_LOG_LOCAL_STORAGE_KEY.PREV_EVENT_ID_KEY, '')
  const prevEventType = webLocalStorage.getItem<string | null>(SEARCH_LOG_LOCAL_STORAGE_KEY.PREV_EVENT_TYPE_KEY, null)
  const prevPagePath = webLocalStorage.getItem(SEARCH_LOG_LOCAL_STORAGE_KEY.PREV_PAGE_PATH_KEY, '')

  if (eventProperties?.isFunnelRoot) {
    const funnelRootIdKey = getAmplitudeFunnelRootIdKey({ pathname: window.location.pathname })

    if (funnelRootIdKey) {
      webSessionStorage.setItem(funnelRootIdKey, currentEventId)
    }

    delete eventProperties.isFunnelRoot
  }

  if (eventProperties?.isLeafEvent) {
    webLocalStorage.setItem(SEARCH_LOG_LOCAL_STORAGE_KEY.PREV_LEAF_ID_KEY, currentEventId)

    delete eventProperties.isLeafEvent
  }

  if (eventProperties?.externalGenerateEventId) {
    delete eventProperties.externalGenerateEventId
  }

  webLocalStorage.setItem(SEARCH_LOG_LOCAL_STORAGE_KEY.PREV_EVENT_ID_KEY, currentEventId)
  webLocalStorage.setItem(SEARCH_LOG_LOCAL_STORAGE_KEY.PREV_EVENT_TYPE_KEY, eventType)

  // Amplitude user tracking
  const amplitudePayload = {
    user_id: userId,
    url: window.location.href,
    event_type: eventType,
    event_properties: {
      timestamp: format(new Date(), `yyyy-MM-dd'T'HH:mm:ss`),
      previousEvent: prevEventType,
      prevEventId,
      eventId: currentEventId,
      prevPageId,
      pageId: currentPageId,
      prevPagePath,
      currentPagePath,
      isNextJs: true, // Angular 와 공존하는 동안에는 존재해야 하는 property
      url: window.location.href,
      ...eventProperties,
    },
    device_id: deviceId,
  }

  /**
   * @description
   * 운영 환경에서는 amplitude로 이벤트를 바로 전송하지 않음
   * kinesis에서 amplitude로 전송하는 구조
   */
  if (!isProduction) {
    track(amplitudePayload).promise.catch(noop)
  }

  // LBox 자체 user tracking
  const clientTrackingPayload = {
    clientId: deviceId,
    url: window.location.href,
    pageId: currentPageId,
    eventId: currentEventId,
    prevEventId,
    prevPageId,
    eventProperties: {
      ...eventProperties,
      type: amplitudePayload.event_type,
      userAgent: window.navigator.userAgent,
      amplitude: amplitudePayload,
    },
  }

  postLBoxUserTracking(clientTrackingPayload).catch(noop)
}
