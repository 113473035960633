import type { AnimationProps } from 'framer-motion'

export const SLIDING_FROM_RIGHT: AnimationProps = {
  initial: { translateX: '100%' },
  exit: { translateX: '100%' },
  animate: { translateX: '0%' },
  transition: { ease: 'easeInOut' },
}

export const FADE_IN_AND_OUT: AnimationProps = {
  initial: {
    opacity: 0,
  },
  exit: {
    opacity: 0,
  },
  animate: {
    opacity: 1,
  },
  transition: {
    ease: 'easeInOut',
    opacity: {
      duration: 0.4,
    },
  },
}

export const DIMMED_OPACITY_TRANSITION: AnimationProps = {
  initial: {
    opacity: 0,
  },
  animate: {
    opacity: 1.0,
  },
  exit: {
    opacity: 0,
  },
  transition: {
    delay: 0,
    ease: 'easeOut',
    opacity: {
      duration: 0.2,
    },
  },
}

/**
 * @param delay animation 이 시작될 때 까지 delay 할 시간(second 단위)
 */
export const TOOLTIP_FADE_IN = (delay = 0): AnimationProps => ({
  initial: {
    opacity: 0,
  },
  animate: {
    opacity: 1.0,
  },
  exit: {
    opacity: 0,
  },
  transition: {
    delay,
    ease: 'linear',
    opacity: {
      duration: 0.25,
    },
  },
})

export const BOTTOM_SHEET_SHOW_UP_TRANSITION_DURATION_MS = 200
export const BOTTOM_SHEET_SHOW_UP_TRANSITION: AnimationProps = {
  initial: {
    bottom: '-100%',
  },
  animate: {
    bottom: 0,
  },
  exit: {
    bottom: '-100%',
  },
  transition: {
    delay: 0,
    ease: 'easeOut',
    bottom: {
      duration: BOTTOM_SHEET_SHOW_UP_TRANSITION_DURATION_MS / 1000,
    },
  },
}
