import { useEffect, useRef } from 'react'

import { usePreservedCallback } from '@toss/react'

interface UseWindowResizeParams {
  onResize: () => void
  immediate?: boolean
}

export const useWindowResize = ({ onResize, immediate = false }: UseWindowResizeParams) => {
  const isQueuedRef = useRef(false)
  const onResizeCallback = usePreservedCallback(onResize)

  useEffect(() => {
    if (immediate) {
      onResizeCallback()
    }

    isQueuedRef.current = false

    const handleResize = () => {
      if (isQueuedRef.current) {
        return
      }

      isQueuedRef.current = true

      window.requestAnimationFrame(() => {
        onResizeCallback()
        isQueuedRef.current = false
      })
    }

    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [onResizeCallback, immediate])
}
