import { isServer } from '@toss/utils'

import { consoleLogger } from './consoleLogger'
import { datadogLogger } from './datadogLogger'

export type LogFunction = (message: string, context?: object, error?: Error) => void

export interface LoggerInitOptions {
  env: string
  datadog: {
    env?: string
    clientToken: string
    service: string
    releaseVersion?: string
  }
}

export interface Logger {
  init: (options: LoggerInitOptions) => void
  setUser: (user: Record<string, unknown>) => void
  clearUser: VoidFunction
  log: LogFunction
  debug: LogFunction
  info: LogFunction
  warn: LogFunction
  error: LogFunction
}

export let logger = consoleLogger

export const initLogger = (options: LoggerInitOptions): void => {
  logger = isServer() || options.env === 'local' ? consoleLogger : datadogLogger
  logger.init(options)
}
