import type { UseQueryOptions } from '@tanstack/react-query'

import { useGetLboxUser } from '../apis/lbox-user/queries'
import type { LboxUser } from '../types/user/lboxUser'

const useMe = (options?: Omit<UseQueryOptions<LboxUser, unknown, LboxUser>, 'queryKey' | 'queryFn'>) => {
  // 로그인 토큰 쿠키를 이용하는 외부 API 호출
  const {
    data: lboxUser,
    isPending: isLboxUserDataLoading,
    isError: isLboxUserDataError,
    refetch: refetchLboxUser,
  } = useGetLboxUser(options)

  // 이름
  const name = lboxUser?.user?.name ?? ''
  // 변호사 직업 여부
  const isJobLawyer = lboxUser?.lawyer ?? false
  // 변호사 인증 여부
  const isAuthenticatedLawyer = lboxUser?.lawyerVerified ?? false

  // 법학전문대학원생
  const isLawSchoolStudent = lboxUser?.jobDetails?.jobCode === 'JOB_4010'

  const isLoggedIn = Boolean(lboxUser)

  return {
    isLboxUserDataLoading,
    isLboxUserDataError,
    refetchLboxUser,
    isGuest: !isLoggedIn,
    isLawSchoolStudent,
    isLoggedIn,
    lboxUser,
    name,
    isJobLawyer,
    isAuthenticatedLawyer,
    userId: lboxUser?.userId ? String(lboxUser.userId) : 'NO_USER_ID',
    email: lboxUser?.user?.email ?? 'NO_EMAIL',
  }
}

export default useMe
