/* eslint-disable n/no-process-env */

interface EnvironmentVariables {
  NODE_ENV: NodeJS.ProcessEnv['NODE_ENV']
  NEXT_PUBLIC_AMPLITUDE_DEV_API_KEY: string
  NEXT_PUBLIC_AMPLITUDE_STAGE_API_KEY: string
  NEXT_PUBLIC_AMPLITUDE_PRODUCTION_API_KEY: string
  NEXT_PUBLIC_FINGERPRINTJS_API_KEY: string
  NEXT_PUBLIC_KAKAO_REST_API_KEY: string
  NEXT_PUBLIC_LBOX_HOST: string
  NEXT_PUBLIC_LBOX_API_HOST: string
  NEXT_PUBLIC_LBOX_AI_API_HOST: string
  NEXT_PUBLIC_LFIND_API_HOST: string
  NEXT_PUBLIC_LFIND_HOST: string
  NEXT_PUBLIC_DATADOG_CLIENT_TOKEN: string
  NEXT_PUBLIC_DATADOG_SERVICE: string
  NEXT_PUBLIC_ENV: 'local' | 'dev' | 'staging' | 'production'
  NEXT_PUBLIC_DATADOG_ENV:
    | '' // local
    | 'pl.dev'
    | 'rs.dev'
    | 'ai.dev'
    | 'lf.dev'
    | 'staging'
    | 'production'
  NEXT_PUBLIC_DATADOG_RELEASE_VERSION: string | undefined
  NEXT_PUBLIC_KAKAO_SCRIPT_URL: string
  NEXT_PUBLIC_KAKAO_OAUTH_KEY: string
  NEXT_PUBLIC_GOOGLE_SCRIPT_URL: string
  NEXT_PUBLIC_GOOGLE_CLIENT_ID: string
  NEXT_PUBLIC_NAVER_CLIENT_ID: string
  NEXT_PUBLIC_PORTONE_SCRIPT_URL: string
  NEXT_PUBLIC_PORTONE_API_KEY: string
  NEXT_PUBLIC_AMPLITUDE_PROJECT: string
  NEXT_PUBLIC_AWS_REGION: string
  NEXT_PUBLIC_LBOX_COGNITO_IDENTITY_POOL_ID: string
  NEXT_PUBLIC_AWS_KINESIS_STREAM_NAME: string
  NEXT_PUBLIC_LBOX_GRAPH_QL_HOST: string
  NEXT_PUBLIC_LBOX_GRAPH_QL_X_API_KEY: string
  NEXT_PUBLIC_LBOX_GRAPH_QL_X_API_SECRET: string
  NEXT_PUBLIC_UNLEASH_API_TOKEN: string
  NEXT_PUBLIC_UNLEASH_ENDPOINT: string
  NEXT_PUBLIC_AWS_LAWYER_PROFILE_AVATAR_S3_BUCKET: string
  NEXT_PUBLIC_LBOX_AI_UPLOADED_FILE_S3_BUCKET_NAME: string
  NEXT_PUBLIC_LBOX_AI_COGNITO_IDENTITY_POOL_ID: string
  NEXT_PUBLIC_S3_REGION: string
  CI: string | undefined
}

export const ENV = {
  NODE_ENV: process.env.NODE_ENV,
  NEXT_PUBLIC_AMPLITUDE_DEV_API_KEY: process.env.NEXT_PUBLIC_AMPLITUDE_DEV_API_KEY,
  NEXT_PUBLIC_AMPLITUDE_STAGE_API_KEY: process.env.NEXT_PUBLIC_AMPLITUDE_STAGE_API_KEY,
  NEXT_PUBLIC_AMPLITUDE_PRODUCTION_API_KEY: process.env.NEXT_PUBLIC_AMPLITUDE_PRODUCTION_API_KEY,
  NEXT_PUBLIC_FINGERPRINTJS_API_KEY: process.env.NEXT_PUBLIC_FINGERPRINTJS_API_KEY,
  NEXT_PUBLIC_KAKAO_REST_API_KEY: process.env.NEXT_PUBLIC_KAKAO_REST_API_KEY,
  NEXT_PUBLIC_LBOX_HOST: process.env.NEXT_PUBLIC_LBOX_HOST,
  NEXT_PUBLIC_LBOX_API_HOST: process.env.NEXT_PUBLIC_LBOX_API_HOST,
  NEXT_PUBLIC_LBOX_AI_API_HOST: process.env.NEXT_PUBLIC_LBOX_AI_API_HOST,
  NEXT_PUBLIC_LFIND_API_HOST: process.env.NEXT_PUBLIC_LFIND_API_HOST,
  NEXT_PUBLIC_LFIND_HOST: process.env.NEXT_PUBLIC_LFIND_HOST,
  NEXT_PUBLIC_DATADOG_CLIENT_TOKEN: process.env.NEXT_PUBLIC_DATADOG_CLIENT_TOKEN,
  NEXT_PUBLIC_DATADOG_SERVICE: process.env.NEXT_PUBLIC_DATADOG_SERVICE,
  NEXT_PUBLIC_ENV: process.env.NEXT_PUBLIC_ENV,
  NEXT_PUBLIC_DATADOG_ENV: process.env.NEXT_PUBLIC_DATADOG_ENV,
  NEXT_PUBLIC_DATADOG_RELEASE_VERSION: process.env.NEXT_PUBLIC_DATADOG_RELEASE_VERSION,
  NEXT_PUBLIC_KAKAO_SCRIPT_URL: process.env.NEXT_PUBLIC_KAKAO_SCRIPT_URL,
  NEXT_PUBLIC_KAKAO_OAUTH_KEY: process.env.NEXT_PUBLIC_KAKAO_OAUTH_KEY,
  NEXT_PUBLIC_GOOGLE_SCRIPT_URL: process.env.NEXT_PUBLIC_GOOGLE_SCRIPT_URL,
  NEXT_PUBLIC_GOOGLE_CLIENT_ID: process.env.NEXT_PUBLIC_GOOGLE_CLIENT_ID,
  NEXT_PUBLIC_NAVER_CLIENT_ID: process.env.NEXT_PUBLIC_NAVER_CLIENT_ID,
  NEXT_PUBLIC_PORTONE_SCRIPT_URL: process.env.NEXT_PUBLIC_PORTONE_SCRIPT_URL,
  NEXT_PUBLIC_PORTONE_API_KEY: process.env.NEXT_PUBLIC_PORTONE_API_KEY,
  NEXT_PUBLIC_AMPLITUDE_PROJECT: process.env.NEXT_PUBLIC_AMPLITUDE_PROJECT,
  NEXT_PUBLIC_AWS_REGION: process.env.NEXT_PUBLIC_AWS_REGION,
  NEXT_PUBLIC_LBOX_COGNITO_IDENTITY_POOL_ID: process.env.NEXT_PUBLIC_LBOX_COGNITO_IDENTITY_POOL_ID,
  NEXT_PUBLIC_AWS_KINESIS_STREAM_NAME: process.env.NEXT_PUBLIC_AWS_KINESIS_STREAM_NAME,
  NEXT_PUBLIC_LBOX_GRAPH_QL_HOST: process.env.NEXT_PUBLIC_LBOX_GRAPH_QL_HOST,
  NEXT_PUBLIC_LBOX_GRAPH_QL_X_API_KEY: process.env.NEXT_PUBLIC_LBOX_GRAPH_QL_X_API_KEY,
  NEXT_PUBLIC_LBOX_GRAPH_QL_X_API_SECRET: process.env.NEXT_PUBLIC_LBOX_GRAPH_QL_X_API_SECRET,
  NEXT_PUBLIC_UNLEASH_API_TOKEN: process.env.NEXT_PUBLIC_UNLEASH_API_TOKEN,
  NEXT_PUBLIC_UNLEASH_ENDPOINT: process.env.NEXT_PUBLIC_UNLEASH_ENDPOINT,
  NEXT_PUBLIC_AWS_LAWYER_PROFILE_AVATAR_S3_BUCKET: process.env.NEXT_PUBLIC_AWS_LAWYER_PROFILE_AVATAR_S3_BUCKET,
  NEXT_PUBLIC_LBOX_AI_UPLOADED_FILE_S3_BUCKET_NAME: process.env.NEXT_PUBLIC_LBOX_AI_UPLOADED_FILE_S3_BUCKET_NAME,
  NEXT_PUBLIC_LBOX_AI_COGNITO_IDENTITY_POOL_ID: process.env.NEXT_PUBLIC_LBOX_AI_COGNITO_IDENTITY_POOL_ID,
  NEXT_PUBLIC_S3_REGION: process.env.NEXT_PUBLIC_S3_REGION,
  CI: process.env.CI,
} as unknown as EnvironmentVariables
