import type { PropsWithChildren } from 'react'
import { useCallback, useEffect } from 'react'

import { IconX, Portal } from '@lbox/shared/components'
import { noop } from '@lbox/shared/utils'

import useFocusTrap from '@charlietango/use-focus-trap'
import cn from 'classnames'
import { AnimatePresence, motion } from 'framer-motion'

import { BOTTOM_SHEET_SHOW_UP_TRANSITION } from '../../../constants/animation'
import { twMergeLDS } from '../../../utils/twMergeLDS'
import { Backdrop } from '../backdrop'
import { IconOnlyButton } from '../button'
import { Body } from '../modal/Body'
import { Footer } from '../modal/Footer'
import { Headline } from '../modal/Headline'
import { Image } from '../modal/Image'
import { Tabs } from '../modal/Tabs'
import { useBodyScrollLock } from '../modal/useBodyScrollLock'
import { useEsc } from '../modal/useEsc'

export type BottomSheetCommonProps = {
  /**
   * 열림 상태
   */
  isOpen: boolean
  /**
   * Backdrop 노출 여부
   */
  hasBackdrop?: boolean
  /**
   * X 버튼 노출 여부
   */
  showsCloseButton?: boolean
  /**
   * focusTrap 활성화 여부
   */
  activeFocusTrap?: boolean
  /**
   * Backdrop 컴포넌트의 root 요소 className 에 들어갈 문자열
   */
  backdropClassName?: string
  /**
   * BottomSheet 컴포넌트의 root 요소에 적용할 className
   */
  rootClassName?: string
  /**
   * Backdrop 요소 클릭 시 닫힘 방지 여부
   */
  noCloseOnBackdropClick?: boolean
  /**
   * 열기 동작 수행 시 실행 될 callback
   */
  onOpen?: VoidFunction
  /**
   * 닫기 동작 수행 시 실행 될 callback
   */
  onClose?: VoidFunction
  /**
   * 아래에서 위로 올라오는 애니메이션 수행 여부
   */
  isShowUpAnimated?: boolean
}

export type BottomSheetHeightVariables =
  | {
      /**
       * 최대 높이가 window 의 innerHeight 가 되도록 허용해줄지 여부
       */
      maxHeightFull?: true
      /**
       * 높이가 window 의 innerHeight 전체를 차지하게 만들지 여부
       */
      heightFull?: false
    }
  | {
      maxHeightFull?: false
      heightFull?: true
    }
  | {
      maxHeightFull?: false
      heightFull?: false
    }

export type BottomSheetProps = BottomSheetCommonProps & BottomSheetHeightVariables

/**
 * LDS v2 BottomSheet 컴포넌트
 */
export const BottomSheet = ({
  isOpen = false,
  hasBackdrop = true,
  showsCloseButton = false,
  activeFocusTrap = true,
  noCloseOnBackdropClick = false,
  maxHeightFull = false,
  heightFull = false,
  backdropClassName = '',
  rootClassName = '',
  onOpen: handleOpen,
  onClose: handleClose = noop,
  isShowUpAnimated = true,
  children,
}: PropsWithChildren<BottomSheetProps>) => {
  const ref = useFocusTrap(isOpen && activeFocusTrap)

  useEsc(handleClose)
  useBodyScrollLock(isOpen)

  const handleClickBackdrop = useCallback(() => {
    if (noCloseOnBackdropClick) {
      return
    }
    handleClose?.()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [noCloseOnBackdropClick])

  useEffect(() => {
    if (isOpen) {
      handleOpen?.()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen])

  return (
    <AnimatePresence>
      {isOpen && (
        <Portal isOpen={isOpen} rootId="modal-root">
          {/* Dimmed */}
          {hasBackdrop && (
            <Backdrop
              isDimmed
              isFadeAnimated={isShowUpAnimated} // 레이어 올라오는 애니메이션 없어지면 백드롭 애니메이션도 제거
              onClick={handleClickBackdrop}
              rootClassName={backdropClassName}
            />
          )}
          {/* Bottom Sheet */}
          <motion.div
            ref={ref}
            key="bottom-sheet-container"
            aria-modal="true"
            data-allow-scroll // body-scroll-lock 에서 특정 DOM 요소의 scroll 을 허용해주기 위한 attribute
            role="dialog"
            className={twMergeLDS(
              cn(
                'fixed bottom-0',
                'flex flex-col',
                'w-full',
                'bg-white',
                'rounded-tl-[24px] rounded-tr-[24px]',
                'overflow-y-clip',
                'h-fit max-h-[calc(var(--custom-vh,1vh)*80)]',
                {
                  'bottom-sheet-height-full': heightFull,
                  'max-h-screen-custom': maxHeightFull,
                },
                {
                  'shadow-none': hasBackdrop,
                  'shadow-modalShadow': !hasBackdrop,
                }
              ),
              rootClassName
            )}
            {...(isShowUpAnimated ? BOTTOM_SHEET_SHOW_UP_TRANSITION : {})}
          >
            {children}
            {/* close button */}
            {showsCloseButton && (
              <span className={cn('absolute right-[16px] top-[8px]')}>
                <IconOnlyButton
                  buttonType="ghost"
                  size="medium"
                  onClick={handleClose}
                  icon={IconX}
                  className={cn('text-foreground-secondary')}
                />
              </span>
            )}
          </motion.div>
        </Portal>
      )}
    </AnimatePresence>
  )
}

BottomSheet.Headline = Headline
BottomSheet.Tabs = Tabs
BottomSheet.Body = Body
BottomSheet.Image = Image
BottomSheet.Footer = Footer
