import type { RefObject } from 'react'

import { useHover, useMatchMedia } from '@lbox/shared/hooks'

import { useBooleanState, useDidUpdate } from '@toss/react'

import type { TooltipProps } from './Tooltip'

const MOBILE_DEFAULT_AUTO_HIDE_DURATION_MS = 200

export type UseTooltipTriggerParams = Pick<
  TooltipProps,
  'isOpen' | 'showDelay' | 'hideDelay' | 'disableHoverListener' | 'autoHideDuration'
> & {
  triggerRef: RefObject<HTMLElement>
}

export const useTooltipTrigger = ({
  isOpen,
  showDelay,
  hideDelay,
  disableHoverListener,
  triggerRef,
  autoHideDuration = MOBILE_DEFAULT_AUTO_HIDE_DURATION_MS,
}: UseTooltipTriggerParams) => {
  const [isShownTooltip, showTooltip, hideTooltip] = useBooleanState(false)
  const isHover = useHover(triggerRef, { enabled: !disableHoverListener })
  const isTouchDevice = useMatchMedia('(hover: none)')

  useDidUpdate(() => {
    let showTimer: NodeJS.Timeout
    let hideTimer: NodeJS.Timeout

    if (isHover) {
      showTimer = setTimeout(showTooltip, showDelay)
    } else {
      hideTimer = setTimeout(hideTooltip, hideDelay)
    }

    return () => {
      clearTimeout(showTimer)
      clearTimeout(hideTimer)
    }
  }, [isHover])

  /** 모바일에서 툴팁이 켜진 후 자동으로 꺼지는 효과 */
  useDidUpdate(() => {
    if (isTouchDevice) {
      let autoHideDurationTimer: NodeJS.Timeout

      if (isHover) {
        autoHideDurationTimer = setTimeout(hideTooltip, autoHideDuration)
      }

      return () => {
        clearTimeout(autoHideDurationTimer)
      }
    }
  }, [isTouchDevice, isHover])

  return disableHoverListener ? Boolean(isOpen) : isShownTooltip
}
