import type { PropsWithChildren } from 'react'

import cn from 'classnames'

import { twMergeLDS } from '../../../utils'

export const Image = ({ children, className }: PropsWithChildren<{ className?: string }>) => {
  // iOS Safari 에서는 <img> 가 <div> 의 border radius 를 무시하고 앞으로 튀어나와서 <img> 에 border radius 를 적용함
  return (
    <div
      className={twMergeLDS(
        cn('shrink-0', '[&>img]:rounded-[24px_24px_0_0] lds2-tablet:[&>img]:rounded-[8px_8px_0_0]'),
        className
      )}
    >
      {children}
    </div>
  )
}
