export default {
  colors: {
    black: 'rgb(var(--black) / <alpha-value>)',
    white: 'rgb(var(--white) / <alpha-value>)',
    red: {
      50: 'rgb(var(--red-50) / <alpha-value>)',
      100: 'rgb(var(--red-100) / <alpha-value>)',
      200: 'rgb(var(--red-200) / <alpha-value>)',
      300: 'rgb(var(--red-300) / <alpha-value>)',
      400: 'rgb(var(--red-400) / <alpha-value>)',
      500: 'rgb(var(--red-500) / <alpha-value>)',
      600: 'rgb(var(--red-600) / <alpha-value>)',
      700: 'rgb(var(--red-700) / <alpha-value>)',
      800: 'rgb(var(--red-800) / <alpha-value>)',
      900: 'rgb(var(--red-900) / <alpha-value>)',
      950: 'rgb(var(--red-950) / <alpha-value>)',
    },
    yellow: {
      50: 'rgb(var(--yellow-50) / <alpha-value>)',
      100: 'rgb(var(--yellow-100) / <alpha-value>)',
      200: 'rgb(var(--yellow-200) / <alpha-value>)',
      300: 'rgb(var(--yellow-300) / <alpha-value>)',
      400: 'rgb(var(--yellow-400) / <alpha-value>)',
      500: 'rgb(var(--yellow-500) / <alpha-value>)',
      600: 'rgb(var(--yellow-600) / <alpha-value>)',
      700: 'rgb(var(--yellow-700) / <alpha-value>)',
      800: 'rgb(var(--yellow-800) / <alpha-value>)',
      900: 'rgb(var(--yellow-900) / <alpha-value>)',
      950: 'rgb(var(--yellow-950) / <alpha-value>)',
    },
    orange: {
      50: 'rgb(var(--orange-50) / <alpha-value>)',
      100: 'rgb(var(--orange-100) / <alpha-value>)',
      200: 'rgb(var(--orange-200) / <alpha-value>)',
      300: 'rgb(var(--orange-300) / <alpha-value>)',
      400: 'rgb(var(--orange-400) / <alpha-value>)',
      500: 'rgb(var(--orange-500) / <alpha-value>)',
      600: 'rgb(var(--orange-600) / <alpha-value>)',
      700: 'rgb(var(--orange-700) / <alpha-value>)',
      800: 'rgb(var(--orange-800) / <alpha-value>)',
      900: 'rgb(var(--orange-900) / <alpha-value>)',
      950: 'rgb(var(--orange-950) / <alpha-value>)',
    },
    zinc: {
      50: 'rgb(var(--zinc-50) / <alpha-value>)',
      100: 'rgb(var(--zinc-100) / <alpha-value>)',
      200: 'rgb(var(--zinc-200) / <alpha-value>)',
      300: 'rgb(var(--zinc-300) / <alpha-value>)',
      400: 'rgb(var(--zinc-400) / <alpha-value>)',
      500: 'rgb(var(--zinc-500) / <alpha-value>)',
      600: 'rgb(var(--zinc-600) / <alpha-value>)',
      700: 'rgb(var(--zinc-700) / <alpha-value>)',
      800: 'rgb(var(--zinc-800) / <alpha-value>)',
      900: 'rgb(var(--zinc-900) / <alpha-value>)',
      950: 'rgb(var(--zinc-950) / <alpha-value>)',
    },
    rose: {
      50: 'rgb(var(--rose-50) / <alpha-value>)',
      100: 'rgb(var(--rose-100) / <alpha-value>)',
      200: 'rgb(var(--rose-200) / <alpha-value>)',
      300: 'rgb(var(--rose-300) / <alpha-value>)',
      400: 'rgb(var(--rose-400) / <alpha-value>)',
      500: 'rgb(var(--rose-500) / <alpha-value>)',
      600: 'rgb(var(--rose-600) / <alpha-value>)',
      700: 'rgb(var(--rose-700) / <alpha-value>)',
      800: 'rgb(var(--rose-800) / <alpha-value>)',
      900: 'rgb(var(--rose-900) / <alpha-value>)',
      950: 'rgb(var(--rose-950) / <alpha-value>)',
    },
    pink: {
      50: 'rgb(var(--pink-50) / <alpha-value>)',
      100: 'rgb(var(--pink-100) / <alpha-value>)',
      200: 'rgb(var(--pink-200) / <alpha-value>)',
      300: 'rgb(var(--pink-300) / <alpha-value>)',
      400: 'rgb(var(--pink-400) / <alpha-value>)',
      500: 'rgb(var(--pink-500) / <alpha-value>)',
      600: 'rgb(var(--pink-600) / <alpha-value>)',
      700: 'rgb(var(--pink-700) / <alpha-value>)',
      800: 'rgb(var(--pink-800) / <alpha-value>)',
      900: 'rgb(var(--pink-900) / <alpha-value>)',
      950: 'rgb(var(--pink-950) / <alpha-value>)',
    },
    fuchsia: {
      50: 'rgb(var(--fuchsia-50) / <alpha-value>)',
      100: 'rgb(var(--fuchsia-100) / <alpha-value>)',
      200: 'rgb(var(--fuchsia-200) / <alpha-value>)',
      300: 'rgb(var(--fuchsia-300) / <alpha-value>)',
      400: 'rgb(var(--fuchsia-400) / <alpha-value>)',
      500: 'rgb(var(--fuchsia-500) / <alpha-value>)',
      600: 'rgb(var(--fuchsia-600) / <alpha-value>)',
      700: 'rgb(var(--fuchsia-700) / <alpha-value>)',
      800: 'rgb(var(--fuchsia-800) / <alpha-value>)',
      900: 'rgb(var(--fuchsia-900) / <alpha-value>)',
      950: 'rgb(var(--fuchsia-950) / <alpha-value>)',
    },
    purple: {
      50: 'rgb(var(--purple-50) / <alpha-value>)',
      100: 'rgb(var(--purple-100) / <alpha-value>)',
      200: 'rgb(var(--purple-200) / <alpha-value>)',
      300: 'rgb(var(--purple-300) / <alpha-value>)',
      400: 'rgb(var(--purple-400) / <alpha-value>)',
      500: 'rgb(var(--purple-500) / <alpha-value>)',
      600: 'rgb(var(--purple-600) / <alpha-value>)',
      700: 'rgb(var(--purple-700) / <alpha-value>)',
      800: 'rgb(var(--purple-800) / <alpha-value>)',
      900: 'rgb(var(--purple-900) / <alpha-value>)',
      950: 'rgb(var(--purple-950) / <alpha-value>)',
    },
    violet: {
      50: 'rgb(var(--violet-50) / <alpha-value>)',
      100: 'rgb(var(--violet-100) / <alpha-value>)',
      200: 'rgb(var(--violet-200) / <alpha-value>)',
      300: 'rgb(var(--violet-300) / <alpha-value>)',
      400: 'rgb(var(--violet-400) / <alpha-value>)',
      500: 'rgb(var(--violet-500) / <alpha-value>)',
      600: 'rgb(var(--violet-600) / <alpha-value>)',
      700: 'rgb(var(--violet-700) / <alpha-value>)',
      800: 'rgb(var(--violet-800) / <alpha-value>)',
      900: 'rgb(var(--violet-900) / <alpha-value>)',
      950: 'rgb(var(--violet-950) / <alpha-value>)',
    },
    indigo: {
      50: 'rgb(var(--indigo-50) / <alpha-value>)',
      100: 'rgb(var(--indigo-100) / <alpha-value>)',
      200: 'rgb(var(--indigo-200) / <alpha-value>)',
      300: 'rgb(var(--indigo-300) / <alpha-value>)',
      400: 'rgb(var(--indigo-400) / <alpha-value>)',
      500: 'rgb(var(--indigo-500) / <alpha-value>)',
      600: 'rgb(var(--indigo-600) / <alpha-value>)',
      700: 'rgb(var(--indigo-700) / <alpha-value>)',
      800: 'rgb(var(--indigo-800) / <alpha-value>)',
      900: 'rgb(var(--indigo-900) / <alpha-value>)',
      950: 'rgb(var(--indigo-950) / <alpha-value>)',
    },
    blue: {
      50: 'rgb(var(--blue-50) / <alpha-value>)',
      100: 'rgb(var(--blue-100) / <alpha-value>)',
      200: 'rgb(var(--blue-200) / <alpha-value>)',
      300: 'rgb(var(--blue-300) / <alpha-value>)',
      400: 'rgb(var(--blue-400) / <alpha-value>)',
      500: 'rgb(var(--blue-500) / <alpha-value>)',
      600: 'rgb(var(--blue-600) / <alpha-value>)',
      700: 'rgb(var(--blue-700) / <alpha-value>)',
      800: 'rgb(var(--blue-800) / <alpha-value>)',
      900: 'rgb(var(--blue-900) / <alpha-value>)',
      950: 'rgb(var(--blue-950) / <alpha-value>)',
    },
    sky: {
      50: 'rgb(var(--sky-50) / <alpha-value>)',
      100: 'rgb(var(--sky-100) / <alpha-value>)',
      200: 'rgb(var(--sky-200) / <alpha-value>)',
      300: 'rgb(var(--sky-300) / <alpha-value>)',
      400: 'rgb(var(--sky-400) / <alpha-value>)',
      500: 'rgb(var(--sky-500) / <alpha-value>)',
      600: 'rgb(var(--sky-600) / <alpha-value>)',
      700: 'rgb(var(--sky-700) / <alpha-value>)',
      800: 'rgb(var(--sky-800) / <alpha-value>)',
      900: 'rgb(var(--sky-900) / <alpha-value>)',
      950: 'rgb(var(--sky-950) / <alpha-value>)',
    },
    cyan: {
      50: 'rgb(var(--cyan-50) / <alpha-value>)',
      100: 'rgb(var(--cyan-100) / <alpha-value>)',
      200: 'rgb(var(--cyan-200) / <alpha-value>)',
      300: 'rgb(var(--cyan-300) / <alpha-value>)',
      400: 'rgb(var(--cyan-400) / <alpha-value>)',
      500: 'rgb(var(--cyan-500) / <alpha-value>)',
      600: 'rgb(var(--cyan-600) / <alpha-value>)',
      700: 'rgb(var(--cyan-700) / <alpha-value>)',
      800: 'rgb(var(--cyan-800) / <alpha-value>)',
      900: 'rgb(var(--cyan-900) / <alpha-value>)',
      950: 'rgb(var(--cyan-950) / <alpha-value>)',
    },
    teal: {
      50: 'rgb(var(--teal-50) / <alpha-value>)',
      100: 'rgb(var(--teal-100) / <alpha-value>)',
      200: 'rgb(var(--teal-200) / <alpha-value>)',
      300: 'rgb(var(--teal-300) / <alpha-value>)',
      400: 'rgb(var(--teal-400) / <alpha-value>)',
      500: 'rgb(var(--teal-500) / <alpha-value>)',
      600: 'rgb(var(--teal-600) / <alpha-value>)',
      700: 'rgb(var(--teal-700) / <alpha-value>)',
      800: 'rgb(var(--teal-800) / <alpha-value>)',
      900: 'rgb(var(--teal-900) / <alpha-value>)',
      950: 'rgb(var(--teal-950) / <alpha-value>)',
    },
    emerald: {
      50: 'rgb(var(--emerald-50) / <alpha-value>)',
      100: 'rgb(var(--emerald-100) / <alpha-value>)',
      200: 'rgb(var(--emerald-200) / <alpha-value>)',
      300: 'rgb(var(--emerald-300) / <alpha-value>)',
      400: 'rgb(var(--emerald-400) / <alpha-value>)',
      500: 'rgb(var(--emerald-500) / <alpha-value>)',
      600: 'rgb(var(--emerald-600) / <alpha-value>)',
      700: 'rgb(var(--emerald-700) / <alpha-value>)',
      800: 'rgb(var(--emerald-800) / <alpha-value>)',
      900: 'rgb(var(--emerald-900) / <alpha-value>)',
      950: 'rgb(var(--emerald-950) / <alpha-value>)',
    },
    green: {
      50: 'rgb(var(--green-50) / <alpha-value>)',
      100: 'rgb(var(--green-100) / <alpha-value>)',
      200: 'rgb(var(--green-200) / <alpha-value>)',
      300: 'rgb(var(--green-300) / <alpha-value>)',
      400: 'rgb(var(--green-400) / <alpha-value>)',
      500: 'rgb(var(--green-500) / <alpha-value>)',
      600: 'rgb(var(--green-600) / <alpha-value>)',
      700: 'rgb(var(--green-700) / <alpha-value>)',
      800: 'rgb(var(--green-800) / <alpha-value>)',
      900: 'rgb(var(--green-900) / <alpha-value>)',
      950: 'rgb(var(--green-950) / <alpha-value>)',
    },
    lime: {
      50: 'rgb(var(--lime-50) / <alpha-value>)',
      100: 'rgb(var(--lime-100) / <alpha-value>)',
      200: 'rgb(var(--lime-200) / <alpha-value>)',
      300: 'rgb(var(--lime-300) / <alpha-value>)',
      400: 'rgb(var(--lime-400) / <alpha-value>)',
      500: 'rgb(var(--lime-500) / <alpha-value>)',
      600: 'rgb(var(--lime-600) / <alpha-value>)',
      700: 'rgb(var(--lime-700) / <alpha-value>)',
      800: 'rgb(var(--lime-800) / <alpha-value>)',
      900: 'rgb(var(--lime-900) / <alpha-value>)',
      950: 'rgb(var(--lime-950) / <alpha-value>)',
    },
    amber: {
      50: 'rgb(var(--amber-50) / <alpha-value>)',
      100: 'rgb(var(--amber-100) / <alpha-value>)',
      200: 'rgb(var(--amber-200) / <alpha-value>)',
      300: 'rgb(var(--amber-300) / <alpha-value>)',
      400: 'rgb(var(--amber-400) / <alpha-value>)',
      500: 'rgb(var(--amber-500) / <alpha-value>)',
      600: 'rgb(var(--amber-600) / <alpha-value>)',
      700: 'rgb(var(--amber-700) / <alpha-value>)',
      800: 'rgb(var(--amber-800) / <alpha-value>)',
      900: 'rgb(var(--amber-900) / <alpha-value>)',
      950: 'rgb(var(--amber-950) / <alpha-value>)',
    },
    slate: {
      50: 'rgb(var(--slate-50) / <alpha-value>)',
      100: 'rgb(var(--slate-100) / <alpha-value>)',
      200: 'rgb(var(--slate-200) / <alpha-value>)',
      300: 'rgb(var(--slate-300) / <alpha-value>)',
      400: 'rgb(var(--slate-400) / <alpha-value>)',
      500: 'rgb(var(--slate-500) / <alpha-value>)',
      600: 'rgb(var(--slate-600) / <alpha-value>)',
      700: 'rgb(var(--slate-700) / <alpha-value>)',
      800: 'rgb(var(--slate-800) / <alpha-value>)',
      900: 'rgb(var(--slate-900) / <alpha-value>)',
      950: 'rgb(var(--slate-950) / <alpha-value>)',
    },
    'state-on-light-1': 'var(--state-on-light-1)',
    'state-on-light-2': 'var(--state-on-light-2)',
    'state-on-light-3': 'var(--state-on-light-3)',
    'state-on-light-4': 'var(--state-on-light-4)',
    'state-on-dark-1': 'var(--state-on-dark-1)',
    'state-on-dark-2': 'var(--state-on-dark-2)',
    'state-on-dark-3': 'var(--state-on-dark-3)',
    'state-on-dark-4': 'var(--state-on-dark-4)',
    'azure-50': 'rgb(var(--azure-50) / <alpha-value>)',
    'azure-100': 'rgb(var(--azure-100) / <alpha-value>)',
    'azure-200': 'rgb(var(--azure-200) / <alpha-value>)',
    'azure-300': 'rgb(var(--azure-300) / <alpha-value>)',
    'azure-400': 'rgb(var(--azure-400) / <alpha-value>)',
    'azure-500': 'rgb(var(--azure-500) / <alpha-value>)',
    'azure-600': 'rgb(var(--azure-600) / <alpha-value>)',
    'azure-700': 'rgb(var(--azure-700) / <alpha-value>)',
    'azure-800': 'rgb(var(--azure-800) / <alpha-value>)',
    'azure-900': 'rgb(var(--azure-900) / <alpha-value>)',
    'azure-950': 'rgb(var(--azure-950) / <alpha-value>)',
    'brand-50': 'rgb(var(--brand-50) / <alpha-value>)',
    'brand-100': 'rgb(var(--brand-100) / <alpha-value>)',
    'brand-200': 'rgb(var(--brand-200) / <alpha-value>)',
    'brand-300': 'rgb(var(--brand-300) / <alpha-value>)',
    'brand-400': 'rgb(var(--brand-400) / <alpha-value>)',
    'brand-500': 'rgb(var(--brand-500) / <alpha-value>)',
    'brand-600': 'rgb(var(--brand-600) / <alpha-value>)',
    'brand-700': 'rgb(var(--brand-700) / <alpha-value>)',
    'brand-800': 'rgb(var(--brand-800) / <alpha-value>)',
    'brand-900': 'rgb(var(--brand-900) / <alpha-value>)',
    'brand-950': 'rgb(var(--brand-950) / <alpha-value>)',
    'iris-50': 'rgb(var(--iris-50) / <alpha-value>)',
    'iris-100': 'rgb(var(--iris-100) / <alpha-value>)',
    'iris-300': 'rgb(var(--iris-300) / <alpha-value>)',
    'iris-600': 'rgb(var(--iris-600) / <alpha-value>)',
    'border-primary': 'rgb(var(--border-primary) / <alpha-value>)',
    'border-secondary': 'rgb(var(--border-secondary) / <alpha-value>)',
    'border-mute': 'rgb(var(--border-mute) / <alpha-value>)',
    'border-brand': 'rgb(var(--border-brand) / <alpha-value>)',
    'border-error': 'rgb(var(--border-error) / <alpha-value>)',
    'border-success': 'rgb(var(--border-success) / <alpha-value>)',
    'border-secondary-hovered': 'rgb(var(--border-secondary-hovered) / <alpha-value>)',
    'border-secondary-inverse': 'rgb(var(--border-secondary-inverse) / <alpha-value>)',
    'state-hovered-primary': 'var(--state-hovered-primary)',
    'state-hovered-secondary': 'var(--state-hovered-secondary)',
    'state-hovered-on-brand': 'var(--state-hovered-on-brand)',
    'state-active-primary': 'var(--state-active-primary)',
    'state-active-secondary': 'var(--state-active-secondary)',
    'text-primary': 'rgb(var(--text-primary) / <alpha-value>)',
    'text-secondary': 'rgb(var(--text-secondary) / <alpha-value>)',
    'text-tertiary': 'rgb(var(--text-tertiary) / <alpha-value>)',
    'text-mute': 'rgb(var(--text-mute) / <alpha-value>)',
    'text-error': 'rgb(var(--text-error) / <alpha-value>)',
    'text-primary-on-brand': 'rgb(var(--text-primary-on-brand) / <alpha-value>)',
    'text-primary-inverse': 'rgb(var(--text-primary-inverse) / <alpha-value>)',
    'text-white': 'rgb(var(--text-white) / <alpha-value>)',
    'text-black': 'rgb(var(--text-black) / <alpha-value>)',
    'text-brand': 'rgb(var(--text-brand) / <alpha-value>)',
    'background-primary': 'rgb(var(--background-primary) / <alpha-value>)',
    'background-secondary': 'rgb(var(--background-secondary) / <alpha-value>)',
    'background-mute': 'rgb(var(--background-mute) / <alpha-value>)',
    'background-error-low': 'rgb(var(--background-error-low) / <alpha-value>)',
    'background-primary-inverse': 'rgb(var(--background-primary-inverse) / <alpha-value>)',
    'background-success-high': 'rgb(var(--background-success-high) / <alpha-value>)',
    'background-elevation-level1': 'rgb(var(--background-elevation-level1) / <alpha-value>)',
    'background-brand': 'rgb(var(--background-brand) / <alpha-value>)',
    'background-brand-mute': 'rgb(var(--background-brand-mute) / <alpha-value>)',
    'foreground-primary': 'rgb(var(--foreground-primary) / <alpha-value>)',
    'foreground-primary-on-brand': 'rgb(var(--foreground-primary-on-brand) / <alpha-value>)',
    'foreground-primary-inverse': 'rgb(var(--foreground-primary-inverse) / <alpha-value>)',
    'foreground-primary-on-brand-mute': 'rgb(var(--foreground-primary-on-brand-mute) / <alpha-value>)',
    'foreground-secondary': 'rgb(var(--foreground-secondary) / <alpha-value>)',
    'foreground-tertiary': 'rgb(var(--foreground-tertiary) / <alpha-value>)',
    'foreground-mute': 'rgb(var(--foreground-mute) / <alpha-value>)',
    'foreground-error': 'rgb(var(--foreground-error) / <alpha-value>)',
    'foreground-error-mute': 'rgb(var(--foreground-error-mute) / <alpha-value>)',
    'foreground-brand': 'rgb(var(--foreground-brand) / <alpha-value>)',
    'foreground-white': 'rgb(var(--foreground-white) / <alpha-value>)',
    'foreground-black': 'rgb(var(--foreground-black) / <alpha-value>)',
    'utility-rose-lighter': 'rgb(var(--utility-rose-lighter) / <alpha-value>)',
    'utility-rose-medium': 'rgb(var(--utility-rose-medium) / <alpha-value>)',
    'utility-rose-lightest': 'rgb(var(--utility-rose-lightest) / <alpha-value>)',
    'utility-rose-dark': 'rgb(var(--utility-rose-dark) / <alpha-value>)',
    'utility-violet-dark': 'rgb(var(--utility-violet-dark) / <alpha-value>)',
    'utility-violet-lighter': 'rgb(var(--utility-violet-lighter) / <alpha-value>)',
    'utility-indigo-lighter': 'rgb(var(--utility-indigo-lighter) / <alpha-value>)',
    'utility-indigo-dark': 'rgb(var(--utility-indigo-dark) / <alpha-value>)',
    'utility-yellow-lighter': 'rgb(var(--utility-yellow-lighter) / <alpha-value>)',
    'utility-yellow-dark': 'rgb(var(--utility-yellow-dark) / <alpha-value>)',
    'utility-yellow-lightest': 'rgb(var(--utility-yellow-lightest) / <alpha-value>)',
    'utility-yellow-medium': 'rgb(var(--utility-yellow-medium) / <alpha-value>)',
    'utility-yellow-darkest': 'rgb(var(--utility-yellow-darkest) / <alpha-value>)',
    'utility-zinc-lighter': 'rgb(var(--utility-zinc-lighter) / <alpha-value>)',
    'utility-zinc-dark': 'rgb(var(--utility-zinc-dark) / <alpha-value>)',
    'utility-zinc-lightest': 'rgb(var(--utility-zinc-lightest) / <alpha-value>)',
    'utility-zinc-medium': 'rgb(var(--utility-zinc-medium) / <alpha-value>)',
    'utility-pink-lighter': 'rgb(var(--utility-pink-lighter) / <alpha-value>)',
    'utility-pink-dark': 'rgb(var(--utility-pink-dark) / <alpha-value>)',
    'utility-brand-lighter': 'rgb(var(--utility-brand-lighter) / <alpha-value>)',
    'utility-brand-dark': 'rgb(var(--utility-brand-dark) / <alpha-value>)',
    'utility-brand-lightest': 'rgb(var(--utility-brand-lightest) / <alpha-value>)',
    'utility-brand-medium': 'rgb(var(--utility-brand-medium) / <alpha-value>)',
    'utility-green-lighter': 'rgb(var(--utility-green-lighter) / <alpha-value>)',
    'utility-green-dark': 'rgb(var(--utility-green-dark) / <alpha-value>)',
    'utility-green-lightest': 'rgb(var(--utility-green-lightest) / <alpha-value>)',
    'utility-green-medium': 'rgb(var(--utility-green-medium) / <alpha-value>)',
    'utility-green-darkest': 'rgb(var(--utility-green-darkest) / <alpha-value>)',
    'scroll-default': 'rgb(var(--scroll-default) / <alpha-value>)',
    'segment-control-segment': 'rgb(var(--segment-control-segment) / <alpha-value>)',
    'toggle-background': 'rgb(var(--toggle-background) / <alpha-value>)',
    dim: 'var(--dim)',
    'snackbar-background': 'var(--snackbar-background)',
    'snackbar-foreground-error': 'rgb(var(--snackbar-foreground-error) / <alpha-value>)',
    'snackbar-foreground-positive': 'rgb(var(--snackbar-foreground-positive) / <alpha-value>)',
    'elevation-level1-gradient': 'var(--elevation-level1-gradient)',
    'tabs-border-selected': 'rgb(var(--tabs-border-selected) / <alpha-value>)',
    'spacing-gradient-on-background-primary': 'var(--spacing-gradient-on-background-primary)',
    'spacing-gradient-on-background-secondary': 'var(--spacing-gradient-on-background-secondary)',
    'spacing-gradient-on-level1': 'var(--spacing-gradient-on-level1)',
  },
}
