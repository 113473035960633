import { useRouter } from 'next/compat/router'

import { CSROnly } from '@lbox/shared/components'
import { LBOX_PAGE_PATH, TIME } from '@lbox/shared/constants'
import { useMediaQuery } from '@lbox/shared/hooks'

import { useGetUserCurrent } from '@lbox-kr/components/src/apis/user/user-current/queries'
import useMe from '@lbox-kr/components/src/hooks/useMe'
import usePostUserUpdateData from '@lbox-kr/components/src/hooks/user/usePostUserUpdateData'
import { BottomSheet, Button, ButtonBox, Spacing } from '@lbox-kr/libra/v2'
import { useDidUpdate } from '@toss/react'
import cn from 'classnames'
import { useAtom } from 'jotai'

import { useGetInvolveCaseCandidate } from '@/apis/user-involve-case/queries'
import { isMyCaseCandidatePopupOpenAtom } from '@/atoms/globalPopupAtom'
import { amplitudeTrack } from '@/utils/amplitude/amplitudeTrack'

export const MyCaseCandidatePopup = () => {
  const router = useRouter()
  const { isDesktop } = useMediaQuery()
  const { isJobLawyer, isLoggedIn } = useMe()

  const { data: involveCaseCandidate } = useGetInvolveCaseCandidate({
    retry: false,
    enabled: isLoggedIn,
  })

  const { data: user } = useGetUserCurrent({ enabled: isLoggedIn })
  const { mutate: updateUserData } = usePostUserUpdateData()
  const [isMyCaseCandidatePopupOpen, setIsMyCaseCandidatePopupOpen] = useAtom(isMyCaseCandidatePopupOpenAtom)

  const candidateCount = involveCaseCandidate?.length ?? 0
  const notShownUntil = Number(user?.data?.involveUntil ?? 0)
  const isDatePassed = notShownUntil < Date.now()

  const isOpen = isJobLawyer && candidateCount > 0 && user && isMyCaseCandidatePopupOpen && isDatePassed

  useDidUpdate(() => {
    if (isOpen) {
      amplitudeTrack('view_checkMyCaseNudgingPopover_seeMyCase', { path: window.location.pathname })
    }
  }, [isOpen])

  function handleClickOkButton() {
    if (!router) {
      return
    }

    setIsMyCaseCandidatePopupOpen(false)
    amplitudeTrack('click_checkMyCaseNudgingPopover_seeMyCase', { path: window.location.pathname })
    router.push(`${LBOX_PAGE_PATH.lawyerProfile.myCase}?tab=candidate`)
  }

  function handleClickCloseButton() {
    setIsMyCaseCandidatePopupOpen(false)
    updateUserData({ involveUntil: String(Date.now() + TIME['30일']) })
  }

  if (!isOpen) {
    return null
  }

  return (
    <CSROnly>
      <aside
        className={cn(
          'hidden desktop:block',
          'z-[20]',
          'fixed right-[32px] top-[104px] w-[400px]',
          'overflow-hidden rounded-[8px] border-[0.5px] border-zinc-200 bg-white shadow-[3px_3px_22px_-10px_rgba(0,0,0,0.25)]'
        )}
      >
        <div className={cn('p-[24px]')}>
          <p className={cn('break-keep text-lds2-body2-regular text-zinc-700')}>
            <em className={cn('text-lds2-body2-semibold not-italic')}>‘{user.name} 변호사’</em>님으로 검색되는 판례 중,
            수행여부 본인 확인이 필요한{' '}
            <em className={cn('text-lds2-body2-semibold not-italic')}>{candidateCount}건</em>의 판례가 있습니다.
            <br />
            <br />
            사건 수행여부를 확인하고 내 수행사건으로 등록하세요.
          </p>
        </div>
        <Spacing size={16} />
        <ButtonBox>
          <Button
            label="30일 동안 보지 않기"
            type="button"
            buttonType="tonal"
            size="medium"
            width="full"
            onClick={handleClickCloseButton}
          />
          <Button
            label="수행여부 확인하기"
            type="button"
            buttonType="filled"
            size="medium"
            width="full"
            onClick={handleClickOkButton}
          />
        </ButtonBox>
      </aside>
      {!isDesktop && (
        <BottomSheet isOpen onClose={() => setIsMyCaseCandidatePopupOpen(false)}>
          <BottomSheet.Body className={cn('break-keep text-lds2-body2-regular text-zinc-700')}>
            <Spacing size={16} />
            <em className={cn('text-lds2-body2-semibold not-italic')}>‘{user.name} 변호사’</em>님으로 검색되는 판례 중,
            수행여부 본인 확인이 필요한{' '}
            <em className={cn('text-lds2-body2-semibold not-italic')}>{candidateCount}건</em>
            의 판례가 있습니다.
            <br />
            <br />
            사건 수행여부를 확인하고 내 수행사건으로 등록하세요.
          </BottomSheet.Body>
          <BottomSheet.Footer type="vertical-reverse">
            <Button
              label="30일 동안 보지 않기"
              className={cn('text-lds2-body2-regular')}
              type="button"
              buttonType="ghost"
              size="medium"
              width="full"
              onClick={handleClickCloseButton}
            />
            <Button
              label="수행여부 확인하기"
              type="button"
              buttonType="filled"
              size="medium"
              width="full"
              onClick={handleClickOkButton}
            />
          </BottomSheet.Footer>
        </BottomSheet>
      )}
    </CSROnly>
  )
}
