import { format } from 'date-fns'
import { UAParser } from 'ua-parser-js'

import { generateUUID } from '../generateUUID'
import { webLocalStorage } from '../storage'
import { storageKey } from './storageKey'

export const createEventProperty = () => {
  const eventProperty = {
    amplitudeProject: null as string | null,
    userId: null as string | null,
    deviceId: null as string | null,
    userIp: null as string | null,
    userProperties: {} as Record<string, unknown>,
    userAgent: typeof navigator !== 'undefined' ? navigator.userAgent : null,
    uaResult: new UAParser(typeof navigator !== 'undefined' ? navigator.userAgent : undefined).getResult(),
    format(eventType: string, eventProperties: Record<string, unknown> = {}) {
      return {
        ...eventProperty.getCommonProperties(),
        event: {
          type: eventType,
          ...eventProperties,
          amplitude: {
            project: eventProperty.amplitudeProject,
            ip: eventProperty.userIp,
            event_type: eventType,
            user_id: eventProperty.userId,
            device_id: eventProperty.deviceId,
            time: format(new Date(), `yyyy-MM-dd'T'HH:mm:ss`),
            url: window?.location?.href ?? null,
            event_properties: { ...eventProperties },
            user_properties: eventProperty.userProperties,
            /**
             * HTTP API를 사용하기 때문에 os, device 관련 필드를 직접 넣어줌
             * 각 필드 맵핑 로직은 react-native sdk 소스코드를 참고했음
             * @see https://community.amplitude.com/data-instrumentation-57/amplitude-device-type-and-device-family-fields-129
             * @see https://github.com/amplitude/Amplitude-TypeScript/blob/main/packages/analytics-react-native/src/plugins/context.ts
             */
            user_agent: eventProperty.userAgent,
            os_name: eventProperty.uaResult.browser.name,
            os_version: eventProperty.uaResult.browser.version,
            device_manufacturer: eventProperty.uaResult.device.vendor,
            device_model: eventProperty.uaResult.device.model || eventProperty.uaResult.os.name,
            platform: getPlatform(eventProperty.userAgent),
          },
        },
      }
    },
    getCommonProperties() {
      return {
        date: Date.now(),
        id: null,
        clientId: null,
        userId: eventProperty.userId,
        deviceId: eventProperty.deviceId,
        userIp: eventProperty.userIp,
        url: window?.location?.href ?? null,
        testGroupName: null,
        pageId: webLocalStorage.getItem<string | null>(storageKey.CURRENT_PAGE_ID, null),
        eventId: generateUUID(),
        prevPageId: webLocalStorage.getItem<string | null>(storageKey.PREV_PAGE_ID, null),
        prevEventId: webLocalStorage.getItem<string | null>(storageKey.PREV_EVENT_ID, null),
        previousEvent: webLocalStorage.getItem<string | null>(storageKey.PREV_EVENT_TYPE, null),
      }
    },
  }

  return eventProperty
}

function getPlatform(userAgent: string | null) {
  if (/Lfind Android/i.test(userAgent ?? '')) {
    return 'Android'
  }
  if (/Lfind iOS/i.test(userAgent ?? '')) {
    return 'iOS'
  }
  return 'Web'
}
