import type { RefObject } from 'react'
import { useEffect, useState } from 'react'

export interface UseHoverOptions {
  enabled?: boolean
}

export const useHover = <T extends HTMLElement>(targetRef: RefObject<T>, { enabled = true }: UseHoverOptions = {}) => {
  const [isHover, setIsHover] = useState(false)

  useEffect(() => {
    const onMouseEnter = () => setIsHover(true)
    const onMouseLeave = () => setIsHover(false)

    const target = targetRef.current

    if (enabled && target) {
      target.addEventListener('mouseenter', onMouseEnter)
      target.addEventListener('mouseleave', onMouseLeave)
    }

    return () => {
      if (enabled && target) {
        target.removeEventListener('mouseenter', onMouseEnter)
        target.removeEventListener('mouseleave', onMouseLeave)
      }
    }
  }, [targetRef, enabled])

  return isHover
}
