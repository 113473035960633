import type { UseMutationOptions, UseQueryOptions } from '@tanstack/react-query'
import { useMutation, useQuery } from '@tanstack/react-query'
import type { AxiosError } from 'axios'

import type { GetInvolveCaseCandidateResponse, MarkInvolveCaseCandidateParams } from '@/apis/user-involve-case/fetchers'
import { getInvolveCaseCandidate, markInvolveCaseCandidate } from '@/apis/user-involve-case/fetchers'

const USER_INVOLVE_CASE_QUERY_KEYS = {
  all: '/userInvolveCase',
  candidateList: () => [USER_INVOLVE_CASE_QUERY_KEYS.all, getInvolveCaseCandidate.name],
  mark: () => [USER_INVOLVE_CASE_QUERY_KEYS.all, markInvolveCaseCandidate.name],
} as const

export const useGetInvolveCaseCandidate = (
  options?: Omit<UseQueryOptions<GetInvolveCaseCandidateResponse, AxiosError>, 'queryKey' | 'queryFn'>
) => {
  return useQuery<GetInvolveCaseCandidateResponse, AxiosError>({
    queryKey: USER_INVOLVE_CASE_QUERY_KEYS.candidateList(),
    queryFn: getInvolveCaseCandidate,
    ...options,
  })
}

export const useMarkInvolveCaseCandidate = (
  options?: UseMutationOptions<
    Awaited<ReturnType<typeof markInvolveCaseCandidate>>,
    AxiosError,
    MarkInvolveCaseCandidateParams,
    unknown
  >
) => {
  return useMutation({ mutationFn: markInvolveCaseCandidate, ...options })
}
