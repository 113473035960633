// Tailwind CSS 특성상 클래스명 전체를 정적으로 선언해야 함
const ColorClassNames = {
  'lbox-blue': {
    backgroundColor: 'bg-lbox-blue',
    borderColor: 'border-lbox-blue',
    textColor: 'text-lbox-blue',
  },
  'lbox-black': {
    backgroundColor: 'bg-lbox-black',
    borderColor: 'border-zinc-300',
    textColor: 'text-lbox-black',
  },
  'lbox-red': {
    backgroundColor: 'bg-state-red',
    borderColor: 'border-state-red',
    textColor: 'text-state-red',
  },
  // to be added...
} as const

export type ButtonColor = keyof typeof ColorClassNames

export function getButtonColors(color: ButtonColor) {
  return ColorClassNames[color]
}
