import { useEffect, useState } from 'react'

import { logger, webLocalStorage } from '@lbox/shared/utils'

import FingerprintJs from '@fingerprintjs/fingerprintjs-pro'
import { isServer } from '@toss/utils'
import type { AxiosError } from 'axios'

import { ENV } from '@/constants/env'
import { FINGERPRINT_DEVICE_ID_LOCALSTORAGE_KEY } from '@/constants/fingerprint'

const FINGERPRINT_API_KEY = ENV.NEXT_PUBLIC_FINGERPRINTJS_API_KEY

/**
 * fingerprint JS 를 bootstrap 하기 위한 hook
 *
 * 사용자의 deviceId 를 얻어오기 위해 사용한다.
 */
export const useFingerprintInit = () => {
  const [isInitialized, setIsInitialized] = useState(false)

  async function init() {
    if (!FINGERPRINT_API_KEY) {
      return
    }

    // 이미 deviceId를 갖고 있는 경우
    const deviceIdFromLocalStorage = webLocalStorage.getItem<string | null>(
      FINGERPRINT_DEVICE_ID_LOCALSTORAGE_KEY,
      null
    )
    if (deviceIdFromLocalStorage !== null) {
      return
    }

    try {
      const fingerprintAgent = await FingerprintJs.load({
        apiKey: FINGERPRINT_API_KEY,
        scriptUrlPattern: [
          'https://fp-metrics.lbox.kr/web/v3/3yEe1ZJyMobBlEXBJEH0/loader_v3.9.0.js',
          FingerprintJs.defaultScriptUrlPattern, // The default endpoint as fallback
        ],
        endpoint: [
          'https://fp-metrics.lbox.kr',
          FingerprintJs.defaultEndpoint, // The default endpoint as fallback
        ],
      })

      const { visitorId: deviceId } = await fingerprintAgent.get()

      webLocalStorage.setItem(FINGERPRINT_DEVICE_ID_LOCALSTORAGE_KEY, deviceId)
    } catch (error) {
      logger.log('fingerprintjs error', {}, error as AxiosError)
    }
  }

  useEffect(() => {
    if (isServer()) {
      return
    }

    if (isInitialized) {
      return
    }

    init()

    setIsInitialized(true)
  }, [])

  return { isInitialized }
}
