import { isServer } from '@toss/utils'
import Cookies from 'js-cookie'

const LBoxCookieStorageUtils = {
  /** 모든 쿠키를 삭제합니다. SSR, CSR 환경을 구분하여 동작합니다. */
  clear: () => {
    if (isServer()) {
      /** TODO : SSR 로직 */
      return
    }

    const cookies = Cookies.get()

    Object.keys(cookies).forEach((cookie) => Cookies.remove(cookie))
  },
  get: (key: string) => {
    if (isServer()) {
      return
    }

    return Cookies.get(key)
  },
}

export default LBoxCookieStorageUtils
