import { useState } from 'react'

import { useRefEffect } from '@toss/react'

interface UseIsOverflowingOptions {
  direction?: 'horizontal' | 'vertical'
}

export const useIsOverflowing = ({ direction = 'vertical' }: UseIsOverflowingOptions = {}) => {
  const [isOverflowing, setIsOverflowing] = useState(false)

  const ref = useRefEffect<HTMLDivElement>((el) => {
    const observer = new ResizeObserver(([entry]) => {
      if (entry) {
        const { target } = entry

        if (direction === 'horizontal') {
          setIsOverflowing(target.scrollWidth > target.clientWidth)
        } else {
          setIsOverflowing(target.scrollHeight > target.clientHeight)
        }
      }
    })

    observer.observe(el)

    return () => observer.disconnect()
  }, [])

  return { ref, isOverflowing }
}
