import { useEffect, useRef } from 'react'
import type { PropsWithChildren } from 'react'

import { Portal } from '@lbox/shared/components'

import cn from 'classnames'
import { motion, AnimatePresence } from 'framer-motion'

export type CloseEventType = 'USER_CLICK' | 'TIMEOUT'
export type Position = 'top' | 'bottom'

export interface ToastStatusType {
  isOpen: boolean
  message: string
  actionLabel?: string
  position?: Position
  /**
   * Toast 노출 지속 시간(ms 단위의 숫자 값)
   *
   * 'PERMANENT' -> 자동으로 사라지지 않음
   */
  autoHideDuration: number | 'PERMANENT'
}

export interface ToastProps extends ToastStatusType {
  actionFn?: (eventName: CloseEventType) => unknown
  onClose?: (eventName: CloseEventType) => unknown
  closeToast: VoidFunction
}

export const Toast = ({
  isOpen = false,
  message = '',
  autoHideDuration,
  actionLabel = '',
  position = 'bottom',
  actionFn,
  onClose,
  closeToast,
}: PropsWithChildren<ToastProps>) => {
  const timerAutoHide = useRef<NodeJS.Timeout | null>(null)

  function handleClickActionLabel() {
    closeToast()

    if (typeof onClose === 'function') {
      onClose('USER_CLICK')
    }

    if (typeof actionFn === 'function') {
      actionFn('USER_CLICK')
    }
  }

  useEffect(() => {
    if (typeof autoHideDuration === 'number') {
      clearTimeout(timerAutoHide.current as NodeJS.Timeout)

      timerAutoHide.current = setTimeout(() => {
        closeToast()

        if (typeof onClose === 'function') {
          onClose('TIMEOUT')
        }
      }, autoHideDuration)
    }

    return () => {
      if (!timerAutoHide.current) {
        return
      }

      clearTimeout(timerAutoHide.current as NodeJS.Timeout)
    }
  }, [isOpen, autoHideDuration, onClose, closeToast])

  return (
    <AnimatePresence>
      <Portal isOpen={isOpen} rootId="toast-root">
        <motion.div
          key="toast-message"
          initial={{
            opacity: 0,
            visibility: 'hidden',
            scaleX: 0.75,
            scaleY: 0.5625,
            translateX: '-50%',
          }}
          animate={{
            opacity: 1.0,
            visibility: 'visible',
            scaleX: 1.0,
            scaleY: 1.0,
          }}
          exit={{
            opacity: 0,
          }}
          transition={{
            delay: 0,
            ease: [0.4, 0, 0.2, 1],
            opacity: {
              duration: 0.15,
            },
            transform: {
              duration: 0.225,
            },
          }}
          className={cn(
            'flex items-center justify-between',
            'fixed left-[50%]',
            'rounded-[5px]',
            'bg-zinc-700',
            'text-white',
            'min-w-0',
            'w-[calc(100%-48px)] tablet:w-fit tablet:max-w-[640px]',
            {
              'bottom-[24px] tablet:bottom-[48px]': position === 'bottom',
              'top-[24px] tablet:top-[48px]': position === 'top',
            },
            {
              'px-[24px] py-[18px]': actionLabel,
              'px-[24px] py-[16px]': !actionLabel,
            }
          )}
        >
          <p className={cn('text-[16px] font-medium leading-[23px] text-white', 'break-keep')}>{message}</p>
          {actionLabel && (
            <button
              className={cn(
                'ml-[24px] tablet:ml-[32px]',
                'tablet:rounded-[5px]',
                'tablet:bg-[transparent]',
                'tablet:whitespace-nowrap',
                'whitespace-nowrap',
                'text-lbox-yellow tablet:text-[16px] tablet:font-medium tablet:leading-[26px]'
              )}
              type="button"
              onClick={handleClickActionLabel}
            >
              {actionLabel}
            </button>
          )}
        </motion.div>
      </Portal>
    </AnimatePresence>
  )
}
