import type { ErrorInfo } from 'react'
import { Component } from 'react'

import type { StrictPropsWithChildren } from '@lbox/shared/types'
import { logger } from '@lbox/shared/utils'

import { convertNewlineToJSX } from '@toss/react'

import ExceptionError from '@/components/shared/exceptionError/ExceptionError'

interface GlobalErrorBoundaryProps {
  disableLogging?: boolean
}

interface State {
  hasError: boolean
}

class GlobalErrorBoundary extends Component<StrictPropsWithChildren<GlobalErrorBoundaryProps>, State> {
  public state: State = {
    hasError: false,
  }

  public static getDerivedStateFromError(): State {
    return {
      hasError: true,
    }
  }

  /** errorInfo : 에러 발생 컴포넌트 stack - Captures which component contained the exception, and its ancestors. */
  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    const { disableLogging } = this.props

    if (disableLogging) {
      return
    }

    const ctx = {
      errorInfo,
    }

    logger.error('GlobalErrorBoundary Component에서 catch 된 처리되지 않은 에러', ctx, error)
  }

  public render() {
    if (this.state.hasError) {
      return (
        <ExceptionError
          title="잠시 후 다시 시도해주세요"
          description={convertNewlineToJSX(
            '내부의 기술적인 문제로 확인이 필요합니다.\n문제가 지속되면 고객센터로 문의를 부탁드립니다.'
          )}
        />
      )
    }

    return this.props.children
  }
}

export default GlobalErrorBoundary
