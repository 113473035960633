export const externalUrl = {
  고객센터: 'https://support.lbox.kr/hc/ko',
  릴리즈노트: 'https://lbox.notion.site/4bfce2b009b7421c9f8f5b7cbd8f9b83?v=80c4b3efbed44f869f4ecaaaa2c09b99',
  이용가이드: 'https://lbox.notion.site/3e2919133fe44ad59c8d9b651e0def7c',
  운영정책: 'https://lbox.notion.site/c794756f1a1847bdbda72028985177dc',
  개인정보처리방침: 'https://lbox.notion.site/3df18ec81f634a2fb8902b629e7cbaf3',
  이용약관: 'https://lbox.notion.site/dac8679d0b474d3298c3f06f7bf46149',
  인재채용: 'https://lbox.career.greetinghr.com',
  고객센터_문의등록: 'https://support.lbox.kr/hc/ko/requests/new',
  서비스_이용문의_등록: 'https://support.lbox.kr/hc/ko/requests/new?ticket_form_id=5237251656847',
  비즈니스_플랜_중도추가_할인율_안내서:
    'https://support.lbox.kr/hc/ko/articles/5442758917519--%EB%B9%84%EC%A6%88%EB%8B%88%EC%8A%A4-%EA%B3%84%EC%95%BD-%EC%B2%B4%EA%B2%B0-%ED%9B%84-%EC%9D%B4%EC%9A%A9%EC%9D%B8%EC%9B%90-%EC%A4%91%EB%8F%84-%EC%B6%94%EA%B0%80-%EC%8B%9C-%ED%95%A0%EC%9D%B8%EC%9C%A8%EC%9D%80-%EC%96%B4%EB%96%BB%EA%B2%8C-%EC%A0%81%EC%9A%A9%EB%90%98%EB%82%98%EC%9A%94',
  비즈니스_플랜_가입_안내서: 'https://lbox.notion.site/93fa6b6d61a94132881a0f3b04f16386',
  퍼블릭_플랜_가입_안내서: 'https://lbox.notion.site/015d5f993fc7484a8e2f83c295eafb87',
  유료_서비스_운영정책: 'https://lbox.notion.site/eddc0dae5ec041458e1405e28da57710',
  로웨이브: 'https://lawwave.kr',
  엘파인드_고객센터: 'https://support.lbox.kr/hc/ko/requests/new?ticket_form_id=5237251656847',
  엘파인드_이용약관: 'https://lbox.notion.site/Lfind-ef34aeadbd2647da87436f586a20c650',
  엘파인드_개인정보처리방침: 'https://lbox.notion.site/3df18ec81f634a2fb8902b629e7cbaf3',
  엘파인드_상담문의_개인정보수집이용동의: 'https://www.notion.so/lbox/Lfind-d750f58730cc4875823f88d7e8e3ca60',
  엘파인드_상담문의_제3자제공동의: 'https://www.notion.so/lbox/Lfind-3-5721e7c50f6e4ee1ab035ea0873f1fb7',
  엘파인드_변호사프로필_운영정책: 'https://lbox.notion.site/626c536a2c8049349b3cace7ace65e44',
  소속_인증_방법_안내: 'https://lbox.notion.site/8ac4917950c542a7a897a76c333d1de2',
  한국사내변호사회_프로모션: 'https://kica-lbox.lbox.kr/',
  staticAssetsCloudFrontUrl: '/v2/lbox-fe-static',
  경찰청_복지포털: 'https://polbokji.ezwel.com',
  고객센터_최대기기:
    'https://support.lbox.kr/hc/ko/articles/5441942358543-%ED%95%98%EB%82%98%EC%9D%98-%EA%B3%84%EC%A0%95%EC%9C%BC%EB%A1%9C-%EC%B5%9C%EB%8C%80-%EB%AA%87-%EB%8C%80-%EA%B8%B0%EA%B8%B0%EC%97%90%EC%84%9C-%EC%9D%B4%EC%9A%A9%ED%95%A0-%EC%88%98-%EC%9E%88%EB%82%98%EC%9A%94',
  로웨이브_통계로_본_개업변호사의_오늘: 'https://www.lawwave.kr/feel/278?category=worklife',
  엘박스_다큐멘터리: 'https://youtu.be/b9iveJaPVp4?utm_source=lbox&utm_medium=modal&utm_campaign=thebridge',
  엘박스_AI_개인정보처리방침: 'https://lbox.notion.site/lbox/AI-63ece1f8a74a4f52bdb2a5a7fc169ef8',
  엘박스_AI_서비스_운영정책: 'https://lbox.notion.site/lbox/AI-a69a43dabe9e4b28971ff0a9065b4758',
  엘박스_AI_법조인_법학연구자_인증_신청_에어테이블: 'https://airtable.com/app1pjEW7HWGWWFDb/pagUIFG71vhCBZVtr/form',
  한국공인노무사회_MOU_혜택_신청: 'https://airtable.com/appYPJhSdXuDhZgIn/paglF8ETbWKaOSqqK/form',
  손해사정사_인증: 'https://airtable.com/apprd556dupHkS6Ma/pagO3us7btFeRLgux/form',
  법률분석_고객센터:
    'https://support.lbox.kr/hc/ko/articles/10062998724751-%EB%B2%95%EB%A5%A0%EB%B6%84%EC%84%9D%EC%9D%80-%EB%88%84%EA%B0%80-%EC%9D%B4%EC%9A%A9%ED%95%A0-%EC%88%98-%EC%9E%88%EB%82%98%EC%9A%94',
  엘박스_AI_프리미엄_에어테이블: 'https://airtable.com/appy9mPRLjDbzXgpb/pagg9EpoGqdIfk3Sc/form',
  엘박스_AI_문서초안_문서형식_추가: 'https://forms.gle/N6qtHWuUsFC1gaeh9',
  엘박스_AI_검색가이드: 'https://lbox.notion.site/lbox/a4cf89a0fbd44d55bec0ed52b60685b4?pvs=4',
  내판결문등록_운영정책: 'https://lbox.notion.site/aa9c2c3cd61a44d9add6e512262e0ce2',
  대법원_종합법률정보: 'https://glaw.scourt.go.kr/wsjo/panre/sjo050.do',
  staticAssetsLboxUrl: 'https://image.lbox.kr',
  경찰청플랜_이용방법: 'https://support.lbox.kr/hc/ko/articles/9053377678095',
  나의변호사: 'https://www.klaw.or.kr',
  여러건등록_가이드: 'https://lbox.notion.site/1924e2f42a228082806de3332a75235e',
} as const
