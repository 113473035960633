import type { ModalProps } from '@lbox-kr/libra/v2'
import { useAtom } from 'jotai'

import { globalModalInitialStatus, globalModalStatusAtom } from '@/atoms/globalModal'

export const useGlobalModal = () => {
  const [globalModalStatus, setGlobalModalStatus] = useAtom(globalModalStatusAtom)

  function open(props: ModalProps) {
    setGlobalModalStatus({ ...props })
  }

  function close() {
    setGlobalModalStatus(globalModalInitialStatus)
  }

  return { globalModalStatus, open, close }
}
