import type { PropsWithChildren } from 'react'

import NextHead from 'next/head'

import { V2_PREFIX } from '@lbox/shared/constants'

interface HeadProps {
  title: string
  description?: string
  ogTitle?: string
  ogDescription?: string
  imageUrl?: string
}

const Head = ({ title, description, ogTitle, ogDescription, imageUrl, children }: PropsWithChildren<HeadProps>) => {
  return (
    <NextHead>
      <title>{title}</title>

      <meta key="title" name="title" content={title} />
      {description && <meta key="description" name="description" content={description} />}

      <meta key="og:type" property="og:type" content="website" />
      {ogTitle && <meta key="og:title" property="og:title" content={ogTitle} />}
      {ogDescription && <meta key="og:description" property="og:description" content={ogDescription} />}
      <meta key="og:image" property="og:image" content={imageUrl ?? `/${V2_PREFIX}/images/og/og-lbox-logo.png`} />
      {children}
    </NextHead>
  )
}

export default Head
