import { lboxHttpClient } from '@lbox-kr/components/src/utils/api'

import { ROUTE_HANDLER_API_ENDPOINTS } from '@/apis/route-handler/endpoints'
import type {
  GetNaverEmailResponse,
  GetRequestFilterParams,
  GetRequestSubCategoryParams,
  SearchAddressByKeywordParams,
  SearchAddressByKeywordResponse,
} from '@/apis/route-handler/types'
import type { GetRequestSubCategoryAddedNotifyQueryParams } from '@/pages/api/get-request-subcategory-added-notify'

export const getNaverEmail = async (snsToken: string) => {
  const { data } = await lboxHttpClient.get<GetNaverEmailResponse>(ROUTE_HANDLER_API_ENDPOINTS.getNaverEmail, {
    baseURL: '/v2',
    params: {
      snsToken,
    },
  })

  return data
}

export const getRequestFilter = async (params: GetRequestFilterParams) => {
  const { data } = await lboxHttpClient.get<{ status: 'success' }>(ROUTE_HANDLER_API_ENDPOINTS.getRequestFilter, {
    baseURL: '/v2',
    params,
  })

  return data
}

export const getRequestSubCategory = async (params: GetRequestSubCategoryParams) => {
  const { data } = await lboxHttpClient.get<{ status: 'success' }>(ROUTE_HANDLER_API_ENDPOINTS.getRequestSubCategory, {
    baseURL: '/v2',
    params,
  })

  return data
}

export const getRequestSubCategoryAddedNotify = async (params: GetRequestSubCategoryAddedNotifyQueryParams) => {
  const { data } = await lboxHttpClient.get<{ status: 'success' }>(
    ROUTE_HANDLER_API_ENDPOINTS.getRequestSubCategoryAddedNotify,
    {
      baseURL: '/v2',
      params,
    }
  )

  return data
}

export const getIp = async () => {
  const { data } = await lboxHttpClient.get<{ ipAddress: string }>('/ip', { baseURL: '/v2/api' })

  return data
}

export const searchAddressByKeyword = async ({ keyword }: SearchAddressByKeywordParams) => {
  const { data } = await lboxHttpClient.get<SearchAddressByKeywordResponse>(ROUTE_HANDLER_API_ENDPOINTS.searchAddress, {
    baseURL: '/v2',
    params: { keyword },
  })
  return data
}
