import { CaseLawDocumentPageType } from '@/gql/graphql'

// enum type 추가될 예정
export const DOCUMENT_PAGE_TYPE = [
  'STATUTE',
  'AUTHORITATIVE_INTERPRETATION',
  'DECISION',
  'TRIAL_DECISION',
  'PERSONAL_INFORMATION',
  'HUMAN_RIGHTS',
  'AUDIT_INSPECTION',
  'CIVIL_RIGHTS',
  'CIVIL_RIGHTS_CENTRAL',
  'CIVIL_RIGHTS_SUBCOMMITTEE',
  'FAIR_TRADE_COMMISSION',
  'IPTAB',
  'TAX_TRIBUNAL',
  'FINANCIAL_SERVICES_COMMISSION',
  'ADMINISTRATIVE_APPEALS_COMMISSION',
  'INDUSTRIAL_ACCIDENT_COMPENSATION_INSURANCE_REEXAMINATION_COMMITTEE',
  'WORKERS_WELFARE_SERVICE',
  'LABOR_COMMISSION',
  'EMPLOYMENT_INSURANCE_DELIBERATION_COMMITTEE',
] as const

export const KOREAN_DOCUMENT_PAGE_TYPE = ['법령', '유권해석', '심결례'] as const

export const DOCUMENT_PAGE_TYPE_LIST = {
  STATUTE: DOCUMENT_PAGE_TYPE[0],
  AUTHORITATIVE_INTERPRETATION: DOCUMENT_PAGE_TYPE[1],
  DECISION: DOCUMENT_PAGE_TYPE[2],
  TRIAL_DECISION: DOCUMENT_PAGE_TYPE[3],
  PERSONAL_INFORMATION: DOCUMENT_PAGE_TYPE[4],
  HUMAN_RIGHTS: DOCUMENT_PAGE_TYPE[5],
  AUDIT_INSPECTION: DOCUMENT_PAGE_TYPE[6],
  CIVIL_RIGHTS: DOCUMENT_PAGE_TYPE[7],
  CIVIL_RIGHTS_CENTRAL: DOCUMENT_PAGE_TYPE[8],
  CIVIL_RIGHTS_SUBCOMMITTEE: DOCUMENT_PAGE_TYPE[9],
  FAIR_TRADE_COMMISSION: DOCUMENT_PAGE_TYPE[10],
  IPTAB: DOCUMENT_PAGE_TYPE[11],
  TAX_TRIBUNAL: DOCUMENT_PAGE_TYPE[12],
  FINANCIAL_SERVICES_COMMISSION: DOCUMENT_PAGE_TYPE[13],
  ADMINISTRATIVE_APPEALS_COMMISSION: DOCUMENT_PAGE_TYPE[14],
  INDUSTRIAL_ACCIDENT_COMPENSATION_INSURANCE_REEXAMINATION_COMMITTEE: DOCUMENT_PAGE_TYPE[15],
  WORKERS_WELFARE_SERVICE: DOCUMENT_PAGE_TYPE[16],
  LABOR_COMMISSION: DOCUMENT_PAGE_TYPE[17],
  EMPLOYMENT_INSURANCE_DELIBERATION_COMMITTEE: DOCUMENT_PAGE_TYPE[18],
}

export const KOREAN_DOCUMENT_PAGE_TYPE_LIST = {
  STATUTE: KOREAN_DOCUMENT_PAGE_TYPE[0],
  AUTHORITATIVE_INTERPRETATION: KOREAN_DOCUMENT_PAGE_TYPE[1],
  /** 아래 모두 결정례를 볼 수 있도록 설정 (amplitude 작업 때문) */
  DECISION: KOREAN_DOCUMENT_PAGE_TYPE[2],
  TRIAL_DECISION: KOREAN_DOCUMENT_PAGE_TYPE[2],
  PERSONAL_INFORMATION: KOREAN_DOCUMENT_PAGE_TYPE[2],
  HUMAN_RIGHTS: KOREAN_DOCUMENT_PAGE_TYPE[2],
  AUDIT_INSPECTION: KOREAN_DOCUMENT_PAGE_TYPE[2],
  CIVIL_RIGHTS: KOREAN_DOCUMENT_PAGE_TYPE[2],
  CIVIL_RIGHTS_CENTRAL: KOREAN_DOCUMENT_PAGE_TYPE[2],
  CIVIL_RIGHTS_SUBCOMMITTEE: KOREAN_DOCUMENT_PAGE_TYPE[2],
  FAIR_TRADE_COMMISSION: KOREAN_DOCUMENT_PAGE_TYPE[2],
  IPTAB: KOREAN_DOCUMENT_PAGE_TYPE[2],
  TAX_TRIBUNAL: KOREAN_DOCUMENT_PAGE_TYPE[2],
  FINANCIAL_SERVICES_COMMISSION: KOREAN_DOCUMENT_PAGE_TYPE[2],
  ADMINISTRATIVE_APPEALS_COMMISSION: KOREAN_DOCUMENT_PAGE_TYPE[2],
  INDUSTRIAL_ACCIDENT_COMPENSATION_INSURANCE_REEXAMINATION_COMMITTEE: KOREAN_DOCUMENT_PAGE_TYPE[2],
  WORKERS_WELFARE_SERVICE: KOREAN_DOCUMENT_PAGE_TYPE[2],
  LABOR_COMMISSION: KOREAN_DOCUMENT_PAGE_TYPE[2],
  EMPLOYMENT_INSURANCE_DELIBERATION_COMMITTEE: KOREAN_DOCUMENT_PAGE_TYPE[2],
  [CaseLawDocumentPageType.NationalTaxServiceRulings]: KOREAN_DOCUMENT_PAGE_TYPE[2],
  [CaseLawDocumentPageType.HrInnovationAppealsReviewCommitteeCases]: KOREAN_DOCUMENT_PAGE_TYPE[2],
}

export const TEXT_SUB_INFO_INITIAL_VALUE = {
  committee: '',
  respondingAgency: '',
  relevantAuthority: '',
  docIdOrigin: '',
  responseDate: '',
} as const

export const DOCUMENT_SUB_INFO_INITIAL_VALUE = {
  organization: '', //기관
  committee: '', // 담당위원회
  responsibleDepartment: '', //담당부서
  docIdOrigin: '', //문서번호
  decisionDate: '', //회시일자
} as const
