import { createHttpClient, webLocalStorage } from '@lbox/shared/utils'

import { isClient, isServer } from '@toss/utils'
import { isAxiosError } from 'axios'

import { serverTokenStorage } from './asyncHooks'
import LBoxCookieStorageUtils from './cookieStorage'
import { FINGERPRINT_DEVICE_ID_LOCALSTORAGE_KEY } from '../constants/fingerprint'
import {
  CASE_EVENTS_V2_KEY,
  LAW_SCHOOL_CAN_USE_LBOX_AI_KEY,
  LAW_SCHOOL_LBOX_AI_POPUP_KEY,
  LAWYER_EXAM_13TH_EVENT_POPUP_NO_SHOWN_KEY,
  LBOX_LOGIN_TOKEN_KEY,
  LFIND_POPUP_STORAGE_KEY,
  SRP_INTERPRETATION_BANNER_NOT_SHOWN_KEY,
  TOKEN_FROM_LBOX_CLIENT,
  TOP_BANNER_NOT_SHOWN_UNTIL_KEY,
} from '../constants/localStorage'
import { LFIND_API_HOST } from '../constants/paths'
import { SESSION_STORAGE_KEYS } from '../constants/sessionStorage'

export const logoutProcess = () => {
  /** DESC : 로그아웃 로직 따라 모든 쿠키를 삭제시켜준다. -> 추후 이 부분이 문제가 될 시 유저 관련 정보만 삭제하도록 수정 */
  LBoxCookieStorageUtils.clear()

  /** DESC : 로그아웃 로직 따라 세션 스토리지의 nonProfitOrgInfo 데이터를 삭제한다. */
  sessionStorage.removeItem(SESSION_STORAGE_KEYS.nonProfitOrgInfo)

  /** DESC : 로그아웃 로직 따라 로컬 스토리지의 특정 데이터 삭제*/
  webLocalStorage.clearExcept([
    FINGERPRINT_DEVICE_ID_LOCALSTORAGE_KEY,
    TOP_BANNER_NOT_SHOWN_UNTIL_KEY,
    LAWYER_EXAM_13TH_EVENT_POPUP_NO_SHOWN_KEY,
    SRP_INTERPRETATION_BANNER_NOT_SHOWN_KEY,
    LFIND_POPUP_STORAGE_KEY,
    CASE_EVENTS_V2_KEY,
    LAW_SCHOOL_CAN_USE_LBOX_AI_KEY,
    LAW_SCHOOL_LBOX_AI_POPUP_KEY,
  ])
}

const lboxHttpClient = isServer()
  ? createHttpClient({
      baseURL: `https://${process.env.NEXT_PUBLIC_LBOX_API_HOST}/api`,
      headers: {
        'Content-Type': 'application/json',
      },
    })
  : createHttpClient({
      baseURL: '/api',
      headers: {
        'Content-Type': 'application/json',
      },
    })

lboxHttpClient.interceptors.request.use((config) => {
  if (!isServer()) {
    return config
  }

  const token = serverTokenStorage.getStore()

  if (token) {
    config.headers.Cookie = `${TOKEN_FROM_LBOX_CLIENT}=${token}`
  }

  return config
})

lboxHttpClient.interceptors.response.use(
  (response) => response,
  (error: unknown) => {
    /** CSR인 경우에만 대응 */
    if (!isAxiosError(error) || isServer()) {
      return Promise.reject(error)
    }

    if (error.response?.data === 'token_error') {
      logoutProcess()

      location.reload()

      return Promise.reject(error)
    }

    if (error.response?.data === 'login_required') {
      logoutProcess()

      return Promise.reject(error)
    }

    return Promise.reject(error)
  }
)

const lfindHttpClient = createHttpClient({
  baseURL: `https://${LFIND_API_HOST}/api`,
  headers: {
    'Content-Type': 'application/json',
  },
})

lfindHttpClient.interceptors.request.use((config) => {
  if (isClient()) {
    const token = LBoxCookieStorageUtils.get(LBOX_LOGIN_TOKEN_KEY)
    token && (config.headers.Authorization = `Bearer ${token}`)
  }
  if (isServer()) {
    const token = serverTokenStorage.getStore()
    token && (config.headers.Authorization = `Bearer ${token}`)
  }
  return config
})

export { lboxHttpClient, lfindHttpClient }
