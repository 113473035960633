import cn from 'classnames'

import { twMergeLDS } from '../../../utils'

export interface SpacingProps {
  /** 픽셀 단위의 간격 크기 */
  size: number
  className?: string
}

export const Spacing = ({ size, className }: SpacingProps) => {
  return <div style={{ height: `${size}px` }} className={twMergeLDS(cn('flex-none'), className)} />
}
