import type { PropsWithChildren } from 'react'
import { useCallback } from 'react'

import { IconX, Portal } from '@lbox/shared/components'
import { useMediaQuery } from '@lbox/shared/hooks'
import { noop } from '@lbox/shared/utils'

import useFocusTrap from '@charlietango/use-focus-trap'
import cn from 'classnames'
import type { MotionProps } from 'framer-motion'
import { motion } from 'framer-motion'

import { twMergeLDS } from '../../../utils/twMergeLDS'
import { Backdrop } from '../backdrop'
import type { BottomSheetProps } from '../bottom-sheet'
import { BottomSheet } from '../bottom-sheet'
import { IconOnlyButton } from '../button'
import { Body } from './Body'
import { Footer } from './Footer'
import { Headline } from './Headline'
import { Image } from './Image'
import { Tabs } from './Tabs'
import { useBodyScrollLock } from './useBodyScrollLock'
import { useEsc } from './useEsc'
import { getBottomSheetHeightProps } from './utils'

export type ModalProps = {
  isOpen: boolean
  hasBackdrop?: boolean
  activeFocusTrap?: boolean
  showsCloseButton?: boolean
  noCloseOnBackdropClick?: boolean
  size?: 'small' | 'medium'
  rootClassName?: string
  bottomSheetRootClassName?: string
  fixedCenter?: boolean
  framerMotionProps?: MotionProps
  onClose?: VoidFunction
} & BottomSheetProps

/**
 * 모바일 screen size 에서는 bottom sheet 역할 까지 겸하는 컴포넌트
 *
 * @param isOpen 열림/닫힘 상태
 * @param showsCloseButton X(닫기)버튼 노출 여부
 * @param noCloseOnBackdropClick Backdrop 영역 클릭 시 닫힘 여부
 * @param maxHeightFull bottom sheet 의 max-height 값이 최대 window innerHeight 까지 차지할 수 있는지 여부
 * @param heightFull {true} => bottom sheet 의 height 값을 window innerHeight 로 설정
 * @param rootClassName Modal 컴포넌트에 적용할 className
 * @param bottomSheetRootClassName BottomSheet 컴포넌트에 적용할 className
 * @param backdropClassName Backdrop 컴포넌트에 적용할 className
 * @param modalPosition 모달의 위치를 우상단, 센터 결정하는 props
 * @param onOpen 모달을 열 때 수행할 callback 함수
 * @param onClose 모달을 닫을 때 수행할 callback 함수
 * @param {'small' | 'medium'} size 모달의 width
 *
 */
export const Modal = ({
  isOpen,
  activeFocusTrap = true,
  hasBackdrop = true,
  showsCloseButton = false,
  noCloseOnBackdropClick = false,
  maxHeightFull,
  heightFull,
  backdropClassName = '',
  rootClassName = '',
  fixedCenter = true,
  bottomSheetRootClassName = '',
  onOpen: handleOpen,
  onClose: handleClose = noop,
  children,
  size = 'small',
  framerMotionProps = {},
}: PropsWithChildren<ModalProps>) => {
  const { isTabletOrDesktop } = useMediaQuery()
  const ref = useFocusTrap(isOpen && activeFocusTrap)

  useEsc(handleClose)
  useBodyScrollLock(isOpen)

  const bottomSheetHeightProps = getBottomSheetHeightProps({ maxHeightFull, heightFull })

  const handleClickBackdrop = useCallback(() => {
    if (noCloseOnBackdropClick) {
      return
    }
    handleClose()
  }, [noCloseOnBackdropClick, handleClose])

  return (
    <>
      {isTabletOrDesktop ? (
        <Portal rootId="modal-root" isOpen={isOpen}>
          {/* Dimmed */}
          {hasBackdrop && <Backdrop isDimmed onClick={handleClickBackdrop} rootClassName={backdropClassName} />}
          {/* Modal */}
          <motion.div
            ref={ref}
            role="dialog"
            aria-modal="true"
            data-allow-scroll // body-scroll-lock 에서 특정 DOM 요소의 scroll 을 허용해주기 위한 attribute
            className={twMergeLDS(
              cn(
                'flex flex-col',
                'relative',
                'fixed',
                'rounded-[8px] bg-white',
                {
                  'fixed-center': fixedCenter,
                },
                {
                  'w-[500px]': size === 'small',
                  'w-[600px]': size === 'medium',
                },
                'h-fit max-h-[calc(var(--custom-vh,1vh)*100-64px)]',
                'overflow-y-hidden',
                {
                  'shadow-none': hasBackdrop,
                  'shadow-modalShadow': !hasBackdrop,
                }
              ),
              rootClassName
            )}
            {...framerMotionProps}
          >
            {children}
            {/* close button */}
            {showsCloseButton && (
              <span className={cn('absolute right-[12px] top-[12px]')}>
                <IconOnlyButton
                  buttonType="ghost"
                  size="large"
                  onClick={handleClose}
                  icon={IconX}
                  className={cn('text-foreground-secondary')}
                />
              </span>
            )}
          </motion.div>
        </Portal>
      ) : (
        <BottomSheet
          isOpen={isOpen}
          hasBackdrop={hasBackdrop}
          activeFocusTrap={activeFocusTrap}
          showsCloseButton={showsCloseButton}
          noCloseOnBackdropClick={noCloseOnBackdropClick}
          backdropClassName={backdropClassName}
          rootClassName={`${rootClassName} ${bottomSheetRootClassName}`}
          onOpen={handleOpen}
          onClose={handleClose}
          {...bottomSheetHeightProps}
        >
          {children}
        </BottomSheet>
      )}
    </>
  )
}

Modal.Headline = Headline
Modal.Tabs = Tabs
Modal.Body = Body
Modal.Image = Image
Modal.Footer = Footer
