export const SEARCH_LOG_LOCAL_STORAGE_KEY = {
  PREV_PAGE_PATH_KEY: 'prevPagePath',
  CURRENT_PAGE_PATH_KEY: 'currentPagePath',
  PREV_PAGE_ID_KEY: 'prevPageId',
  CURRENT_PAGE_ID_KEY: 'pageId',
  PREV_EVENT_ID_KEY: 'prevEventId',
  PREV_EVENT_TYPE_KEY: 'prevEventType',
  SEARCH_SESSION_ID: 'search_session_id',
  PREV_LEAF_ID_KEY: 'prevLeafId',
} as const
