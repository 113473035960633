import { useIsomorphicLayoutEffect } from '@toss/react'
import { isServer } from '@toss/utils'

/**
 * 비정상 URL로 접속 시 리디렉션 해주는 훅
 */
export const useInvalidUrlRedirection = () => {
  /**
   * @description 도메인 마지막에 . 이 붙어있는 경우 제거
   * @example https://lbox.kr./v2/login -> https://lbox.kr/v2/login
   */
  useIsomorphicLayoutEffect(() => {
    if (isServer()) {
      return
    }

    if (window.location.hostname.endsWith('.')) {
      const url = new URL(window.location.href)
      url.hostname = url.hostname.slice(0, -1)
      window.location.assign(url.href)
    }
  }, [])
}
