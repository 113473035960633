'use client'

import { useEffect, useRef } from 'react'
import ReactDOM from 'react-dom'

import { useIsMounted } from '@toss/react'

import type { StrictPropsWithChildren } from '../../types/utils'

export enum PORTAL_ROOT_ID {
  LAYER = 'layer-root',
  DRAWER = 'drawer-root',
  TOAST = 'toast-root',
  MODAL = 'modal-root',
  OVERLAY = 'overlay-root',
  POPOVER = 'popover-root',
  TOOLTIP = 'tooltip-root',
}

export interface PortalProps {
  isOpen: boolean
  rootId: `${PORTAL_ROOT_ID}`
  forceMount?: boolean
}

export const Portal = ({
  isOpen = false,
  forceMount = false,
  rootId,
  children,
}: StrictPropsWithChildren<PortalProps>) => {
  const rootRef = useRef<HTMLDivElement | null>(null)
  const isMounted = useIsMounted()

  useEffect(() => {
    rootRef.current = document.getElementById(rootId) as HTMLDivElement

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (forceMount) {
    return isMounted && rootRef.current ? ReactDOM.createPortal(children, rootRef.current) : null
  }

  return isOpen && isMounted && rootRef.current ? ReactDOM.createPortal(children, rootRef.current) : null
}
