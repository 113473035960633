import type { UseQueryOptions } from '@tanstack/react-query'
import { useMutation, useQuery } from '@tanstack/react-query'

import { LBOX_USER_API_ENDPOINTS } from './endpoints'
import { getLboxUser, patchLboxUserJob } from './fetchers'
import type { LboxUser } from '../../types/user/lboxUser'

export const QUERY_KEYS = {
  lboxUser: [LBOX_USER_API_ENDPOINTS.base],
}

export const useGetLboxUser = (
  options?: Omit<UseQueryOptions<LboxUser, unknown, LboxUser>, 'queryKey' | 'queryFn'>
) => {
  return useQuery<LboxUser, unknown, LboxUser>({
    queryKey: QUERY_KEYS.lboxUser,
    queryFn: getLboxUser,
    ...options,
  })
}

export const usePatchLboxUserJob = () => {
  return useMutation({ mutationFn: patchLboxUserJob })
}
