/**
 * UUID 문자열 값을 계산하여 반환한다
 */
export const generateUUID = () => {
  // random 한 hex 문자열을 반환하는 함수
  const randomHex = () =>
    Math.floor((1 + Math.random()) * 0x10000) // 65536 <= number < 65536 * 2
      .toString(16) // '10000' ~ '1ffff'
      .substring(1) // '0000' ~ 'ffff'

  // ex) 2925ecbe-7c98-9cf8-5b98-72c8712323e1
  return `${randomHex()}${randomHex()}-${randomHex()}-${randomHex()}-${randomHex()}-${randomHex()}${randomHex()}${randomHex()}`
}
