import { noop } from '@lbox/shared/utils'

import type { ModalProps } from '@lbox-kr/libra/v2'
import { atom } from 'jotai'

export const globalModalInitialStatus: ModalProps = {
  isOpen: false,
  showsCloseButton: false,
  noCloseOnBackdropClick: false,
  size: 'small',
  rootClassName: '',
  onClose: noop,
}

export type GlobalModalStatusAtom = ModalProps

export const globalModalStatusAtom = atom<GlobalModalStatusAtom>(globalModalInitialStatus)
