import { useCallback } from 'react'
import type { ReactNode } from 'react'

import { useAtom } from 'jotai'

import { globalErrorModalStatusAtom } from '@/atoms/globalErrorModal'

export type OpenGlobalErrorModalParams = {
  isOpen?: boolean
  title: string
  content: ReactNode
  buttonLabel: string
  handleClickButton: VoidFunction
}

/**
 * 전역 Error Modal 을 사용하기 위한 global hook
 */
export const useGlobalErrorModal = () => {
  const [globalErrorModalStatus, setGlobalErrorModalStatus] = useAtom(globalErrorModalStatusAtom)
  const { isOpen, title, content, rightButtonInformation } = globalErrorModalStatus

  const open = useCallback(({ title, content, buttonLabel, handleClickButton }: OpenGlobalErrorModalParams) => {
    setGlobalErrorModalStatus((...prevStatus) => ({
      ...prevStatus,
      isOpen: true,
      title,
      rightButtonInformation: {
        type: 'button',
        label: buttonLabel,
        handleClick: handleClickButton,
      },
      content,
    }))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const close = useCallback(() => {
    setGlobalErrorModalStatus((prevStatus) => ({
      ...prevStatus,
      isOpen: false,
      title: '',
      rightButtonInformation: {
        type: 'button',
      },
      content: null,
    }))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return { isOpen, title, content, rightButtonInformation, open, close }
}
