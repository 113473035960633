import { useRef } from 'react'

import { IconCopy } from '@lbox/shared/components'
import { webSessionStorage } from '@lbox/shared/utils'

import { BottomSheet, Button, Tooltip } from '@lbox-kr/libra/v2'
import { useBooleanState } from '@toss/react'
import { clipboard } from '@toss/utils'

import LiVector from '@/components/pricing/pricing/faq-section/accordion/LiVector'
import { amplitudeTrack } from '@/utils/amplitude/amplitudeTrack'

const EZWEL_URL = 'https://polbokji.ezwel.com/'

const EzwelAppBottomSheet = () => {
  const [isBottomSheetOpen, , closeBottomSheet] = useBooleanState(true)
  const [isTooltipOpen, openTooltip, closeTooltip] = useBooleanState(false)
  const copyUrlSucceedTooltipTimeoutRef = useRef<NodeJS.Timeout>()

  const onClickCopyUrl = async () => {
    const isSucceed = await clipboard.writeText(EZWEL_URL)

    if (isSucceed) {
      openTooltip()
      amplitudeTrack('click_copy_polbokji_url')

      clearTimeout(copyUrlSucceedTooltipTimeoutRef.current)
      copyUrlSucceedTooltipTimeoutRef.current = setTimeout(closeTooltip, 5000)

      return
    }
  }

  const onClickCloseButton = () => {
    closeBottomSheet()
    webSessionStorage.setItem('isEzwelAppBottomSheetClosed', true)
  }

  return (
    <BottomSheet isOpen={isBottomSheetOpen} showsCloseButton onClose={onClickCloseButton}>
      <BottomSheet.Headline className="text-lds2-heading3-semibold">
        웹 브라우저에서
        <br />
        이지웰을 통해 접속해주세요.
      </BottomSheet.Headline>
      <BottomSheet.Body>
        <div className="flex flex-col gap-[16px]">
          <ul className="flex flex-col gap-[8px] text-lds2-body2-regular">
            <li className="flex">
              <LiVector className="shrink-0" />
              <span>이지웰 앱을 통해 엘박스를 이용하시는 경우, 뒤로가기 등 일부 기능이 작동하지 않습니다.</span>
            </li>
            <li className="flex">
              <LiVector className="shrink-0" />
              <span>엘박스는 Safari, Chrome 등 웹 브라우저에서 정상적인 사용이 가능합니다.</span>
            </li>
            <li className="flex">
              <LiVector className="shrink-0" />
              <span>
                아래 주소를 복사하신 뒤, 사용하시는 웹 브라우저에 붙여넣기하여 복지몰 로그인 후 엘박스를 이용해주시기
                바랍니다.
              </span>
            </li>
          </ul>

          <div className="flex flex-col gap-[8px]">
            <p className="rounded-[4px] border bg-background-secondary p-[10px_12px] text-lds2-body1-regular">
              {EZWEL_URL}
            </p>
            <Tooltip
              triggerWrapperClassName="w-full flex-[50%]"
              isOpen={isTooltipOpen}
              initialDirection="bottom-center"
              color="green"
              title="주소를 복사하였습니다"
              autoHideDuration={5000}
              disableHoverListener
              isPortalTooltip
            >
              <Button
                type="button"
                buttonType="outlined"
                label="주소 복사"
                size="large"
                width="full"
                leftIcon={<IconCopy size="24" className="text-foreground-secondary" />}
                onClick={onClickCopyUrl}
              />
            </Tooltip>
          </div>
        </div>
      </BottomSheet.Body>
      <BottomSheet.Footer>
        <Button type="button" buttonType="tonal" label="닫기" size="large" width="full" onClick={onClickCloseButton} />
      </BottomSheet.Footer>
    </BottomSheet>
  )
}

export default EzwelAppBottomSheet
