import type { Logger } from '../../utils/logger'
import { noop } from '../toolkit'

export const consoleLogger: Logger = {
  init: noop,
  setUser: noop,
  clearUser: noop,
  log: (...args) => console.log(...args),
  debug: (...args) => console.debug(...args),
  info: (...args) => console.info(...args),
  warn: (...args) => console.warn(...args),
  error: (...args) => console.error(...args),
}
