import { datadogLogs } from '@datadog/browser-logs'
import { isServer } from '@toss/utils'
import { isAxiosError } from 'axios'

import type { Logger, LoggerInitOptions } from '.'

const initDatadogLogs = ({ datadog }: LoggerInitOptions) => {
  const { clientToken, env, service, releaseVersion } = datadog

  if (isServer() || !clientToken) {
    return
  }

  datadogLogs.init({
    clientToken,
    site: 'datadoghq.com',
    forwardErrorsToLogs: true,
    forwardConsoleLogs: ['warn', 'error'],
    sessionSampleRate: 100,
    env,
    service,
    version: releaseVersion,
    beforeSend: (event) => {
      if (
        event?.message?.toLowerCase()?.includes('unleash') ||
        event?.message?.toLowerCase()?.includes('minified react error #418')
      ) {
        return false
      }

      return true
    },
  })
}

export const datadogLogger: Logger = {
  init: initDatadogLogs,
  setUser: datadogLogs.setUser.bind(datadogLogs),
  clearUser: datadogLogs.clearUser.bind(datadogLogs),
  log: (message, context, error) => datadogLogs.logger.log(message, context, 'debug', error),
  debug: datadogLogs.logger.debug.bind(datadogLogs.logger),
  info: datadogLogs.logger.info.bind(datadogLogs.logger),
  warn: datadogLogs.logger.warn.bind(datadogLogs.logger),
  error: (message: string, messageContext: object = {}, error?: Error) => {
    const datadogErrorLogger = datadogLogs.logger.error.bind(datadogLogs.logger)

    if (isAxiosError(error)) {
      datadogErrorLogger(
        message,
        {
          ...messageContext,
          error,
          errorResponse: error.response,
          errorRequest: error.request,
          isAxiosError: true,
        },
        error
      )

      return
    }

    datadogErrorLogger(message, messageContext, error)
  },
}
