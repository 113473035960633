import type { ReactNode, FormEventHandler, MouseEventHandler } from 'react'

import { Portal } from '@lbox/shared/components'
import type { StrictPropsWithChildren } from '@lbox/shared/types'

import cn from 'classnames'

import { Button } from '../../atoms/button/Button'
import type { ButtonProps } from '../../atoms/button/Button'
import { Backdrop } from '../../v2/backdrop/Backdrop'

export type ButtonInformation = Partial<ButtonProps> & {
  type: 'button' | 'submit' | 'reset'
  handleClick?: MouseEventHandler
  handleSubmit?: FormEventHandler
}

export interface ModalProps {
  title: ReactNode
  isOpen: boolean
  size?: 'default' | 'wide'
  leftButtonInformation?: ButtonInformation
  rightButtonInformation?: ButtonInformation
  isCustomModal?: boolean
  onClickBackdrop: VoidFunction
}

export const Modal = ({
  title,
  isOpen,
  size = 'default',
  leftButtonInformation,
  rightButtonInformation,
  isCustomModal = false,
  children,
  onClickBackdrop,
}: StrictPropsWithChildren<ModalProps>) => {
  return (
    <Portal rootId="modal-root" isOpen={isOpen}>
      <Backdrop isDimmed onClick={onClickBackdrop} />

      <section
        data-allow-scroll // body-scroll-lock 에서 특정 DOM 요소의 scroll 을 허용해주기 위한 attribute
        className={cn(
          'fixed left-[50%] top-[50%] translate-x-[-50%] translate-y-[-50%] break-keep rounded-[4px] bg-white shadow-[0_0_25px_rgba(0,0,0,0.15)]',
          'flex flex-col',
          'max-h-[calc(var(--custom-vh,1vh)*90)]',
          'overflow-y-hidden',
          {
            'w-[400px]': size === 'default',
            'w-[640px]': size === 'wide',
          }
        )}
      >
        <div className={cn('bg-white', 'text-heading2 text-zinc-700', 'px-[24px] pb-[12px] pt-[24px]')}>{title}</div>

        {isCustomModal && <>{children}</>}

        {!isCustomModal && (
          <>
            {/* Body 영역 */}
            <div className={cn('flex grow flex-col gap-y-[12px]', 'px-[24px] pb-[24px] pt-[12px]', 'overflow-y-auto')}>
              {children}
            </div>

            {/* 하단 CTA 버튼 영역 */}
            <div className={cn('flex gap-[12px]', 'bg-white', 'px-[24px] pb-[24px] pt-[12px]', 'shadow-buttonShadow')}>
              {leftButtonInformation && (
                <Button
                  label={leftButtonInformation.label ?? ''}
                  type={leftButtonInformation.type ?? 'button'}
                  buttonType="outlined"
                  color={leftButtonInformation.color ?? 'lbox-blue'}
                  size={leftButtonInformation.size ?? 'large'}
                  width="full"
                  state={leftButtonInformation.state ?? 'active'}
                  onClick={leftButtonInformation.handleClick}
                />
              )}

              {rightButtonInformation && (
                <Button
                  label={rightButtonInformation.label ?? ''}
                  type={rightButtonInformation.type}
                  buttonType="filled"
                  color={rightButtonInformation.color ?? 'lbox-blue'}
                  size={rightButtonInformation.size ?? 'large'}
                  width="full"
                  state={rightButtonInformation.state ?? 'active'}
                  onClick={rightButtonInformation.handleClick}
                  onSubmit={rightButtonInformation.handleSubmit}
                />
              )}
            </div>
          </>
        )}
      </section>
    </Portal>
  )
}
