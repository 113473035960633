'use client'

import type { ErrorInfo, ReactNode } from 'react'
import { Component } from 'react'

import type { ErrorBoundaryState, ErrorBoundaryWithFallbackProps } from './types'
import { logger } from '../../utils'

function isReactFC<T>(args: React.FC<T> | ReactNode): args is React.FC<T> {
  return typeof args === 'function'
}

export class ErrorBoundaryWithFallback extends Component<ErrorBoundaryWithFallbackProps, ErrorBoundaryState> {
  state: ErrorBoundaryState = {
    error: null,
  }

  resetErrorState = () => {
    this.setState({ error: null })
  }

  static getDerivedStateFromError(error: Error): ErrorBoundaryState {
    return { error }
  }

  /** errorInfo : 에러 발생 컴포넌트 stack - Captures which component contained the exception, and its ancestors. */
  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    const { disableLogging = false, information = null } = this.props

    if (disableLogging) {
      return
    }

    const ctx = {
      errorInfo,
      information,
    }

    logger.error('ErrorBoundaryWithFallback Component에서 catch 된 처리되지 않은 에러', ctx, error)
  }

  render() {
    const { children, fallback: Fallback } = this.props

    const { error } = this.state

    if (error && isReactFC(Fallback)) {
      return <Fallback error={error} resetErrorState={this.resetErrorState} />
    }

    if (error && !isReactFC(Fallback)) {
      return Fallback
    }

    return children
  }
}
