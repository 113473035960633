import type {
  ComponentPropsWithoutRef,
  ComponentPropsWithRef,
  ElementType,
  ForwardRefRenderFunction,
  PropsWithoutRef,
} from 'react'
import { forwardRef } from 'react'

/**
 * @author 준혁
 * @description as property 를 통해 컴포넌트에 다형성을 부여하기 위한 유틸 타입
 */
type PolymorphicComponentPropsWithRef<T extends ElementType, Props = object> = {
  as?: T
} & ComponentPropsWithoutRef<T> &
  Props & {
    ref?: ComponentPropsWithRef<T>['ref']
  }

type PolymorphicComponentPropsWithoutRef<T extends ElementType, Props = object> = {
  as?: T
} & ComponentPropsWithoutRef<T> &
  Props

interface PolymorphicFactoryPayload {
  defaultAs: ElementType
  defaultRef: unknown
  props: object
}

export function polymorphicFactory<Payload extends PolymorphicFactoryPayload>(
  ui: ForwardRefRenderFunction<
    Payload['defaultRef'],
    PropsWithoutRef<PolymorphicComponentPropsWithoutRef<Payload['defaultAs'], Payload['props']>>
  >
) {
  type ComponentPropsWithRef<T extends ElementType> = PolymorphicComponentPropsWithRef<T, Payload['props']>
  type Component = <T extends ElementType = Payload['defaultAs']>(
    props: ComponentPropsWithRef<T>
  ) => React.ReactElement | null

  const Component = forwardRef(ui)

  return Component as unknown as Component
}
