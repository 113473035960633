export const TOKEN_FROM_LBOX_CLIENT = 'lboxToken'

/**
 * 메인 페이지 > Top Banner 관련 LocalStorage key
 */
export const TOP_BANNER_NOT_SHOWN_UNTIL_KEY = 'topBannerNotShownUntil'

export const LOCAL_STORAGE_KEY_SEARCH_HISTORY = 'history'

/**
 * 메인 페이지 > 엘파인드 홍보 팝업 관련 LocalStorage key
 */
export const LFIND_POPUP_STORAGE_KEY = 'lfind-popup-brand-story'

/** LBox 로그인 토큰 key */
export const LBOX_LOGIN_TOKEN_KEY = 'lboxToken'

/**
 * 메인페이지 > 변호사 시험 13회 이벤트 홍보 팝업 관련 LocalStorage Key
 */
export const LAWYER_EXAM_13TH_EVENT_POPUP_NO_SHOWN_KEY = 'lawyer-exam-13th-popup-not-shown'

/**
 * SRP > 유권해석 배너 관련 LocalStorage Key
 */
export const SRP_INTERPRETATION_BANNER_NOT_SHOWN_KEY = 'srp-interpretation-banner-not-shown'

/**
 * 사건캘린더 리뉴얼 (베타) 사용 여부. 베타종료 후 삭제 예정입니다.
 */
export const CASE_EVENTS_V2_KEY = 'case-events-v2'

/**
 * @author 준혁
 * @description
 * 메인페이지 로스쿨 팝업 키
 */
export const LAW_SCHOOL_LBOX_AI_POPUP_KEY = 'law-school-lbox-ai-popup'

/**
 * @author 준혁
 * @description
 * 서비스 사용 여부 관련 키
 */
export const LAW_SCHOOL_CAN_USE_LBOX_AI_KEY = 'law-school-can-use-lbox-ai'
