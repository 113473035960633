export const TOKEN_FROM_LBOX_CLIENT = 'lboxToken'

export const PDP_FUNNEL_KEY = {
  statute: 'statute_pdp_funnel_root_id',
  practice: 'practice_pdp_funnel_root_id',
}

/**
 * 메인 페이지 > Top Banner 관련 LocalStorage key
 */
export const TOP_BANNER_NOT_SHOWN_UNTIL_KEY = 'topBannerNotShownUntil'

/**
 * 메인페이지 > 엘박스 AI 홍보 팝업 관련 LocalStorage Key
 */
export const LBOX_AI_POPUP_NOT_SHOWN_KEY = 'lbox-ai-event-modal-popup-not-shown-key'

/**
 * 메인페이지 > 변호사 시험 13회 이벤트 홍보 팝업 관련 LocalStorage Key
 */
export const LAWYER_EXAM_13TH_EVENT_POPUP_NO_SHOWN_KEY = 'lawyer-exam-13th-popup-not-shown'

/**
 * amplitude에 전송할 user properties 저장 시점 (angular와 동일한 key 사용)
 */
export const USER_TOKEN_UPDATED = 'userTokenUpdated'
