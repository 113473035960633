import { useEffect } from 'react'

import { clearAllBodyScrollLocks, disableBodyScrollWithAllowingTouchMove } from '@lbox/shared/utils'

export const useBodyScrollLock = (isOpen: boolean) => {
  useEffect(() => {
    if (isOpen) {
      disableBodyScrollWithAllowingTouchMove()
    } else {
      clearAllBodyScrollLocks()
    }

    return () => {
      clearAllBodyScrollLocks()
    }
  }, [isOpen])
}
