export const TOOLTIP_BODY_DIRECTION = ['top', 'bottom', 'right', 'left'] as const
export type TooltipBodyDirection = (typeof TOOLTIP_BODY_DIRECTION)[number]
export const isTooltipBodyDirection = (args: string): args is TooltipBodyDirection => {
  return TOOLTIP_BODY_DIRECTION.includes(args as TooltipBodyDirection)
}

export const TOOLTIP_TIP_DIRECTION = ['start', 'center', 'end'] as const
export type TooltipTipDirection = (typeof TOOLTIP_TIP_DIRECTION)[number]
export const isTooltipTipDirection = (args: string): args is TooltipTipDirection => {
  return TOOLTIP_TIP_DIRECTION.includes(args as TooltipTipDirection)
}

export type TooltipDirection = `${TooltipBodyDirection}-${TooltipTipDirection}`

export type TooltipColors = 'black' | 'white' | 'green'
