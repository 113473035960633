import type { HTMLAttributes, ReactNode, RefObject } from 'react'
import { useEffect, useRef } from 'react'

import { Portal } from '@lbox/shared/components'
import type { StrictPropsWithChildren } from '@lbox/shared/types'

import { useIsMounted } from '@toss/react'
import cn from 'classnames'
import { AnimatePresence, motion } from 'framer-motion'

import { TipShape } from './TipShape'
import type { TooltipColors, TooltipDirection } from './types'
import { TIP_ADJUST, useTooltipPosition } from './useTooltipPosition'
import { useTooltipTrigger } from './useTooltipTrigger'
import { TOOLTIP_FADE_IN } from '../../../constants/animation'
import { twMergeLDS } from '../../../utils/twMergeLDS'

export type TooltipBaseProps = {
  /** 툴팁 타이틀 */
  title?: ReactNode
  /** 툴팁 본문 */
  description?: ReactNode
  /** 툴팁 색상 */
  color?: TooltipColors
  /** 툴팁이 보여지는 방향. 만약 화면의 공간이 부족하면 반대 방향으로 보여집니다. */
  initialDirection?: TooltipDirection
  /** 툴팁 꼬리 위치 조정 값 */
  tipAdjust?: number
  /** 툴팁이 보여지기 전의 지연 시간 */
  showDelay?: number
  /** 툴팁이 사라지기 전의 지연 시간 */
  hideDelay?: number
  /** 트리거 요소를 감싸는 div에 전달되는 클래스 */
  triggerWrapperClassName?: string
  /** 툴팁 요소에 전달되는 클래스 */
  tooltipWrapperClassName?: string
  /** 꼬리가 없는 툴팁으로 렌더링할 지 여부 */
  nonTip?: boolean
  /** Portal로 툴팁을 열 수 있다. fixed 제한 속성이 불가피하게 사용되는 경우 true */
  isPortalTooltip?: boolean
  /** 자동으로 툴팁 사라지는 시간(모바일 기본 동작으로 지정됨) */
  autoHideDuration?: number
  /** 툴팁 추가 offset이 필요한 경우 */
  offset?: number
  /** 툴팁이 나타날 때 실행될 callback */
  onOpen?: VoidFunction
  /** 툴팁이 사라질 때 실행될 callback */
  onClose?: VoidFunction
}

export type TooltipAdditionalProps =
  | {
      /**
       * 툴팁이 보여질 지 여부를 직접 결정.
       * isOpen을 사용하는 경우에는 disableHoverListener를 true로 설정하여 hover 이벤트를 무시해야 합니다.
       */
      isOpen: boolean
      /**
       * 기본적으로 툴팁은 마우스 hover 상태일 때 보여집니다.
       * disableHoverListener는 hover 동작을 비활성화하기 위해 사용됩니다.
       */
      disableHoverListener: true
    }
  | {
      isOpen?: null
      disableHoverListener?: false
    }

export type TooltipProps = StrictPropsWithChildren<
  TooltipBaseProps & TooltipAdditionalProps & HTMLAttributes<HTMLDivElement>
>

/**
 * 툴팁은 대체로 UI에 추가적인 설명을 하기 위해 사용합니다.
 */
export const Tooltip = (props: TooltipProps) => {
  const { children, triggerWrapperClassName, isPortalTooltip = false, ...rest } = props

  const triggerRef = useRef<HTMLDivElement>(null)

  const isMounted = useIsMounted()

  return (
    <>
      <div ref={triggerRef} className={twMergeLDS('relative inline-block w-fit', triggerWrapperClassName)}>
        {children}
        {!isPortalTooltip && isMounted && <TooltipBody triggerRef={triggerRef} {...rest} />}
      </div>

      {isPortalTooltip && (
        <Portal isOpen rootId="tooltip-root">
          <TooltipBody triggerRef={triggerRef} {...rest} />
        </Portal>
      )}
    </>
  )
}

type TooltipBodyProps = Omit<TooltipProps, 'children' | 'triggerWrapperClassName' | 'isPortalTooltip'> & {
  triggerRef: RefObject<HTMLElement>
}

const DEFAULT_OFFSET = 8 // 대상과 툴팁 박스 사이의 간격

const TooltipBody = (props: TooltipBodyProps) => {
  const {
    triggerRef,
    isOpen,
    title,
    description,
    color = 'black',
    tipAdjust = TIP_ADJUST.default,
    initialDirection = 'top-center',
    showDelay = 0,
    hideDelay = 0,
    disableHoverListener = false,
    nonTip = false,
    tooltipWrapperClassName,
    autoHideDuration,
    offset = DEFAULT_OFFSET,
    onOpen,
    onClose,
    ...rest
  } = props

  const isShownTooltip = useTooltipTrigger({
    isOpen,
    showDelay,
    hideDelay,
    disableHoverListener,
    triggerRef,
    autoHideDuration,
  })

  const { position, tipPosition, tooltipBoxRef } = useTooltipPosition({
    triggerRef,
    tipAdjust,
    title,
    description,
    initialDirection,
    offset,
  })

  useEffect(() => {
    if (isShownTooltip && typeof onOpen === 'function') {
      onOpen()
      return
    }

    if (!isShownTooltip && typeof onClose === 'function') {
      onClose()
      return
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isShownTooltip])

  return (
    <AnimatePresence>
      {isShownTooltip && (
        <motion.div key="tooltip-box" {...TOOLTIP_FADE_IN()}>
          <div
            {...rest}
            ref={tooltipBoxRef}
            className={twMergeLDS(
              cn(
                'fixed',
                'flex flex-col gap-y-[8px]',
                'rounded-[4px]',
                'min-w-fit',
                'whitespace-nowrap',
                'shadow-[0px_1px_2px_0px_rgba(0,0,0,0.06),0px_1px_3px_0px_rgba(0,0,0,0.10)]',
                {
                  'p-[10px_8px]': title,
                  'p-[4px_8px]': !title,
                },
                {
                  'border border-border-success bg-background-success-high text-white': color === 'green',
                  'border border-border-secondary bg-background-elevation-level1 text-text-primary': color === 'white',
                  'border border-border-secondary-inverse bg-background-primary-inverse text-text-primary-inverse':
                    color === 'black',
                }
              ),
              tooltipWrapperClassName
            )}
            style={{ top: `${position.top}px`, left: `${position.left}px` }}
          >
            {title && <p className={cn('text-lds2-body3-medium-trimmed')}>{title}</p>}
            {description && <span className={cn('text-lds2-body3-regular')}>{description}</span>}
          </div>
          {!nonTip && (
            <TipShape
              color={color}
              style={{
                top: `${tipPosition.top}px`,
                left: `${tipPosition.left}px`,
                transform: `rotate(${tipPosition.rotate}deg)`,
              }}
            />
          )}
        </motion.div>
      )}
    </AnimatePresence>
  )
}
