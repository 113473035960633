import cn from 'classnames'
import { motion } from 'framer-motion'

import { FADE_IN_AND_OUT } from '../../../constants/animation'
import { twMergeLDS } from '../../../utils/twMergeLDS'

export interface BackdropProps {
  isDimmed?: boolean
  isFadeAnimated?: boolean
  rootClassName?: string
  onClick?: VoidFunction
}

/** 전체 화면을 덮는 레이어. Modal, BottomSheet, Drawer 등의 컴포넌트 뒤에 위치한다. */
export const Backdrop = ({ isDimmed, isFadeAnimated, rootClassName, onClick }: BackdropProps) => {
  function handleClickBackdrop() {
    if (typeof onClick === 'function') {
      onClick()
    }
  }

  return (
    <motion.div
      className={twMergeLDS(cn('fixed inset-0', { 'bg-dim': isDimmed }, rootClassName))}
      onClick={handleClickBackdrop}
      {...(isFadeAnimated ? FADE_IN_AND_OUT : {})}
    />
  )
}
