import type { PropsWithChildren } from 'react'

import cn from 'classnames'

import { twMergeLDS } from '../../../utils'
import type { ButtonBoxProps } from '../button-box'
import { ButtonBox } from '../button-box'

export type FooterProps = ButtonBoxProps

export const Footer = ({ children, rootClassName, className, ...props }: PropsWithChildren<FooterProps>) => {
  return (
    <ButtonBox
      rootClassName={twMergeLDS(cn('shrink-0'), rootClassName)}
      className={twMergeLDS(cn('rounded-none lds2-tablet:rounded-[0_0_8px_8px]'), className)}
      {...props}
    >
      {children}
    </ButtonBox>
  )
}
