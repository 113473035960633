import { useIsomorphicLayoutEffect } from '@toss/react'
import { isServer } from '@toss/utils'

import { useWindowResize } from '../useWindowResize'

export const useCustomViewportUnit = () => {
  useWindowResize({ onResize: setCustomVh })

  useIsomorphicLayoutEffect(() => {
    if (isServer()) {
      return
    }
    setCustomVh()
    setStaticCustomVh() // window resize 에 반응하지 않는 static 속성 값 추가
  }, [])
}

function setCustomVh() {
  const vh = window.innerHeight * 0.01
  window.document.documentElement.style.setProperty('--custom-vh', `${vh}px`)
}

function setStaticCustomVh() {
  const vh = window.innerHeight * 0.01
  window.document.documentElement.style.setProperty('--custom-vh-static', `${vh}px`)
}
