import { isClient } from '@toss/utils'

import { removeNullishField } from '../../utils/remove-nullish-field'

export const PATH_REGISTER_MY_CASE_PAGE = '/user/myCase/pending'

export const PATH_CASE_ANALYTICS = '/legal-analytics/case-analytics'
export const PATH_JUDGE_ANALYTICS = '/legal-analytics/judge-analytics'
export const PATH_TRAFFIC_CRIMES = '/legal-analytics/case-analytics/traffic-crimes'
export const PATH_SEXUAL_CRIMES = '/legal-analytics/case-analytics/sexual-crimes'

export const V2_PREFIX = 'v2'

export const LBOX_PAGE_PATH = {
  root: '/',
  ['mou-verification']: '/mou-verification',
  /** 판례 pdp */
  case: {
    withDocId: ({ court, caseno }: { court: string; caseno: string }) => `/case/${court}/${caseno}`,
    approval: {
      withDocId: ({ court, caseno, approvalId }: { court: string; caseno: string; approvalId: string }) =>
        `/case/approval/${court}/${caseno}/${approvalId}`,
    },
  },
  /** 법률 분석 */
  legalAnalytics: {
    caseAnalytics: {
      index: '/legal-analytics/case-analytics',

      trafficCrimes: {
        index: () => `${LBOX_PAGE_PATH.legalAnalytics.caseAnalytics.index}/traffic-crimes`,
        civilTraffic: () => `${LBOX_PAGE_PATH.legalAnalytics.caseAnalytics.trafficCrimes.index()}/civil-traffic`,
        자동차사고손해배상: () =>
          `${LBOX_PAGE_PATH.legalAnalytics.caseAnalytics.trafficCrimes.index()}/car-accident-damage-compensation`,
        licenseCancellation: () =>
          `${LBOX_PAGE_PATH.legalAnalytics.caseAnalytics.trafficCrimes.index()}/license-cancellation`,
      },

      보험: {
        index: () => `${LBOX_PAGE_PATH.legalAnalytics.caseAnalytics.index}/insurance`,
        자동차: () => `${LBOX_PAGE_PATH.legalAnalytics.caseAnalytics.보험.index()}/car`,
        배상책임_대인: () => `${LBOX_PAGE_PATH.legalAnalytics.caseAnalytics.보험.index()}/liability-for-damages`,
        배상책임_대물: () => `${LBOX_PAGE_PATH.legalAnalytics.caseAnalytics.보험.index()}/liability-for-property`,
        생명_질병_상해: () => `${LBOX_PAGE_PATH.legalAnalytics.caseAnalytics.보험.index()}/life-disease-injury`,
        화재_운송_등: () => `${LBOX_PAGE_PATH.legalAnalytics.caseAnalytics.보험.index()}/fire-transportation`,
      },

      인사_노무: {
        index: () => `${LBOX_PAGE_PATH.legalAnalytics.caseAnalytics.index}/labor`,
        근로기준법위반_부정수급: () =>
          `${LBOX_PAGE_PATH.legalAnalytics.caseAnalytics.인사_노무.index()}/labor-standards-act-violation-crimes`,
        임금_퇴직금: () => `${LBOX_PAGE_PATH.legalAnalytics.caseAnalytics.인사_노무.index()}/wage-and-severance-pay`,
        부당해고_징계: () =>
          `${LBOX_PAGE_PATH.legalAnalytics.caseAnalytics.인사_노무.index()}/unfair-dismissal-and-disciplinary-action`,
        노동조합: () => `${LBOX_PAGE_PATH.legalAnalytics.caseAnalytics.인사_노무.index()}/union`,
      },

      산업재해: {
        index: () => `${LBOX_PAGE_PATH.legalAnalytics.caseAnalytics.index}/industrial-accident`,
        산업안전보건범죄: () =>
          `${LBOX_PAGE_PATH.legalAnalytics.caseAnalytics.산업재해.index()}/occupational-safety-and-health-crimes`,
        산업재해배상: () => `${LBOX_PAGE_PATH.legalAnalytics.caseAnalytics.산업재해.index()}/compensation`,
        판정취소: () => `${LBOX_PAGE_PATH.legalAnalytics.caseAnalytics.산업재해.index()}/judgement-cancellation`,
      },

      성범죄: {
        index: () => `${LBOX_PAGE_PATH.legalAnalytics.caseAnalytics.index}/sexual-crimes`,
        강간: () => `${LBOX_PAGE_PATH.legalAnalytics.caseAnalytics.성범죄.index()}/rape`,
        디지털성범죄: () => `${LBOX_PAGE_PATH.legalAnalytics.caseAnalytics.성범죄.index()}/digital-sexual-crimes`,
      },

      재산범죄: {
        index: () => `${LBOX_PAGE_PATH.legalAnalytics.caseAnalytics.index}/property-crimes`,
        사기: () => `${LBOX_PAGE_PATH.legalAnalytics.caseAnalytics.재산범죄.index()}/fraud`,
        횡령_배임: () =>
          `${LBOX_PAGE_PATH.legalAnalytics.caseAnalytics.재산범죄.index()}/embezzlement-and-breach-of-trust`,
        전자금융거래법위반: () =>
          `${LBOX_PAGE_PATH.legalAnalytics.caseAnalytics.재산범죄.index()}/electronic-financial-transactions-act-violation`,
        절도: () => `${LBOX_PAGE_PATH.legalAnalytics.caseAnalytics.재산범죄.index()}/theft`,
      },

      형사일반: {
        index: () => `${LBOX_PAGE_PATH.legalAnalytics.caseAnalytics.index}/criminal`,
        명예훼손_모욕: () => `${LBOX_PAGE_PATH.legalAnalytics.caseAnalytics.형사일반.index()}/defamation`,
        폭력: () => `${LBOX_PAGE_PATH.legalAnalytics.caseAnalytics.형사일반.index()}/violence`,
        마약: () => `${LBOX_PAGE_PATH.legalAnalytics.caseAnalytics.형사일반.index()}/drug`,
      },

      laborStandardsActViolationCrimes: () =>
        `${LBOX_PAGE_PATH.legalAnalytics.caseAnalytics.index}/labor-standards-act-violation-crimes`,

      sexualCrimes: () => `${LBOX_PAGE_PATH.legalAnalytics.caseAnalytics.index}/sexual-crimes`,
      digitalSexualCrimes: () => `${LBOX_PAGE_PATH.legalAnalytics.caseAnalytics.index}/digital-sexual-crimes`,

      fraudCrimes: () => `${LBOX_PAGE_PATH.legalAnalytics.caseAnalytics.index}/fraud-crimes`,
      embezzlementAndBreachOfTrustCrimes: () =>
        `${LBOX_PAGE_PATH.legalAnalytics.caseAnalytics.index}/embezzlement-and-breach-of-trust-crimes`,
      electronicFinancialTransactionsActViolationCrimes: () =>
        `${LBOX_PAGE_PATH.legalAnalytics.caseAnalytics.index}/electronic-financial-transactions-act-violation-crimes`,
      theftCrimes: () => `${LBOX_PAGE_PATH.legalAnalytics.caseAnalytics.index}/theft-crimes`,

      defamationCrimes: () => `${LBOX_PAGE_PATH.legalAnalytics.caseAnalytics.index}/defamation-crimes`,
      violenceCrimes: () => `${LBOX_PAGE_PATH.legalAnalytics.caseAnalytics.index}/violence-crimes`,
      drugCrimes: () => `${LBOX_PAGE_PATH.legalAnalytics.caseAnalytics.index}/drug-crimes`,
    },

    judgeAnalytics: {
      index: '/legal-analytics/judge-analytics',
    },
  },
  /** 주석서/실무서/법률문서 */
  publication: {
    commentary: {
      index: '/publication/commentary',
      detail: (filename: string) => `/publication/commentary/${filename}`,
      ['new-detail']: (args: {
        volumeName: string
        bookName: string
        dataLink: string
        startPageIdx?: string
        title?: string
        lboxPath?: string
      }) => {
        const { volumeName, bookName, dataLink, startPageIdx, title, lboxPath = '' } = args

        const lboxPathWithoutTitle = lboxPath.replace(` > ${title}`, '')
        const [편, 장] = lboxPathWithoutTitle.split(' > ')

        const searchParams = new URLSearchParams(
          removeNullishField({
            part: 편,
            chapter: 장,
            title,
            dataLink,
            bookName,
            startPageIdx,
          })
        )

        return `/publication/commentary/${encodeURIComponent(volumeName)}?${searchParams.toString()}`
      },
    },
    practice: {
      index: '/publication/practice',
      detail: (filename: string) => `/publication/practice/${filename}`,
      ['new-detail']: (args: {
        volumeName: string
        bookName: string
        dataLink: string
        startPageIdx?: string
        title?: string
        lboxPath?: string
      }) => {
        const { volumeName, bookName, dataLink, startPageIdx, title, lboxPath = '' } = args

        const lboxPathWithoutTitle = lboxPath.replace(` > ${title}`, '')
        const [편, 장] = lboxPathWithoutTitle.split(' > ')

        const searchParams = new URLSearchParams(
          removeNullishField({
            part: 편,
            chapter: 장,
            title,
            dataLink,
            bookName,
            startPageIdx,
          })
        )

        return `/publication/practice/${encodeURIComponent(volumeName)}?${searchParams.toString()}`
      },
    },
  },
  /** 주석서 */
  commentary: {
    index: '/commentary',
  },
  /** 실무서 */
  practice: {
    index: '/practice',
  },
  /** 검색 결과 */
  search: {
    index: '/search',
    /** 판결문 검색 결과 */
    case: '/search/case',
    /** 주석서 검색 결과 */
    commentary: '/search/commentary',
    /** 법령 검색 결과 */
    statute: '/search/statute',
    /** 논문 검색 결과 */
    reference: '/search/reference',
    /** 결정례 검색 결과 */
    trialDecision: '/search/trialdecision',
    /** 유권해석 검색 결과(deprecated) */
    interpretation: '/search/interpretation',
    /** 뉴스 검색 결과 */
    news: '/search/news',
  },
  /** 변호사 기능 */
  lawyerProfile: {
    /** 내 수행사건 관리 */
    caseManagement: '/user/case-management',
    /** 내 수행사건 등록 */
    myCase: '/user/myCase',
    /** 내 프로필 관리 */
    index: '/user/profile',
    /** 프로필 사전등록 온보딩 */
    profileOnboarding: '/user/profile-onboarding',
    /** 프로필 간편등록 온보딩 */
    simpleOnboarding: '/user/simple-onboarding',
  },
  /** 법령 */
  statute: {
    index: '/statute',
    withId: ({ id }: { id: string }) => `/statute/${id}`,
    withJomunId: ({
      id,
      jomunId,
      proclamationNumber,
      statuteName,
    }: {
      id: string
      jomunId: string
      proclamationNumber: string
      statuteName: string
    }) => `/statute/${id}/${jomunId}?proclamationNumber=${proclamationNumber}&statuteName=${statuteName}`,
  },
  /** 결정례 */
  pdfViewer: {
    index: '/pdf-viewer',
    withId: ({
      documentId,
      documentPageType,
      documentType,
    }: {
      documentId: string
      documentPageType?: string
      documentType: 'trialdecision' | 'interpretation' | 'north-korea-statute'
    }) => {
      const searchParams = new URLSearchParams(
        removeNullishField({
          documentPageType,
          documentType,
        })
      )

      return `${LBOX_PAGE_PATH.pdfViewer.index}/${documentId}?${searchParams.toString()}`
    },
  },
  /** 유권해석 */
  textViewer: {
    index: '/text-viewer',
    withId: ({
      documentId,
      documentPageType,
      documentType,
    }: {
      documentId: string
      documentPageType: string
      documentType: 'trialdecision' | 'interpretation'
    }) =>
      `${LBOX_PAGE_PATH.textViewer.index}?documentPageType=${documentPageType}&id=${documentId}&documentType=${documentType}`,
  },
  /** 계정 */
  myPage: {
    /** 계정/요금제 */
    index: '/user/myPage/info',
    /** 결제 내역 */
    paymentLogs: '/user/logs/payment',
    /** 포인트 내역 */
    pointLogs: '/user/logs/point',
    /** 알림 설정 */
    alarmSettings: '/user/alarm',
    /** 친구 초대 */
    invite: '/user/invite',
  },
  /** 서비스 이용 내역 */
  history: {
    /** 등록요청한 판례 */
    requestCases: '/user/requestCases',
    /** 내 복대리 */
    mySubstituteHistory: '/user/substitute/history',
    /** 검색조건 알림 */
    subscribeAlarm: '/user/subscribe',
  },
  /** 요금제 */
  pricing: {
    index: '/pricing',
    business: {
      contact: {
        index: '/pricing/business/contact',
        withParams: ({ company, usersCount }: { company: string; usersCount: number }) =>
          `/pricing/business/contact?company=${company}&usersCount=${usersCount}`,
      },
      pay: {
        index: '/pricing/business/pay',
        withParams: ({ company, usersCount }: { company: string; usersCount: number }) =>
          `/pricing/business/pay?company=${company}&usersCount=${usersCount}`,
      },
      'quotation-contact': '/pricing/business/quotation-contact',
    },
    public: {
      contact: '/pricing/public/contact',
    },
    standard: {
      pay: '/pricing/standard/pay',
    },
    'law-school': {
      index: '/pricing/law-school',
    },
  },
  /** User */
  user: {
    pricing: '/user/pricing',
    /** 마이페이지 */
    myPage: {
      /** 알림 설정 */
      alarm: '/user/myPage/alarm',
    },
    invite: '/user/invite',
    /** 엘피인드 브랜드 스토리 배너 (iframe용) */
    lfindBanner: '/user/lfind-banner',
  },
  /** 회원가입 */
  register: {
    default: '/register',
    index: () => {
      return isClient()
        ? `${LBOX_PAGE_PATH.register.default}?from=${encodeURIComponent(window.location.href)}`
        : LBOX_PAGE_PATH.register.default
    },
    complete: {
      index: '/register/complete',
      'legal-professional': '/register/complete/legal-professional',
      'law-school-student': '/register/complete/law-school-student',
    },
  },
  /** 로그인 */
  login: {
    default: '/login',
    index: ({ from = '' }: { from?: string } = {}) => {
      return isClient()
        ? `${LBOX_PAGE_PATH.login.default}?from=${
            from ? encodeURIComponent(from) : encodeURIComponent(window.location.href)
          }`
        : `${LBOX_PAGE_PATH.login.default}?from=${encodeURIComponent(from)}`
    },
  },
  /** 이벤트 */
  event: {
    laborattorneysExam: {
      '33rd': '/event/laborattorneys-exam/33rd',
    },
    lawyerExam: {
      '13th': '/event/lawyer-exam/13th',
    },
    mou: {
      kicaa: '/event/mou/kicaa',
    },
    lawyer: {
      '20k-celebration': '/event/lawyer/20k-celebration',
    },
  },
  /** 공지 */
  notice: {
    'legal-analyitcs': {
      'loss-adjuster': '/notice/legal-analytics/loss-adjuster',
      police: '/notice/legal-analytics/police',
    },
    'plan-pricing': '/notice/plan-pricing',
  },
  /** 스칼라 */
  scholar: {
    notice: {
      'mutual-savings-bank-law': '/scholar/notice/mutual-savings-bank-law',
      '노동조합법과-노사관계-실무': '/scholar/notice/노동조합법과-노사관계-실무',
    },
  },
  /** 사건일정관리 */
  caseEvents: {
    calendar: '/case-events/calendar',
    list: '/case-events/list',
  },
} as const
