import type { ButtonHTMLAttributes, HTMLAttributes, MouseEventHandler, ReactNode } from 'react'
import { forwardRef, useId, useMemo } from 'react'

import { createContext } from '@lbox/shared/createContext'
import type { StrictPropsWithChildren } from '@lbox/shared/types'

import classNames from 'classnames'
import { motion } from 'framer-motion'

import { twMergeLDS } from '../../../utils/twMergeLDS'

export interface TabsProps extends HTMLAttributes<HTMLDivElement> {
  /** Tabs의 전체 라인에 border bottom 존재 여부 */
  hasTabsBorderBottom?: boolean
  className?: string
  size?: 'small' | 'large' | 'xlarge'
}

const [TabsContextProvider, useTabsContext] = createContext<{ tabsId: string; size: 'small' | 'large' | 'xlarge' }>({
  name: 'Tabs',
})

/**
 * 연관된 그룹의 컨텐츠를 사용자가 자유롭게 이동할 수 있도록 돕는 컴포넌트
 */
const TabsRoot = forwardRef<HTMLDivElement, StrictPropsWithChildren<TabsProps>>(function Tabs(
  { hasTabsBorderBottom = false, className, size = 'large', children, ...props }: StrictPropsWithChildren<TabsProps>,
  forwardedRef
) {
  const tabsId = useId()
  const ctxValue = useMemo(() => ({ tabsId, size }), [tabsId, size])

  return (
    <TabsContextProvider value={ctxValue}>
      <div
        ref={forwardedRef}
        className={twMergeLDS(
          classNames(
            'overflow-x-scroll no-scrollbar',
            { 'shadow-[inset_0_-1px_0_0_theme(colors.border-secondary)]': hasTabsBorderBottom },
            {
              'h-[40px]': size === 'small',
              'h-[56px]': size === 'large',
              'h-[64px]': size === 'xlarge',
            }
          ),
          className
        )}
        {...props}
      >
        {children}
      </div>
    </TabsContextProvider>
  )
})

type TabListProps = HTMLAttributes<HTMLDivElement>

const TabList = ({ className, children, ...props }: TabListProps) => {
  return (
    <div className={twMergeLDS(classNames('flex'), className)} {...props}>
      {children}
    </div>
  )
}

export interface TabItem {
  label: string
  value: string
}

export interface TabItemProps extends Omit<ButtonHTMLAttributes<HTMLButtonElement>, 'onClick'> {
  /** 해당 Tab 선택 여부 */
  isSelected: boolean
  /**
   * label: 실제 TabItem에 보여지는 UI용 Text
   * value: 데이터 key 값으로 사용하는 Text
   */
  item: TabItem
  /** TabItem의 우측 accessory 컴포넌트 */
  rightAccessoryComponent?: ReactNode
  /** 클릭한 tab item을 selectedItem으로 변경하는 event handler */
  onClick?: MouseEventHandler
  size?: 'small' | 'large' | 'xlarge'
}

export const TabItem = forwardRef<HTMLDivElement, TabItemProps>(function TabItem(
  { isSelected, item, rightAccessoryComponent, onClick, size: sizeProp, className, ...props }: TabItemProps,
  forwardedRef
) {
  const ctxValue = useTabsContext()
  const size = sizeProp ?? ctxValue.size

  return (
    <div
      ref={forwardedRef}
      key={item.label}
      className={twMergeLDS(
        classNames('relative w-fit whitespace-nowrap', {
          'px-[6px] py-[4px]': size === 'small',
          'px-[6px] py-[12px]': size === 'large',
          'px-[6px] py-[16px]': size === 'xlarge',
        }),
        className
      )}
    >
      <button
        className={twMergeLDS(
          classNames(
            'h-[32px] rounded-[4px] px-[8px] interaction-state-high',
            size === 'small' && {
              'text-lds2-body2-regular-trimmed text-text-tertiary [@media(pointer:fine)]:hover:text-text-primary':
                !isSelected,
              'text-lds2-body2-medium-trimmed text-text-primary': isSelected,
            },
            size === 'large' && {
              'text-lds2-body1-medium-trimmed text-text-tertiary [@media(pointer:fine)]:hover:text-text-primary':
                !isSelected,
              'text-lds2-body1-medium-trimmed text-text-primary': isSelected,
            },
            size === 'xlarge' && {
              'text-lds2-heading4-semibold text-text-tertiary [@media(pointer:fine)]:hover:text-text-primary':
                !isSelected,
              'text-lds2-heading4-semibold text-text-primary': isSelected,
            },
            { 'flex items-center gap-[4px]': rightAccessoryComponent }
          )
        )}
        type="button"
        name={item.label}
        onClick={onClick}
        {...props}
      >
        {item.label}
        {rightAccessoryComponent}
      </button>
      {isSelected && (
        <motion.div
          layoutId={`${ctxValue.tabsId}-tab-indicator`}
          className={classNames(
            'absolute bottom-0 left-0 w-full bg-tabs-border-selected',
            size === 'xlarge' ? 'h-[3px]' : 'h-[2px]'
          )}
          transition={{ duration: 0.2, ease: 'linear' }}
        />
      )}
    </div>
  )
})

export const Tabs = Object.assign(TabsRoot, { List: TabList, Item: TabItem })
