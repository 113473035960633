import { useEffect } from 'react'

export const useOpenExternalBrowser = () => {
  useEffect(() => {
    openExternalBrowser()
  }, [])
}

const openExternalBrowser = () => {
  const userAgent = navigator.userAgent.toLowerCase()

  if (userAgent.match(/kakaotalk/i)) {
    location.href = `kakaotalk://web/openExternal?url=${encodeURIComponent(location.href)}`
  }
}
