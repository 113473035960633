import type { ElementType } from 'react'

import { polymorphicFactory } from '@lbox/shared/utils'

import type { IconProps } from '@phosphor-icons/react'
import cn from 'classnames'

import type { ButtonProps, ButtonSize } from './Button'
import { Button } from './Button'
import { twMergeLDS } from '../../../utils/twMergeLDS'

type IconOnlyButtonSize = ButtonSize | 'xsmall'

interface IconOnlyButtonProps extends Omit<ButtonProps, 'size'> {
  icon: React.FC<IconProps>
  iconProps?: IconProps
  size?: IconOnlyButtonSize
}

/**
 * LDS2 Button에 의존합니다. label 없이 Icon 만을 렌더링 하는 IconOnlyButton 입니다.
 *
 * @param icon phosphor-icons/react 의 IconProps 타입의 Props를 받는 아이콘 함수 컴포넌트
 * @param size IconOnlyButton의 크기
 * @param className LDS2 Button의 additional className (padding, width, height 속성을 제외한 나머지 스타일 속성 및 클래스 이름 정의 가능)
 * @param iconProps LDS2 Icon의 props (아이콘 컴포넌트에 넣어줄 prop을 이 객체 필드에 넣어주면 됩니다.)
 * @param ...buttonProps Button Props
 * @returns Button 컴포넌트
 */
export const IconOnlyButton = polymorphicFactory<{
  defaultAs: 'button'
  defaultRef: HTMLButtonElement
  props: IconOnlyButtonProps
}>(function IconOnlyButton(props, forwardedRef) {
  const { as, icon: Icon, size = 'medium', iconProps = {}, className, ...buttonProps } = props

  const buttonSize = getButtonSizeClassName(size)
  const iconSize = getIconSize(size)

  return (
    <Button
      as={as as ElementType | undefined}
      ref={forwardedRef}
      className={twMergeLDS(cn(buttonSize), className)}
      leftIcon={<Icon size={iconSize} {...iconProps} />}
      /**
       * @todo 추후 타입 정의 수정 필요
       */
      size={(size === 'xsmall' ? null : size) as ButtonSize}
      width="fit"
      {...buttonProps}
    />
  )
})

const getButtonSizeClassName = (size: IconOnlyButtonSize) => {
  if (size === 'xsmall') {
    return 'p-[4px] w-[24px] h-[24px]'
  }

  if (size === 'small') {
    return 'px-[6px] w-[32px] h-[32px]'
  }

  if (size === 'medium') {
    return 'px-[9px] w-[40px] h-[40px]'
  }

  return 'px-[12px] w-[48px] h-[48px]'
}

const getIconSize = (size: IconOnlyButtonSize) => {
  if (size === 'xsmall') {
    return 16
  }

  if (size === 'small') {
    return 20
  }

  if (size === 'medium') {
    return 22
  }

  return 24
}
