import { useEffect } from 'react'

// esc 키를 누르면 callback 함수를 실행하는 hook
export const useEsc = (callback: VoidFunction) => {
  useEffect(() => {
    const handleEsc = (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        callback()
      }
    }

    document.addEventListener('keydown', handleEsc)

    return () => {
      document.removeEventListener('keydown', handleEsc)
    }
  }, [callback])
}
