/* eslint-disable */
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core'
export type Maybe<T> = T | null
export type InputMaybe<T> = Maybe<T>
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] }
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> }
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> }
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never }
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never }
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string }
  String: { input: string; output: string }
  Boolean: { input: boolean; output: boolean }
  Int: { input: number; output: number }
  Float: { input: number; output: number }
  /** An RFC-3339 compliant Full Date Scalar */
  Date: { input: any; output: any }
  /** A slightly refined version of RFC-3339 compliant DateTime Scalar */
  DateTime: { input: any; output: any }
  /** A JSON scalar */
  JSON: { input: any; output: any }
}

export enum AggregateType {
  DateHistogram = 'date_histogram',
  Histogram = 'histogram',
  MultiTerms = 'multi_terms',
  Nested = 'nested',
  Term = 'term',
}

export type AggregatedType = {
  __typename?: 'AggregatedType'
  docCount?: Maybe<Scalars['Int']['output']>
  key?: Maybe<Scalars['String']['output']>
}

export type Attorney = {
  __typename?: 'Attorney'
  span?: Maybe<Array<Maybe<Scalars['Int']['output']>>>
  text?: Maybe<Scalars['String']['output']>
  type?: Maybe<Scalars['String']['output']>
  value?: Maybe<AttorneyValue>
}

export type AttorneyValue = {
  __typename?: 'AttorneyValue'
  lawFirm?: Maybe<Scalars['String']['output']>
  name?: Maybe<Scalars['String']['output']>
  serial?: Maybe<Scalars['String']['output']>
  side?: Maybe<Scalars['String']['output']>
  title?: Maybe<Scalars['String']['output']>
}

export enum CalendarInterval {
  Day = 'Day',
  Hour = 'Hour',
  Minute = 'Minute',
  Month = 'Month',
  Quarter = 'Quarter',
  Second = 'Second',
  Week = 'Week',
  Year = 'Year',
}

export enum CaseLawAggregateField {
  DecisionDate = 'decision_date',
  DocumentTypeKeyword = 'document_type__keyword',
  OrganizationKeyword = 'organization__keyword',
}

export type CaseLawAggregateInput = {
  /** calendar_interval */
  calendarInterval?: InputMaybe<CalendarInterval>
  /** Term Aggregate 할 field 명 */
  field?: InputMaybe<CaseLawAggregateField>
  fields?: InputMaybe<Array<InputMaybe<CaseLawAggregateField>>>
  /** 최소 문서 수(default = 1) */
  minDocCount?: InputMaybe<Scalars['Int']['input']>
  /** Term Aggregate size */
  size?: InputMaybe<Scalars['Int']['input']>
  /** Aggregation Type */
  type?: InputMaybe<AggregateType>
}

/** 심결례 document_page_type 값 */
export enum CaseLawDocumentPageType {
  /** 행정심판위원회 */
  AdministrativeAppealsCommission = 'ADMINISTRATIVE_APPEALS_COMMISSION',
  /** 심결례_감사원 */
  AuditInspection = 'AUDIT_INSPECTION',
  /** 중앙환경분쟁조정위원회 */
  CentralEnvironmentalDisputeResolutionCommission = 'CENTRAL_ENVIRONMENTAL_DISPUTE_RESOLUTION_COMMISSION',
  /** 중앙토지수용위원회 */
  CentralLandExpropriationCommission = 'CENTRAL_LAND_EXPROPRIATION_COMMISSION',
  /** 심결례_국민권익위원회_결정례 */
  CivilRights = 'CIVIL_RIGHTS',
  /** 국민권익위원회_중앙위원회_의결서 */
  CivilRightsCentral = 'CIVIL_RIGHTS_CENTRAL',
  /** 심결례_국민권익위원회_소위원회_의결서 */
  CivilRightsSubcommittee = 'CIVIL_RIGHTS_SUBCOMMITTEE',
  /** 헌법재판소 */
  ConstitutionalCourt = 'CONSTITUTIONAL_COURT',
  /** 고용보험심사위원회 */
  EmploymentInsuranceDeliberationCommittee = 'EMPLOYMENT_INSURANCE_DELIBERATION_COMMITTEE',
  /** 심결례_공정거래위원회 */
  FairTradeCommission = 'FAIR_TRADE_COMMISSION',
  /** 금융위원회 */
  FinancialServicesCommission = 'FINANCIAL_SERVICES_COMMISSION',
  /** 금융감독원_금융분쟁조정결정서 */
  FinancialSupervisoryServiceFinancialDisputeSettlementDecision = 'FINANCIAL_SUPERVISORY_SERVICE_FINANCIAL_DISPUTE_SETTLEMENT_DECISION',
  /** 인사혁신처_소청심사위원회_소청사례 */
  HrInnovationAppealsReviewCommitteeCases = 'HR_INNOVATION_APPEALS_REVIEW_COMMITTEE_CASES',
  /** 심결례_국가인권위원회 */
  HumanRights = 'HUMAN_RIGHTS',
  /** 산업재해보상보험재심사위원회 */
  IndustrialAccidentCompensationInsuranceReexaminationCommittee = 'INDUSTRIAL_ACCIDENT_COMPENSATION_INSURANCE_REEXAMINATION_COMMITTEE',
  /** 특허청 */
  Iptab = 'IPTAB',
  /** 방송통신위원회 */
  KoreaCommunicationsCommission = 'KOREA_COMMUNICATIONS_COMMISSION',
  /** 노동위원회 */
  LaborCommission = 'LABOR_COMMISSION',
  /** 국세청_국세법령정보시스템 */
  NationalTaxServiceRulings = 'NATIONAL_TAX_SERVICE_RULINGS',
  /** 해양안전심판원 */
  OceanSafetyTribunal = 'OCEAN_SAFETY_TRIBUNAL',
  /** 심결례_개인정보보호위원회 */
  PersonalInformation = 'PERSONAL_INFORMATION',
  /** 증권선물위원회 */
  SecuritiesFuturesCommission = 'SECURITIES_FUTURES_COMMISSION',
  /** 조세심판원 */
  TaxTribunal = 'TAX_TRIBUNAL',
  /** 근로복지공단 */
  WorkersWelfareService = 'WORKERS_WELFARE_SERVICE',
}

export enum CaseLawFilterField {
  DecisionDate = 'decision_date',
  DocumentTypeKeyword = 'document_type__keyword',
  OrganizationKeyword = 'organization__keyword',
  ResultKeyword = 'result__keyword',
}

export type CaseLawFilterInput = {
  /** 기간 필터: ex) now-1y/d ~ now/d */
  dateRange?: InputMaybe<DateRangeParam>
  /** 필터로 사용할 field 명 */
  field: CaseLawFilterField
  /** 필터 type */
  type?: InputMaybe<FilterInputType>
  /** 필터로 사용할 값들(string) */
  values?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

export type CaseLawSearchRequest = {
  aggregateInputs?: InputMaybe<Array<InputMaybe<CaseLawAggregateInput>>>
  documentPageType?: InputMaybe<CaseLawDocumentPageType>
  filterInputs?: InputMaybe<Array<InputMaybe<CaseLawFilterInput>>>
  /** 1 based page */
  page?: InputMaybe<Scalars['Int']['input']>
  /** 검색어 */
  query: Scalars['String']['input']
  queryElements?: InputMaybe<Array<InputMaybe<QueryElement>>>
  size?: InputMaybe<Scalars['Int']['input']>
  sortInputs?: InputMaybe<Array<InputMaybe<CaseLawSortInput>>>
}

export type CaseLawSearchRequestV2 = {
  aggregateInputs?: InputMaybe<Array<InputMaybe<CaseLawAggregateInput>>>
  /** legacy */
  documentPageType?: InputMaybe<CaseLawDocumentPageType>
  /** legacy */
  filterInputs?: InputMaybe<Array<InputMaybe<CaseLawFilterInput>>>
  highlightFields?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** 1 based page */
  page?: InputMaybe<Scalars['Int']['input']>
  /** legacy */
  query: Scalars['String']['input']
  /** legacy */
  queryElements?: InputMaybe<Array<InputMaybe<QueryElement>>>
  searchInput?: InputMaybe<SearchInput>
  size?: InputMaybe<Scalars['Int']['input']>
  sortInputs?: InputMaybe<Array<InputMaybe<CaseLawSortInput>>>
}

export enum CaseLawSortField {
  /** 스코어 */
  Score = '_score',
  /** 결정 일자 */
  DecisionDate = 'decision_date',
  /** doc_id_origin */
  DocIdOriginKeyword = 'doc_id_origin__keyword',
}

export type CaseLawSortInput = {
  /** 정렬로 사용할 field 명 */
  field: CaseLawSortField
  order?: InputMaybe<SortOrder>
  unmappedType?: InputMaybe<FieldType>
}

export type CaseName = {
  __typename?: 'CaseName'
  caseName?: Maybe<Scalars['String']['output']>
  sentIndexes?: Maybe<Array<Maybe<Scalars['Int']['output']>>>
}

export type CaseRef = {
  __typename?: 'CaseRef'
  span?: Maybe<Array<Maybe<Scalars['Int']['output']>>>
  text?: Maybe<Scalars['String']['output']>
  type?: Maybe<Scalars['String']['output']>
  value?: Maybe<CaseRefValue>
}

export type CaseRefValue = {
  __typename?: 'CaseRefValue'
  announceDate?: Maybe<Scalars['String']['output']>
  caseNo?: Maybe<Scalars['String']['output']>
  court?: Maybe<Scalars['String']['output']>
}

export type CaseRelation = {
  __typename?: 'CaseRelation'
  caseRefList?: Maybe<Array<Maybe<CaseRelationCaseRef>>>
  confirmed?: Maybe<Scalars['Boolean']['output']>
  disposedBy?: Maybe<Scalars['String']['output']>
  disposedList?: Maybe<Array<Maybe<Dispose>>>
  docId: Scalars['String']['output']
  lowerList?: Maybe<Array<Maybe<CaseRelationCaseRef>>>
  quoteDisposed?: Maybe<Dispose>
  quoteList?: Maybe<Array<Maybe<CaseRelationCaseRef>>>
  splitedList?: Maybe<Array<Maybe<CaseRelationCaseRef>>>
  updatedAt?: Maybe<Scalars['DateTime']['output']>
}

export type CaseRelationCaseRef = {
  __typename?: 'CaseRelationCaseRef'
  announceDate?: Maybe<Scalars['Date']['output']>
  disposedBy?: Maybe<Scalars['String']['output']>
  docId: Scalars['String']['output']
  level?: Maybe<Scalars['Int']['output']>
  referDisposed?: Maybe<Scalars['Boolean']['output']>
  result?: Maybe<Scalars['String']['output']>
  revisedList?: Maybe<Array<Maybe<Scalars['String']['output']>>>
}

/** 모델 결과 */
export type CaseResult = {
  __typename?: 'CaseResult'
  /** 판결문 주문 */
  courtOrder?: Maybe<Scalars['String']['output']>
  docId: Scalars['String']['output']
  result?: Maybe<CaseResultResult>
}

export type CaseResultResult = {
  __typename?: 'CaseResultResult'
  /** 형사 주문 */
  criminalCourtOrder?: Maybe<CriminalCourtOrder>
}

export type Commentary = {
  __typename?: 'Commentary'
  docNum?: Maybe<Scalars['String']['output']>
  pageSpan?: Maybe<Array<Maybe<Scalars['String']['output']>>>
  pdfUrl?: Maybe<Scalars['String']['output']>
  publishedDate?: Maybe<Scalars['Date']['output']>
  source?: Maybe<Scalars['String']['output']>
  title?: Maybe<Scalars['String']['output']>
}

export type CommissionerInfo = {
  __typename?: 'CommissionerInfo'
  name?: Maybe<Scalars['String']['output']>
  title?: Maybe<Scalars['String']['output']>
}

export type ConfirmedCase = {
  __typename?: 'ConfirmedCase'
  announceDate?: Maybe<Scalars['String']['output']>
  disposedBy?: Maybe<Scalars['String']['output']>
  docId?: Maybe<Scalars['String']['output']>
  level?: Maybe<Scalars['Int']['output']>
  result?: Maybe<Scalars['String']['output']>
  revisedList?: Maybe<Array<Maybe<Scalars['String']['output']>>>
}

export type ConfirmedInfo = {
  __typename?: 'ConfirmedInfo'
  confirmed?: Maybe<Scalars['Boolean']['output']>
  confirmedCase?: Maybe<ConfirmedCase>
}

/** 형사 주문 */
export type CriminalCourtOrder = {
  __typename?: 'CriminalCourtOrder'
  /** 모델의 confidence */
  confidence?: Maybe<Scalars['Float']['output']>
  /** 모델 parsing 결과 */
  parse?: Maybe<CriminalCourtOrderParse>
}

export type CriminalCourtOrderParse = {
  __typename?: 'CriminalCourtOrderParse'
  /** 사회봉사명령 */
  communityServicePeriod?: Maybe<Array<Maybe<CriminalCourtOrderParseElement>>>
  /** 교육이수명령 */
  educationPeriod?: Maybe<Array<Maybe<CriminalCourtOrderParseElement>>>
  /** 벌금 */
  fine?: Maybe<Array<Maybe<CriminalCourtOrderParseElement>>>
  /** 징역형 */
  penalServitude?: Maybe<Array<Maybe<CriminalCourtOrderParseElement>>>
  /**
   * 벌금형
   * @deprecated fine과 동일
   */
  penalty?: Maybe<Array<Maybe<CriminalCourtOrderParseElement>>>
  /** 집행유예 */
  probation?: Maybe<Array<Maybe<CriminalCourtOrderParseElement>>>
  /** 무죄/부분무죄 */
  someNotGuilty?: Maybe<SomeNotGuilty>
}

export type CriminalCourtOrderParseElement = {
  __typename?: 'CriminalCourtOrderParseElement'
  text?: Maybe<Scalars['String']['output']>
  type?: Maybe<Scalars['String']['output']>
  unit?: Maybe<Scalars['String']['output']>
  value?: Maybe<Scalars['Int']['output']>
}

/**
 * date range between gte and lte
 * https://www.elastic.co/guide/en/elasticsearch/reference/current/query-dsl-range-query.html#ranges-on-dates
 * ex) now-1y/d ~ now/d
 */
export type DateRangeParam = {
  gt?: InputMaybe<Scalars['String']['input']>
  gte?: InputMaybe<Scalars['String']['input']>
  lt?: InputMaybe<Scalars['String']['input']>
  lte?: InputMaybe<Scalars['String']['input']>
}

export type DeIdentifiedTarget = {
  __typename?: 'DeIdentifiedTarget'
  key?: Maybe<Scalars['String']['output']>
  value?: Maybe<Scalars['String']['output']>
}

export type Dispose = {
  __typename?: 'Dispose'
  dispose?: Maybe<Scalars['String']['output']>
  disposed?: Maybe<Array<Maybe<Scalars['String']['output']>>>
}

/**
 * 문서 Page 타입
 * -
 */
export enum DocumentPageType {
  AuthoritativeInterpretation = 'AUTHORITATIVE_INTERPRETATION',
}

export enum Environment {
  Dev = 'dev',
  Prod = 'prod',
}

export type Fact = {
  __typename?: 'Fact'
  caseNames?: Maybe<Array<Maybe<CaseName>>>
}

export type FetchedDocument = {
  __typename?: 'FetchedDocument'
  docId: Scalars['String']['output']
  documentId: Scalars['String']['output']
  pdfViewer?: Maybe<Scalars['JSON']['output']>
  textViewer?: Maybe<Scalars['JSON']['output']>
}

export enum FieldType {
  Date = 'Date',
}

export enum FilterInputType {
  And = 'and',
  DateRange = 'date_range',
  Nested = 'nested',
  Not = 'not',
  Or = 'or',
  Range = 'range',
  Terms = 'terms',
}

export type HasChildSearchInput = {
  ignoreUnmapped?: InputMaybe<Scalars['Boolean']['input']>
  innerHitsInput?: InputMaybe<InnerHitsInput>
  maxChildren?: InputMaybe<Scalars['Int']['input']>
  minChildren?: InputMaybe<Scalars['Int']['input']>
  scoreMode?: InputMaybe<Scalars['String']['input']>
  searchInput?: InputMaybe<SearchInput>
  type?: InputMaybe<Scalars['String']['input']>
}

export type Heading = {
  __typename?: 'Heading'
  span?: Maybe<Array<Maybe<Scalars['Int']['output']>>>
  text?: Maybe<Scalars['String']['output']>
}

export type HighlightEntry = {
  __typename?: 'HighlightEntry'
  key: Scalars['String']['output']
  values: Array<Scalars['String']['output']>
}

/** ISLA 모델의 결과 */
export type IeModelResult = {
  __typename?: 'IeModelResult'
  /** 모델 추출 결과 feature 의 값 중, categorical feature 값들 */
  categoricalValues?: Maybe<Array<Maybe<Scalars['String']['output']>>>
  category: Scalars['String']['output']
  /**
   * 모델 추출 결과 feature 이름
   * ex): 행위특성,
   */
  field?: Maybe<Scalars['String']['output']>
  ieConfidence?: Maybe<Scalars['Float']['output']>
  /** 모델명(ex: civil_traffic_20240419) */
  ieModelName: Scalars['String']['output']
  /** 모델 추출 결과 feature 의 값 중, numerical feature 값들 */
  numericalValues?: Maybe<Array<Maybe<Scalars['Float']['output']>>>
  /**
   * feature 값의 단위
   * ex) 원, %
   */
  unit?: Maybe<Scalars['String']['output']>
}

export type IeModelSearchAggregateInput = {
  calendarInterval?: InputMaybe<CalendarInterval>
  field?: InputMaybe<IeModelSearchAggregateInputField>
  fields?: InputMaybe<Array<InputMaybe<IeModelSearchAggregateInputField>>>
  minDocCount?: InputMaybe<Scalars['Int']['input']>
  nested?: InputMaybe<NestedIeModelSearchAggregateInput>
  size?: InputMaybe<Scalars['Int']['input']>
  type?: InputMaybe<AggregateType>
}

export enum IeModelSearchAggregateInputField {
  IeModelResultCategoricalValuesKeyword = 'ie_model_result__categorical_values__keyword',
  IeModelResultFieldKeyword = 'ie_model_result__field__keyword',
  PrecedentAnnounceDate = 'precedent__announce_date',
  PrecedentResultKeyword = 'precedent__result__keyword',
}

export type IeModelSearchFilterInput = {
  dateRange?: InputMaybe<DateRangeParam>
  field?: InputMaybe<IeModelSearchFilterInputField>
  /** type 이 not 인 경우 negate 할 filter */
  filterInput?: InputMaybe<IeModelSearchFilterInput>
  /** Logical Filter(ex: type 이 and/or) 인 경우 evaluate 할 필터들 */
  filterInputs?: InputMaybe<Array<InputMaybe<IeModelSearchFilterInput>>>
  nested?: InputMaybe<NestedIeModelSearchFilterInput>
  range?: InputMaybe<RangeParam>
  type?: InputMaybe<FilterInputType>
  values?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

export enum IeModelSearchFilterInputField {
  IeModelResultCategoricalValues = 'ie_model_result__categorical_values',
  IeModelResultCategoricalValuesKeyword = 'ie_model_result__categorical_values__keyword',
  IeModelResultCategory = 'ie_model_result__category',
  IeModelResultCategoryKeyword = 'ie_model_result__category__keyword',
  IeModelResultField = 'ie_model_result__field',
  IeModelResultFieldKeyword = 'ie_model_result__field__keyword',
  IeModelResultIeConfidence = 'ie_model_result__ie_confidence',
  IeModelResultNumericalValues = 'ie_model_result__numerical_values',
  PrecedentAnnounceDate = 'precedent__announce_date',
  PrecedentDocIdKeyword = 'precedent__doc_id__keyword',
  PrecedentResultKeyword = 'precedent__result__keyword',
}

export type IeModelSearchSortInput = {
  field: IeModelSearchSortInputField
  order?: InputMaybe<SortOrder>
  unmappedType?: InputMaybe<FieldType>
}

export enum IeModelSearchSortInputField {
  Score = '_score',
  PrecedentAnnounceDate = 'precedent__announce_date',
  PrecedentQuotedCount = 'precedent__quoted_count',
  PrecedentResultKeyword = 'precedent__result__keyword',
  PrecedentViewCount = 'precedent__view_count',
}

export type IeSearchRequest = {
  aggregateInputs?: InputMaybe<Array<InputMaybe<IeModelSearchAggregateInput>>>
  category: Scalars['String']['input']
  filterInputs?: InputMaybe<Array<InputMaybe<IeModelSearchFilterInput>>>
  page?: InputMaybe<Scalars['Int']['input']>
  /** 검색어 */
  query: Scalars['String']['input']
  queryElements?: InputMaybe<Array<InputMaybe<QueryElement>>>
  size?: InputMaybe<Scalars['Int']['input']>
  sortInputs?: InputMaybe<Array<InputMaybe<IeModelSearchSortInput>>>
}

/** ISLA 모델 검색 결과 */
export type IeSearchResponse = {
  __typename?: 'IeSearchResponse'
  docId: Scalars['String']['output']
  /** Information Extraction 모델 검색 결과 */
  ieModelResult?: Maybe<Array<Maybe<IeModelResult>>>
  labelEngineCategories?: Maybe<Array<Maybe<Scalars['String']['output']>>>
  /** ISAL 모델과 연결된 판결문 */
  precedent?: Maybe<PrecedentSearchResult>
  /** highlight snippet */
  snippet?: Maybe<Scalars['String']['output']>
}

export type IeSearchResult = {
  __typename?: 'IeSearchResult'
  aggregates?: Maybe<Array<Maybe<Array<Maybe<AggregatedType>>>>>
  count?: Maybe<Scalars['Int']['output']>
  data?: Maybe<Array<Maybe<IeSearchResponse>>>
  firstAggregate?: Maybe<Array<Maybe<AggregatedType>>>
  modelInfos?: Maybe<Scalars['JSON']['output']>
}

export type InnerHitsInput = {
  excludeSourceFields?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  from?: InputMaybe<Scalars['Int']['input']>
  highlightFields?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  includeSourceFields?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  name?: InputMaybe<Scalars['String']['input']>
  size?: InputMaybe<Scalars['Int']['input']>
  sort?: InputMaybe<Array<InputMaybe<SortInput>>>
}

export type JomunSummary = {
  __typename?: 'JomunSummary'
  content?: Maybe<Scalars['String']['output']>
  title?: Maybe<Scalars['String']['output']>
}

export type Judge = {
  __typename?: 'Judge'
  span?: Maybe<Array<Maybe<Scalars['Int']['output']>>>
  text?: Maybe<Scalars['String']['output']>
  type?: Maybe<Scalars['String']['output']>
  value?: Maybe<JudgeValue>
}

export type JudgeValue = {
  __typename?: 'JudgeValue'
  name?: Maybe<Scalars['String']['output']>
  serial?: Maybe<Scalars['String']['output']>
  title?: Maybe<Scalars['String']['output']>
}

export type LawFirm = {
  __typename?: 'LawFirm'
  span?: Maybe<Array<Maybe<Scalars['Int']['output']>>>
  text?: Maybe<Scalars['String']['output']>
  type?: Maybe<Scalars['String']['output']>
  value?: Maybe<LawFirmValue>
}

export type LawFirmValue = {
  __typename?: 'LawFirmValue'
  name?: Maybe<Scalars['String']['output']>
  side?: Maybe<Scalars['String']['output']>
}

export type LawwavePost = {
  __typename?: 'LawwavePost'
  author?: Maybe<Scalars['String']['output']>
  docId?: Maybe<Scalars['String']['output']>
  lawwaveLink?: Maybe<Scalars['String']['output']>
  lawwavePostId?: Maybe<Scalars['String']['output']>
  lboxLink?: Maybe<Scalars['String']['output']>
  subtitle?: Maybe<Scalars['String']['output']>
  tags?: Maybe<Array<Maybe<Scalars['String']['output']>>>
  title?: Maybe<Scalars['String']['output']>
}

export type LegislationHistory = {
  __typename?: 'LegislationHistory'
  effectiveAt: Scalars['Date']['output']
  /**     state: String! */
  state: LegislationState
  summary: Scalars['String']['output']
  title: Scalars['String']['output']
}

export enum LegislationState {
  /** 폐지 */
  Close = 'CLOSE',
  /** 현행 */
  Current = 'CURRENT',
  /** 예정 */
  Expect = 'EXPECT',
  /** 연혁 */
  History = 'HISTORY',
}

export type NestedIeModelSearchAggregateInput = {
  aggregateInput: IeModelSearchAggregateInput
  filter?: InputMaybe<IeModelSearchFilterInput>
  path: NestedIeModelSearchAggregateInputPath
}

export enum NestedIeModelSearchAggregateInputPath {
  IeModelResult = 'ie_model_result',
}

export type NestedIeModelSearchFilterInput = {
  filterInput: IeModelSearchFilterInput
  path: NestedIeModelSearchFilterPath
}

export enum NestedIeModelSearchFilterPath {
  IeModelResult = 'ie_model_result',
}

export type NestedSearchInput = {
  path?: InputMaybe<Scalars['String']['input']>
  searchInput?: InputMaybe<SearchInput>
}

export type NestedStatuteCommentaryAggregateInput = {
  aggregateInput: StatuteCommentaryAggregateInput
  path: NestedStatuteCommentaryAggregateInputPath
}

export enum NestedStatuteCommentaryAggregateInputPath {
  Structure = 'structure',
}

export type Precedent = {
  __typename?: 'Precedent'
  /** 선고일자 */
  announceDate?: Maybe<Scalars['Date']['output']>
  /**
   * 사건
   * @deprecated 검색 인덱싱을 위해 만들어 보는 실험 하다가 실험 종류 이후 deprecated.
   */
  case?: Maybe<Scalars['String']['output']>
  /** 판결문 설명 (내용은 lfind 판결문 title에서 가져온다) */
  caseDescription: Scalars['String']['output']
  /**
   * 사건명: caseNameList 대체 되었음
   * @deprecated caseNameList 대체 되었음
   */
  caseName?: Maybe<Scalars['String']['output']>
  /** 사건명 리스트? */
  caseNameList?: Maybe<Array<Maybe<Scalars['String']['output']>>>
  /** 사건번호 */
  caseNo?: Maybe<Scalars['String']['output']>
  /** 사건번호 리스트 */
  caseNoList?: Maybe<Array<Maybe<Scalars['String']['output']>>>
  /**
   * 참조판례
   * @deprecated section_list 안에 caseref_list 로 대체
   */
  caseRefList?: Maybe<Array<Maybe<CaseRefValue>>>
  /** 상하위판결문 연결 */
  caseRelation?: Maybe<CaseRelation>
  /** CaseResult */
  caseResult?: Maybe<CaseResult>
  /** 민사/형사/기타 */
  caseType?: Maybe<Scalars['String']['output']>
  /** 대법원 판례 해설 */
  commentaryList?: Maybe<Array<Maybe<Commentary>>>
  /** 법원명 */
  court?: Maybe<Scalars['String']['output']>
  /** 법원종류 */
  courtType?: Maybe<Scalars['String']['output']>
  /** 해당 판결문이 폐기시킨 다른 판결문들 */
  disposalList?: Maybe<Array<Maybe<CaseRef>>>
  /** 다른 판결문들에 의해 해당 판결문이 됬는지 여부 */
  disposed?: Maybe<Scalars['Boolean']['output']>
  /** 판결문 문서 ID */
  docId?: Maybe<Scalars['String']['output']>
  /**
   * 병합/본소/반소 등 여러개의 사건번호를 가지는 문서 id 리스트
   * - 서울중앙지방법원-98고합123 이 서울민사지방법원-98고합123일 수도 있음
   * - 광주고등법원전주-2019나11468-1
   * - https://lbox.kr/case/%EA%B4%91%EC%A3%BC%EA%B3%A0%EB%93%B1%EB%B2%95%EC%9B%90%EC%A[…]%A3%BC/2019%EB%82%9811468-1?q=%EB%B3%91%ED%95%A9&isLBoxOnly=false
   * - https://lbox.kr/case/%EC%84%9C%EC%9A%B8%EC%A4%91%EC%95%99%EC%A7%80%EB%B0%A9%EB%B[…]%EC%9B%90/2020%EB%85%B81360?q=%EB%B3%91%ED%95%A9&isLBoxOnly=false
   * - https://lbox.kr/case/서울중앙지방법원/2018고단386
   * - 본소/반소: https://lbox.kr/case/%EC%84%9C%EC%9A%B8%EA%B3%A0%EB%93%B1%EB%B2%95%EC%9B%90/2003%EB%82%9840769
   */
  docIdList?: Maybe<Array<Maybe<Scalars['String']['output']>>>
  /** 전원합의체 */
  enBanc?: Maybe<Scalars['Boolean']['output']>
  /** 증거 목록 리스트 */
  evidenceList?: Maybe<Array<Maybe<Scalars['String']['output']>>>
  /** 사실관계 */
  facts?: Maybe<Fact>
  /** 각주 */
  footnote?: Maybe<Scalars['JSON']['output']>
  /** 각주안 참조판례 */
  footnoteCaseRef?: Maybe<Scalars['JSON']['output']>
  /**
   * 원본 자체가 readable pdf 인지 여부
   * @deprecated 딱히 사용되는 곳 없음.
   */
  fromReadablePdf?: Maybe<Scalars['Boolean']['output']>
  /** 공보기재 (출처) */
  gjae?: Maybe<Scalars['String']['output']>
  /** 이미지 */
  images?: Maybe<Scalars['JSON']['output']>
  /** 합의/단독 */
  isPanel?: Maybe<Scalars['Boolean']['output']>
  /** 판시사항 */
  issue?: Maybe<Scalars['String']['output']>
  /** judge list? */
  judgeList?: Maybe<Array<Maybe<Judge>>>
  /** 재판관 */
  judges?: Maybe<Scalars['String']['output']>
  /** 재판부 */
  judi?: Maybe<Scalars['String']['output']>
  /** 1심/2심/3심 */
  level?: Maybe<Scalars['Int']['output']>
  /** 정제된 법원명 */
  normalizedCourt?: Maybe<Scalars['String']['output']>
  /** 주문 */
  order?: Maybe<Scalars['String']['output']>
  /** 판례 출처 */
  origin?: Maybe<Scalars['String']['output']>
  /**
   * pdf url
   * @deprecated s3에 public access 차단하면서 삭제 되었음.
   */
  pdfUrl?: Maybe<Scalars['String']['output']>
  /** 원심판결 */
  prevCase?: Maybe<Array<Maybe<CaseRef>>>
  /** record_list */
  recordList?: Maybe<Array<Maybe<Scalars['String']['output']>>>
  /** 참조조문 */
  reference?: Maybe<Reference>
  /** 청구취지 */
  relief?: Maybe<Scalars['String']['output']>
  /** 열람제한? */
  restricted?: Maybe<Scalars['Boolean']['output']>
  /** 열람제한시각 */
  restrictedAt?: Maybe<Scalars['DateTime']['output']>
  /** 결과 */
  result?: Maybe<Scalars['String']['output']>
  /** 원본 이미지 리스트 in s3 */
  s3Urls?: Maybe<Array<Maybe<Scalars['String']['output']>>>
  /** 본문 */
  sectionList: Array<Maybe<Section>>
  /** 종류 (판결 or 결정) */
  sentenceType?: Maybe<Scalars['String']['output']>
  /** statute_list */
  statuteList?: Maybe<Array<Maybe<StatuteRefValue>>>
  /** 판결요지 */
  summary?: Maybe<Scalars['String']['output']>
  /** 판결문내 표 (html 형식) */
  tables?: Maybe<Scalars['JSON']['output']>
  /** ES indexing 용도로 편의를 위해 만들어 놓은 필드 */
  title?: Maybe<Scalars['String']['output']>
  /**
   * 종류 (판결 or 결정)
   * @deprecated sentenceType 으로 변경되었음.
   */
  type?: Maybe<Scalars['String']['output']>
  /** 수정 시각 */
  updatedAt?: Maybe<Scalars['DateTime']['output']>
  /** upload 시각 */
  uploadedAt?: Maybe<Scalars['DateTime']['output']>
}

export enum PrecedentAggregateField {
  /** 사건유형 */
  CasetypeKeyword = 'casetype__keyword',
  /** 법원 */
  CourtTypeKeyword = 'court_type__keyword',
  /** 주문유형 */
  ResultKeyword = 'result__keyword',
}

export type PrecedentAggregateInput = {
  /** calendar_interval */
  calendarInterval?: InputMaybe<CalendarInterval>
  /** Term Aggregate 할 field 명 */
  field?: InputMaybe<PrecedentAggregateField>
  fields?: InputMaybe<Array<InputMaybe<PrecedentAggregateField>>>
  /** 최소 문서 수(default = 1) */
  minDocCount?: InputMaybe<Scalars['Int']['input']>
  /** Term Aggregate size */
  size?: InputMaybe<Scalars['Int']['input']>
  /** Aggregation Type */
  type?: InputMaybe<AggregateType>
}

export type PrecedentRelatedLawwaveFetchedDocuments = {
  __typename?: 'PrecedentRelatedLawwaveFetchedDocuments'
  docId?: Maybe<Scalars['String']['output']>
  relatedLawwavePosts?: Maybe<Array<Maybe<LawwavePost>>>
}

export type PrecedentSearchRequest = {
  aggregateInputs?: InputMaybe<Array<InputMaybe<PrecedentAggregateInput>>>
  highlightFields?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** 1 based page */
  page?: InputMaybe<Scalars['Int']['input']>
  searchInput?: InputMaybe<SearchInput>
  size?: InputMaybe<Scalars['Int']['input']>
  sortInputs?: InputMaybe<Array<InputMaybe<PrecedentSortInput>>>
}

/** 판결문 SRP 검색 결과 */
export type PrecedentSearchResult = {
  __typename?: 'PrecedentSearchResult'
  announceDate?: Maybe<Scalars['String']['output']>
  caseName?: Maybe<Scalars['String']['output']>
  caseNameList?: Maybe<Array<Maybe<Scalars['String']['output']>>>
  caseNo?: Maybe<Scalars['String']['output']>
  caseNoList?: Maybe<Array<Maybe<Scalars['String']['output']>>>
  caseType?: Maybe<Scalars['String']['output']>
  confirmedInfo?: Maybe<ConfirmedInfo>
  contentLength?: Maybe<Scalars['Int']['output']>
  court?: Maybe<Scalars['String']['output']>
  courtType?: Maybe<Scalars['String']['output']>
  docId?: Maybe<Scalars['String']['output']>
  docketContent?: Maybe<Scalars['String']['output']>
  issue?: Maybe<Scalars['String']['output']>
  judgeType?: Maybe<Scalars['String']['output']>
  /**     judgeList: */
  judi?: Maybe<Scalars['String']['output']>
  level?: Maybe<Scalars['Int']['output']>
  mainContent?: Maybe<Scalars['String']['output']>
  order?: Maybe<Scalars['String']['output']>
  origin?: Maybe<Scalars['String']['output']>
  parties?: Maybe<Scalars['String']['output']>
  quotedCount?: Maybe<Scalars['Int']['output']>
  relief?: Maybe<Scalars['String']['output']>
  reliefName?: Maybe<Scalars['String']['output']>
  result?: Maybe<Scalars['String']['output']>
  /**  statuteList */
  summary?: Maybe<Scalars['String']['output']>
  summaryContent?: Maybe<Scalars['String']['output']>
  title?: Maybe<Scalars['String']['output']>
  type?: Maybe<Scalars['String']['output']>
  updatedDate?: Maybe<Scalars['String']['output']>
  uploadedDate?: Maybe<Scalars['String']['output']>
  viewCount?: Maybe<Scalars['Int']['output']>
}

export enum PrecedentSortField {
  /** 스코어 */
  Score = '_score',
  /** 선고일 */
  AnnounceDate = 'announce_date',
  /** 인용 */
  QuotedCount = 'quoted_count',
  /** 조회수 */
  ViewCount = 'view_count',
}

export type PrecedentSortInput = {
  /** 정렬로 사용할 field 명 */
  field: PrecedentSortField
  order?: InputMaybe<SortOrder>
  unmappedType?: InputMaybe<FieldType>
}

export type Query = {
  __typename?: 'Query'
  caseLaw?: Maybe<FetchedDocument>
  precedent?: Maybe<Precedent>
  precedentCount?: Maybe<Scalars['Int']['output']>
  precedentDeIdentified?: Maybe<Precedent>
  precedentExists?: Maybe<Scalars['Boolean']['output']>
  precedentRelatedLawwaveDocuments?: Maybe<PrecedentRelatedLawwaveFetchedDocuments>
  precedentRestrictedExists?: Maybe<Scalars['Boolean']['output']>
  precedents?: Maybe<Array<Maybe<Precedent>>>
  precedentsByCourtType?: Maybe<Array<Maybe<Precedent>>>
  precedentsDeIdentified?: Maybe<Array<Maybe<Precedent>>>
  searchCaseLaws?: Maybe<SearchResult>
  searchCaseLawsV2?: Maybe<SearchResult>
  /** SRP 에서 사용할 검색 API */
  searchIeModelResults?: Maybe<IeSearchResult>
  searchPrecedents?: Maybe<SearchResult>
  searchPrecedents2?: Maybe<SearchResult>
  searchStatuteByDownload?: Maybe<SearchResultStatuteByDownload>
  searchStatuteByJo?: Maybe<SearchResultStatuteByJo>
  searchStatuteByName?: Maybe<SearchResultStatuteByName>
  searchStatuteCommentaries?: Maybe<SearchResult>
  searchStatuteCommentariesV2?: Maybe<SearchResult>
  searchStatuteCommentaryIndices?: Maybe<SearchResult>
  searchStatuteCommentaryIndicesV2?: Maybe<SearchResult>
  searchStatuteInterpretations?: Maybe<SearchResult>
  searchStatuteInterpretationsV2?: Maybe<SearchResult>
  searchStatuteNorth?: Maybe<SearchResult>
  searchStatuteTextViewer?: Maybe<SearchResultStatuteTextViewer>
  searchStatutes?: Maybe<StatuteSearchResult>
  statute?: Maybe<FetchedDocument>
  statuteHistories?: Maybe<Array<Maybe<LegislationHistory>>>
  statuteInterpretation?: Maybe<FetchedDocument>
  statuteNorth?: Maybe<FetchedDocument>
  userEvents?: Maybe<UserEventResult>
}

export type QueryCaseLawArgs = {
  docId?: InputMaybe<Scalars['String']['input']>
}

export type QueryPrecedentArgs = {
  docId?: InputMaybe<Scalars['String']['input']>
}

export type QueryPrecedentDeIdentifiedArgs = {
  docId?: InputMaybe<Scalars['String']['input']>
}

export type QueryPrecedentExistsArgs = {
  docId?: InputMaybe<Scalars['String']['input']>
}

export type QueryPrecedentRelatedLawwaveDocumentsArgs = {
  docId: Scalars['String']['input']
}

export type QueryPrecedentRestrictedExistsArgs = {
  docId?: InputMaybe<Scalars['String']['input']>
}

export type QueryPrecedentsArgs = {
  docIds?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

export type QueryPrecedentsByCourtTypeArgs = {
  courtType?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  docIds?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  limit?: InputMaybe<Scalars['Int']['input']>
}

export type QueryPrecedentsDeIdentifiedArgs = {
  docIds?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

export type QuerySearchCaseLawsArgs = {
  request?: InputMaybe<CaseLawSearchRequest>
}

export type QuerySearchCaseLawsV2Args = {
  request?: InputMaybe<CaseLawSearchRequestV2>
}

export type QuerySearchIeModelResultsArgs = {
  request?: InputMaybe<IeSearchRequest>
}

export type QuerySearchPrecedentsArgs = {
  request?: InputMaybe<PrecedentSearchRequest>
}

export type QuerySearchPrecedents2Args = {
  request?: InputMaybe<PrecedentSearchRequest>
}

export type QuerySearchStatuteByDownloadArgs = {
  request?: InputMaybe<StatuteByDownloadSearchRequest>
}

export type QuerySearchStatuteByJoArgs = {
  request?: InputMaybe<StatuteByJoSearchRequest>
}

export type QuerySearchStatuteByNameArgs = {
  request?: InputMaybe<StatuteByNameSearchRequest>
}

export type QuerySearchStatuteCommentariesArgs = {
  request?: InputMaybe<StatuteCommentarySearchRequest>
}

export type QuerySearchStatuteCommentariesV2Args = {
  request?: InputMaybe<StatuteCommentarySearchRequestV2>
}

export type QuerySearchStatuteCommentaryIndicesArgs = {
  request?: InputMaybe<StatuteCommentarySearchRequest>
}

export type QuerySearchStatuteCommentaryIndicesV2Args = {
  request?: InputMaybe<StatuteCommentarySearchRequestV2>
}

export type QuerySearchStatuteInterpretationsArgs = {
  request?: InputMaybe<StatuteInterpretationSearchRequest>
}

export type QuerySearchStatuteInterpretationsV2Args = {
  request?: InputMaybe<StatuteInterpretationSearchRequestV2>
}

export type QuerySearchStatuteNorthArgs = {
  request?: InputMaybe<StatuteNorthSearchRequest>
}

export type QuerySearchStatuteTextViewerArgs = {
  request?: InputMaybe<StatuteTextViewerSearchRequest>
}

export type QuerySearchStatutesArgs = {
  request?: InputMaybe<StatuteSearchRequest>
}

export type QueryStatuteArgs = {
  statuteName: Scalars['String']['input']
  targetDate?: InputMaybe<Scalars['String']['input']>
}

export type QueryStatuteHistoriesArgs = {
  statuteName: Scalars['String']['input']
}

export type QueryStatuteInterpretationArgs = {
  docId?: InputMaybe<Scalars['String']['input']>
}

export type QueryStatuteNorthArgs = {
  docId?: InputMaybe<Scalars['String']['input']>
}

export type QueryUserEventsArgs = {
  request?: InputMaybe<UserEventSearchRequest>
}

export type QueryElement = {
  type?: InputMaybe<QueryType>
  value?: InputMaybe<Scalars['String']['input']>
}

/** 빠른 검색 명령어 */
export enum QueryType {
  /** 완전일치: "사기" ('사기' 가 들어간 문서만) */
  Exact = 'exact',
  /** 제외: -사기 ('사기' 들어간 문서 제외) */
  Exclude = 'exclude',
}

/** numerical range between gte and lte */
export type RangeParam = {
  gt?: InputMaybe<Scalars['Float']['input']>
  gte?: InputMaybe<Scalars['Float']['input']>
  lt?: InputMaybe<Scalars['Float']['input']>
  lte?: InputMaybe<Scalars['Float']['input']>
}

export type Reference = {
  __typename?: 'Reference'
  statuteRefList?: Maybe<Array<Maybe<StatuteRef>>>
  text?: Maybe<Scalars['String']['output']>
}

export type RelatedDepartment = {
  __typename?: 'RelatedDepartment'
  contact?: Maybe<Scalars['String']['output']>
  department?: Maybe<Scalars['String']['output']>
  organization?: Maybe<Scalars['String']['output']>
}

export type SearchInput = {
  boost?: InputMaybe<Scalars['Float']['input']>
  fields?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** has_child에 사용 */
  hasChild?: InputMaybe<HasChildSearchInput>
  /** nested에 사용 */
  nested?: InputMaybe<NestedSearchInput>
  /** not에 사용 */
  searchInput?: InputMaybe<SearchInput>
  /** and, or에 사용 */
  searchInputs?: InputMaybe<Array<InputMaybe<SearchInput>>>
  type: SearchInputType
  value?: InputMaybe<Scalars['String']['input']>
}

export enum SearchInputType {
  And = 'and',
  Exists = 'exists',
  HasChild = 'has_child',
  Match = 'match',
  MatchAll = 'match_all',
  MatchPhrase = 'match_phrase',
  Nested = 'nested',
  Not = 'not',
  Or = 'or',
  Range = 'range',
  SimpleQueryString = 'simple_query_string',
  Terms = 'terms',
  Wildcard = 'wildcard',
}

export type SearchResult = {
  __typename?: 'SearchResult'
  aggregates?: Maybe<Array<Maybe<Array<Maybe<AggregatedType>>>>>
  /** totalHits */
  count?: Maybe<Scalars['Int']['output']>
  data?: Maybe<Array<Maybe<SearchResultDocument>>>
  firstAggregate?: Maybe<Array<Maybe<AggregatedType>>>
}

export type SearchResultDocument = {
  __typename?: 'SearchResultDocument'
  documentId: Scalars['String']['output']
  documentPageType?: Maybe<Scalars['String']['output']>
  isPdfViewer?: Maybe<Scalars['Boolean']['output']>
  mainTitle: Scalars['String']['output']
  snippet: Scalars['String']['output']
  textSubInfo?: Maybe<Scalars['JSON']['output']>
}

export type SearchResultStatuteByDownload = {
  __typename?: 'SearchResultStatuteByDownload'
  aggregates?: Maybe<Array<Maybe<Array<Maybe<AggregatedType>>>>>
  /** totalHits */
  count?: Maybe<Scalars['Int']['output']>
  data?: Maybe<Array<Maybe<StatuteByDownload>>>
  firstAggregate?: Maybe<Array<Maybe<AggregatedType>>>
}

export type SearchResultStatuteByJo = {
  __typename?: 'SearchResultStatuteByJo'
  aggregates?: Maybe<Array<Maybe<Array<Maybe<AggregatedType>>>>>
  /** totalHits */
  count?: Maybe<Scalars['Int']['output']>
  data?: Maybe<Array<Maybe<StatuteByJo>>>
  firstAggregate?: Maybe<Array<Maybe<AggregatedType>>>
}

/**
 *  endregion
 *  region data types
 */
export type SearchResultStatuteByName = {
  __typename?: 'SearchResultStatuteByName'
  aggregates?: Maybe<Array<Maybe<Array<Maybe<AggregatedType>>>>>
  /** totalHits */
  count?: Maybe<Scalars['Int']['output']>
  data?: Maybe<Array<Maybe<StatuteByName>>>
  firstAggregate?: Maybe<Array<Maybe<AggregatedType>>>
}

/**
 *  endregion
 *  region data types
 */
export type SearchResultStatuteTextViewer = {
  __typename?: 'SearchResultStatuteTextViewer'
  aggregates?: Maybe<Array<Maybe<Array<Maybe<AggregatedType>>>>>
  /** totalHits */
  count?: Maybe<Scalars['Int']['output']>
  data?: Maybe<Array<Maybe<StatuteTextViewer>>>
  firstAggregate?: Maybe<Array<Maybe<AggregatedType>>>
}

export type Section = {
  __typename?: 'Section'
  attorneyList?: Maybe<Array<Maybe<Attorney>>>
  caseRefList?: Maybe<Array<Maybe<CaseRef>>>
  lawFirmList?: Maybe<Array<Maybe<LawFirm>>>
  statuteRefList?: Maybe<Array<Maybe<StatuteRef>>>
  text?: Maybe<Scalars['String']['output']>
  textProperties?: Maybe<TextProperty>
  title?: Maybe<Scalars['String']['output']>
  type?: Maybe<Scalars['String']['output']>
}

export type SomeNotGuilty = {
  __typename?: 'SomeNotGuilty'
  type?: Maybe<Scalars['String']['output']>
  value?: Maybe<Scalars['Boolean']['output']>
}

export type SortInput = {
  /** 정렬로 사용할 field 명 */
  field: Scalars['String']['input']
  order?: InputMaybe<SortOrder>
  unmappedType?: InputMaybe<FieldType>
}

export enum SortOrder {
  Asc = 'Asc',
  Desc = 'Desc',
}

export type StatuteAggregateInput = {
  calendarInterval?: InputMaybe<CalendarInterval>
  field: StatuteFilterField
  minDocCount?: InputMaybe<Scalars['Int']['input']>
  size?: InputMaybe<Scalars['Int']['input']>
  type?: InputMaybe<AggregateType>
}

export type StatuteByDownload = {
  __typename?: 'StatuteByDownload'
  children?: Maybe<Array<Maybe<StatuteHistoryDownload>>>
  highlights?: Maybe<Array<Maybe<HighlightEntry>>>
  relatedDepartments?: Maybe<Array<Maybe<RelatedDepartment>>>
  responsibleAgency?: Maybe<Scalars['String']['output']>
  statuteName?: Maybe<Scalars['String']['output']>
  statuteNameNoSpace?: Maybe<Scalars['String']['output']>
  statuteNameSet?: Maybe<Array<Maybe<Scalars['String']['output']>>>
  statuteNameShort?: Maybe<Scalars['String']['output']>
  statuteType?: Maybe<Scalars['String']['output']>
}

export type StatuteByDownloadSearchRequest = {
  aggregateInputs?: InputMaybe<Array<InputMaybe<StatuteByNameAggregateInput>>>
  highlightFields?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** 1 based page */
  page?: InputMaybe<Scalars['Int']['input']>
  searchInput?: InputMaybe<SearchInput>
  size?: InputMaybe<Scalars['Int']['input']>
  sortInputs?: InputMaybe<Array<InputMaybe<StatuteByNameSortInput>>>
}

export type StatuteByJo = {
  __typename?: 'StatuteByJo'
  children?: Maybe<Array<Maybe<StatuteHistoryJo>>>
  highlights?: Maybe<Array<Maybe<HighlightEntry>>>
  relatedDepartments?: Maybe<Array<Maybe<RelatedDepartment>>>
  responsibleAgency?: Maybe<Scalars['String']['output']>
  statuteName?: Maybe<Scalars['String']['output']>
  statuteNameNoSpace?: Maybe<Scalars['String']['output']>
  statuteNameSet?: Maybe<Array<Maybe<Scalars['String']['output']>>>
  statuteNameShort?: Maybe<Scalars['String']['output']>
  statuteType?: Maybe<Scalars['String']['output']>
}

export type StatuteByJoSearchRequest = {
  aggregateInputs?: InputMaybe<Array<InputMaybe<StatuteByNameAggregateInput>>>
  highlightFields?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** 1 based page */
  page?: InputMaybe<Scalars['Int']['input']>
  searchInput?: InputMaybe<SearchInput>
  size?: InputMaybe<Scalars['Int']['input']>
  sortInputs?: InputMaybe<Array<InputMaybe<StatuteByNameSortInput>>>
}

export type StatuteByName = {
  __typename?: 'StatuteByName'
  children?: Maybe<Array<Maybe<StatuteHistory>>>
  highlights?: Maybe<Array<Maybe<HighlightEntry>>>
  relatedDepartments?: Maybe<Array<Maybe<RelatedDepartment>>>
  responsibleAgency?: Maybe<Scalars['String']['output']>
  statuteName?: Maybe<Scalars['String']['output']>
  statuteNameNoSpace?: Maybe<Scalars['String']['output']>
  statuteNameSet?: Maybe<Array<Maybe<Scalars['String']['output']>>>
  statuteNameShort?: Maybe<Scalars['String']['output']>
  statuteType?: Maybe<Scalars['String']['output']>
}

export enum StatuteByNameAggregateField {
  /** 법령종류 */
  StatuteTypeKeyword = 'statute_type__keyword',
}

export type StatuteByNameAggregateInput = {
  /** calendar_interval */
  calendarInterval?: InputMaybe<CalendarInterval>
  /** Term Aggregate 할 field 명 */
  field?: InputMaybe<StatuteByNameAggregateField>
  fields?: InputMaybe<Array<InputMaybe<StatuteByNameAggregateField>>>
  /** 최소 문서 수(default = 1) */
  minDocCount?: InputMaybe<Scalars['Int']['input']>
  /** Term Aggregate size */
  size?: InputMaybe<Scalars['Int']['input']>
  /** Aggregation Type */
  type?: InputMaybe<AggregateType>
}

/**  region requests */
export type StatuteByNameSearchRequest = {
  aggregateInputs?: InputMaybe<Array<InputMaybe<StatuteByNameAggregateInput>>>
  highlightFields?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** 1 based page */
  page?: InputMaybe<Scalars['Int']['input']>
  searchInput?: InputMaybe<SearchInput>
  size?: InputMaybe<Scalars['Int']['input']>
  sortInputs?: InputMaybe<Array<InputMaybe<StatuteByNameSortInput>>>
}

export enum StatuteByNameSortField {
  /** 스코어 */
  Score = '_score',
}

export type StatuteByNameSortInput = {
  /** 정렬로 사용할 field 명 */
  field: StatuteByNameSortField
  order?: InputMaybe<SortOrder>
  unmappedType?: InputMaybe<FieldType>
}

export enum StatuteCommentaryAggregateField {
  BookNameKeyword = 'book_name__keyword',
  CategoryOrder = 'category_order',
  CategoryPathKeyword = 'category_path__keyword',
  DataLinkKeyword = 'data_link__keyword',
  DocTypeKeyword = 'doc_type__keyword',
  EditionKeyword = 'edition__keyword',
  EndPageIdx = 'end_page_idx',
  IndexOrder = 'index_order',
  IsLboxOnly = 'is_lbox_only',
  LboxPathKeyword = 'lbox_path__keyword',
  MainAuthorKeyword = 'main_author__keyword',
  PdfPathKeyword = 'pdf_path__keyword',
  PublisherKeyword = 'publisher__keyword',
  ReleaseDateKeyword = 'release_date__keyword',
  StartPageIdx = 'start_page_idx',
  VolumeNameKeyword = 'volume_name__keyword',
  VolumeRangeKeyword = 'volume_range__keyword',
}

export type StatuteCommentaryAggregateInput = {
  calendarInterval?: InputMaybe<CalendarInterval>
  field?: InputMaybe<StatuteCommentaryAggregateField>
  fields?: InputMaybe<Array<InputMaybe<StatuteCommentaryAggregateField>>>
  minDocCount?: InputMaybe<Scalars['Int']['input']>
  nested?: InputMaybe<NestedStatuteCommentaryAggregateInput>
  size?: InputMaybe<Scalars['Int']['input']>
  type?: InputMaybe<AggregateType>
}

export enum StatuteCommentaryFilterField {
  BookNameKeyword = 'book_name__keyword',
  CategoryPathKeyword = 'category_path__keyword',
  DocTypeKeyword = 'doc_type__keyword',
  LboxPathKeyword = 'lbox_path__keyword',
  MainAuthorKeyword = 'main_author__keyword',
  PdfPathKeyword = 'pdf_path__keyword',
  PublisherKeyword = 'publisher__keyword',
  UseForAi = 'use_for_ai',
  VolumeNameKeyword = 'volume_name__keyword',
}

export type StatuteCommentaryFilterInput = {
  field?: InputMaybe<StatuteCommentaryFilterField>
  type?: InputMaybe<FilterInputType>
  values: Array<InputMaybe<Scalars['String']['input']>>
}

export type StatuteCommentarySearchRequest = {
  aggregateInputs?: InputMaybe<Array<InputMaybe<StatuteCommentaryAggregateInput>>>
  filterInputs?: InputMaybe<Array<InputMaybe<StatuteCommentaryFilterInput>>>
  page?: InputMaybe<Scalars['Int']['input']>
  query: Scalars['String']['input']
  queryElements?: InputMaybe<Array<InputMaybe<QueryElement>>>
  size?: InputMaybe<Scalars['Int']['input']>
  sortInputs?: InputMaybe<Array<InputMaybe<StatuteCommentarySortInput>>>
}

export type StatuteCommentarySearchRequestV2 = {
  aggregateInputs?: InputMaybe<Array<InputMaybe<StatuteCommentaryAggregateInput>>>
  filterInputs?: InputMaybe<Array<InputMaybe<StatuteCommentaryFilterInput>>>
  highlightFields?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  page?: InputMaybe<Scalars['Int']['input']>
  query: Scalars['String']['input']
  queryElements?: InputMaybe<Array<InputMaybe<QueryElement>>>
  searchInput?: InputMaybe<SearchInput>
  size?: InputMaybe<Scalars['Int']['input']>
  sortInputs?: InputMaybe<Array<InputMaybe<StatuteCommentarySortInput>>>
}

export enum StatuteCommentarySortField {
  Score = '_score',
  IndexOrder = 'index_order',
  LboxPathKeyword = 'lbox_path__keyword',
  ReleaseDate = 'release_date',
}

export type StatuteCommentarySortInput = {
  field: StatuteCommentarySortField
  order?: InputMaybe<SortOrder>
  unmappedType?: InputMaybe<FieldType>
}

export enum StatuteFilterField {
  Bubjong = 'bubjong',
  EffectiveDate = 'effective_date',
  IsCurrent = 'is_current',
}

export type StatuteFilterInput = {
  field: StatuteFilterField
  type?: InputMaybe<FilterInputType>
  values: Array<InputMaybe<Scalars['String']['input']>>
}

export type StatuteHistory = {
  __typename?: 'StatuteHistory'
  docId?: Maybe<Scalars['String']['output']>
  effectiveDate?: Maybe<Scalars['Date']['output']>
  effectiveEndDate?: Maybe<Scalars['Date']['output']>
  highlights?: Maybe<Array<Maybe<HighlightEntry>>>
  proclamationDate?: Maybe<Scalars['Date']['output']>
  proclamationNumber?: Maybe<Scalars['String']['output']>
  revisionType?: Maybe<Scalars['String']['output']>
  statuteName?: Maybe<Scalars['String']['output']>
  statuteNameNoSpace?: Maybe<Scalars['String']['output']>
  statuteNameShort?: Maybe<Scalars['String']['output']>
  statuteType?: Maybe<Scalars['String']['output']>
}

export type StatuteHistoryDownload = {
  __typename?: 'StatuteHistoryDownload'
  content?: Maybe<Scalars['String']['output']>
  docId?: Maybe<Scalars['String']['output']>
  effectiveDate?: Maybe<Scalars['Date']['output']>
  effectiveEndDate?: Maybe<Scalars['Date']['output']>
  highlights?: Maybe<Array<Maybe<HighlightEntry>>>
  hwpLink?: Maybe<Scalars['String']['output']>
  indexPath?: Maybe<Scalars['String']['output']>
  itemId?: Maybe<Scalars['String']['output']>
  parsedIdx?: Maybe<Scalars['Int']['output']>
  pdfLink?: Maybe<Scalars['String']['output']>
  proclamationDate?: Maybe<Scalars['Date']['output']>
  proclamationNumber?: Maybe<Scalars['String']['output']>
  revisionType?: Maybe<Scalars['String']['output']>
  statuteType?: Maybe<Scalars['String']['output']>
  title?: Maybe<Scalars['String']['output']>
}

export type StatuteHistoryJo = {
  __typename?: 'StatuteHistoryJo'
  content?: Maybe<Scalars['String']['output']>
  docId?: Maybe<Scalars['String']['output']>
  effectiveDate?: Maybe<Scalars['Date']['output']>
  effectiveEndDate?: Maybe<Scalars['Date']['output']>
  highlights?: Maybe<Array<Maybe<HighlightEntry>>>
  indexPath?: Maybe<Scalars['String']['output']>
  itemId?: Maybe<Scalars['String']['output']>
  key_1?: Maybe<Scalars['String']['output']>
  key_2?: Maybe<Scalars['String']['output']>
  parsedIdx?: Maybe<Scalars['Int']['output']>
  proclamationDate?: Maybe<Scalars['Date']['output']>
  proclamationNumber?: Maybe<Scalars['String']['output']>
  revisionType?: Maybe<Scalars['String']['output']>
  statuteType?: Maybe<Scalars['String']['output']>
  title?: Maybe<Scalars['String']['output']>
}

export enum StatuteInterpretationAggregateField {
  /** 문서유형 */
  DocumentTypeKeyword = 'document_type__keyword',
  /** 소관기관/부서 */
  RelevantAuthorityKeyword = 'relevant_authority__keyword',
  /** 회시기관 */
  RespondingAgencyKeyword = 'responding_agency__keyword',
  /** 회시일자 */
  ResponseDate = 'response_date',
}

export type StatuteInterpretationAggregateInput = {
  /** calendar_interval */
  calendarInterval?: InputMaybe<CalendarInterval>
  /** Term Aggregate 할 field 명 */
  field?: InputMaybe<StatuteInterpretationAggregateField>
  fields?: InputMaybe<Array<InputMaybe<StatuteInterpretationAggregateField>>>
  /** 최소 문서 수(default = 1) */
  minDocCount?: InputMaybe<Scalars['Int']['input']>
  /** Term Aggregate size */
  size?: InputMaybe<Scalars['Int']['input']>
  /** Aggregation Type */
  type?: InputMaybe<AggregateType>
}

export enum StatuteInterpretationFilterField {
  /** 문서유형 */
  DocumentType = 'document_type',
  /** 소관기관/부서 */
  RelevantAuthorityKeyword = 'relevant_authority__keyword',
  /** 회시기관 */
  RespondingAgencyKeyword = 'responding_agency__keyword',
  /** 회시일자 */
  ResponseDate = 'response_date',
}

export type StatuteInterpretationFilterInput = {
  /** 기간 필터: ex) now-1y/d ~ now/d */
  dateRange?: InputMaybe<DateRangeParam>
  /** 필터로 사용할 field 명 */
  field: StatuteInterpretationFilterField
  /** 필터 type */
  type?: InputMaybe<FilterInputType>
  /** 필터로 사용할 값들(string) */
  values?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

export type StatuteInterpretationSearchRequest = {
  aggregateInputs?: InputMaybe<Array<InputMaybe<StatuteInterpretationAggregateInput>>>
  /** 문서 page 타입 */
  documentPageType: DocumentPageType
  filterInputs?: InputMaybe<Array<InputMaybe<StatuteInterpretationFilterInput>>>
  /** 1 based page */
  page?: InputMaybe<Scalars['Int']['input']>
  /** 검색어 */
  query: Scalars['String']['input']
  queryElements?: InputMaybe<Array<InputMaybe<QueryElement>>>
  size?: InputMaybe<Scalars['Int']['input']>
  sortInputs?: InputMaybe<Array<InputMaybe<StatuteInterpretationSortInput>>>
}

export type StatuteInterpretationSearchRequestV2 = {
  aggregateInputs?: InputMaybe<Array<InputMaybe<StatuteInterpretationAggregateInput>>>
  /** 문서 page 타입 */
  documentPageType: DocumentPageType
  filterInputs?: InputMaybe<Array<InputMaybe<StatuteInterpretationFilterInput>>>
  highlightFields?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** 1 based page */
  page?: InputMaybe<Scalars['Int']['input']>
  /** 검색어 */
  query: Scalars['String']['input']
  queryElements?: InputMaybe<Array<InputMaybe<QueryElement>>>
  searchInput?: InputMaybe<SearchInput>
  size?: InputMaybe<Scalars['Int']['input']>
  sortInputs?: InputMaybe<Array<InputMaybe<StatuteInterpretationSortInput>>>
}

export enum StatuteInterpretationSortField {
  /** 스코어 */
  Score = '_score',
  /** 회시일자 */
  ResponseDate = 'response_date',
}

export type StatuteInterpretationSortInput = {
  /** 정렬로 사용할 field 명 */
  field: StatuteInterpretationSortField
  order?: InputMaybe<SortOrder>
  unmappedType?: InputMaybe<FieldType>
}

export enum StatuteNorthAggregateField {
  /** 구분 */
  CategoryKeyword = 'category__keyword',
}

export type StatuteNorthAggregateInput = {
  /** calendar_interval */
  calendarInterval?: InputMaybe<CalendarInterval>
  /** Term Aggregate 할 field 명 */
  field?: InputMaybe<StatuteNorthAggregateField>
  fields?: InputMaybe<Array<InputMaybe<StatuteNorthAggregateField>>>
  /** 최소 문서 수(default = 1) */
  minDocCount?: InputMaybe<Scalars['Int']['input']>
  /** Term Aggregate size */
  size?: InputMaybe<Scalars['Int']['input']>
  /** Aggregation Type */
  type?: InputMaybe<AggregateType>
}

export type StatuteNorthSearchRequest = {
  aggregateInputs?: InputMaybe<Array<InputMaybe<StatuteNorthAggregateInput>>>
  highlightFields?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** 1 based page */
  page?: InputMaybe<Scalars['Int']['input']>
  searchInput?: InputMaybe<SearchInput>
  size?: InputMaybe<Scalars['Int']['input']>
  sortInputs?: InputMaybe<Array<InputMaybe<StatuteNorthSortInput>>>
}

export enum StatuteNorthSortField {
  /** 스코어 */
  Score = '_score',
}

export type StatuteNorthSortInput = {
  /** 정렬로 사용할 field 명 */
  field: StatuteNorthSortField
  order?: InputMaybe<SortOrder>
  unmappedType?: InputMaybe<FieldType>
}

export type StatuteRef = {
  __typename?: 'StatuteRef'
  span?: Maybe<Array<Maybe<Scalars['Int']['output']>>>
  text?: Maybe<Scalars['String']['output']>
  type?: Maybe<Scalars['String']['output']>
  value?: Maybe<StatuteRefValue>
}

export type StatuteRefValue = {
  __typename?: 'StatuteRefValue'
  hang?: Maybe<Scalars['String']['output']>
  ho?: Maybe<Scalars['String']['output']>
  jo?: Maybe<Scalars['String']['output']>
  statuteAbbrv?: Maybe<Scalars['String']['output']>
  statuteId?: Maybe<Scalars['String']['output']>
  statuteName?: Maybe<Scalars['String']['output']>
}

export type StatuteResponse = {
  __typename?: 'StatuteResponse'
  bubjong?: Maybe<Scalars['String']['output']>
  effectiveDate?: Maybe<Scalars['String']['output']>
  fullName?: Maybe<Scalars['String']['output']>
  isCurrent?: Maybe<Scalars['Boolean']['output']>
  jomuns?: Maybe<Array<Maybe<JomunSummary>>>
  name?: Maybe<Scalars['String']['output']>
  proclamationDate?: Maybe<Scalars['String']['output']>
  proclamationNum?: Maybe<Scalars['String']['output']>
  revisedType?: Maybe<Scalars['String']['output']>
  state?: Maybe<StatuteState>
  statuteEsId: Scalars['String']['output']
}

export enum StatuteSearchField {
  JomunContent = 'jomun_content',
  JomunTitle = 'jomun_title',
  StatuteName = 'statute_name',
}

export type StatuteSearchFieldInput = {
  boost?: InputMaybe<Scalars['Float']['input']>
  field: StatuteSearchField
}

export type StatuteSearchRequest = {
  aggregateInputs?: InputMaybe<Array<InputMaybe<StatuteAggregateInput>>>
  /**     searchFieldInputs: [StatuteSearchFieldInput] */
  filterInputs?: InputMaybe<Array<InputMaybe<StatuteFilterInput>>>
  page?: InputMaybe<Scalars['Int']['input']>
  /** 검색어 */
  query: Scalars['String']['input']
  queryElements?: InputMaybe<Array<InputMaybe<QueryElement>>>
  size?: InputMaybe<Scalars['Int']['input']>
  sortInputs?: InputMaybe<Array<InputMaybe<StatuteSortInput>>>
  /** 문서 page 타입 */
  statuteSearchType: StatuteSearchType
}

export type StatuteSearchResult = {
  __typename?: 'StatuteSearchResult'
  aggregates?: Maybe<Array<Maybe<Array<Maybe<AggregatedType>>>>>
  count?: Maybe<Scalars['Int']['output']>
  data?: Maybe<Array<Maybe<StatuteResponse>>>
  firstAggregate?: Maybe<Array<Maybe<AggregatedType>>>
}

/**
 * 문서 Page 타입
 * -
 */
export enum StatuteSearchType {
  Jomun = 'jomun',
  Name = 'name',
}

export enum StatuteSortField {
  Score = '_score',
}

export type StatuteSortInput = {
  field: StatuteSortField
  order?: InputMaybe<SortOrder>
  unmappedType?: InputMaybe<FieldType>
}

export enum StatuteState {
  Close = 'CLOSE',
  Current = 'CURRENT',
  Expect = 'EXPECT',
  History = 'HISTORY',
}

export type StatuteTextViewer = {
  __typename?: 'StatuteTextViewer'
  docGroupId?: Maybe<Scalars['String']['output']>
  docId?: Maybe<Scalars['String']['output']>
  effectiveDate?: Maybe<Scalars['String']['output']>
  jsonUrl?: Maybe<Scalars['String']['output']>
  proclamationDate?: Maybe<Scalars['String']['output']>
  proclamationNumber?: Maybe<Scalars['String']['output']>
  relatedDepartments?: Maybe<Array<Maybe<RelatedDepartment>>>
  responsibleAgency?: Maybe<Scalars['String']['output']>
  revisionType?: Maybe<Scalars['String']['output']>
  statuteName?: Maybe<Scalars['String']['output']>
  statuteNameNoSpace?: Maybe<Scalars['String']['output']>
  statuteNameShort?: Maybe<Scalars['String']['output']>
  statuteType?: Maybe<Scalars['String']['output']>
  tempHistoryStatus?: Maybe<Scalars['String']['output']>
}

/**  region requests */
export type StatuteTextViewerSearchRequest = {
  highlightFields?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** 1 based page */
  page?: InputMaybe<Scalars['Int']['input']>
  searchInput?: InputMaybe<SearchInput>
  size?: InputMaybe<Scalars['Int']['input']>
  sortInputs?: InputMaybe<Array<InputMaybe<StatuteTextViewerSortInput>>>
}

export enum StatuteTextViewerSortField {
  /** 스코어 */
  Score = '_score',
  /** 시행일 */
  EffectiveDate = 'effective_date',
  /** 공포일 */
  ProclamationDate = 'proclamation_date',
  /** 공포번호 */
  ProclamationNumberKeyword = 'proclamation_number__keyword',
  /** 소관부처 */
  ResponsibleAgencyKeyword = 'responsible_agency__keyword',
  /** 제개정구분 */
  RevisionTypeKeyword = 'revision_type__keyword',
  /** 법령명 */
  StatuteNameKeyword = 'statute_name__keyword',
  /** 약칭 */
  StatuteNameShortKeyword = 'statute_name_short__keyword',
  /** 법령종류 */
  StatuteTypeKeyword = 'statute_type__keyword',
}

export type StatuteTextViewerSortInput = {
  /** 정렬로 사용할 field 명 */
  field: StatuteTextViewerSortField
  order?: InputMaybe<SortOrder>
  unmappedType?: InputMaybe<FieldType>
}

export type TextProperty = {
  __typename?: 'TextProperty'
  bold?: Maybe<Array<Maybe<Array<Maybe<Scalars['Int']['output']>>>>>
  dot?: Maybe<Array<Maybe<Array<Maybe<Scalars['Int']['output']>>>>>
  heading?: Maybe<Array<Maybe<Heading>>>
  strikeout?: Maybe<Array<Maybe<Array<Maybe<Scalars['Int']['output']>>>>>
  subscript?: Maybe<Array<Maybe<Array<Maybe<Scalars['Int']['output']>>>>>
  underline?: Maybe<Array<Maybe<Array<Maybe<Scalars['Int']['output']>>>>>
}

export type UserEvent = {
  __typename?: 'UserEvent'
  clientId?: Maybe<Scalars['String']['output']>
  event?: Maybe<Scalars['JSON']['output']>
  eventId?: Maybe<Scalars['String']['output']>
  eventTs: Scalars['DateTime']['output']
  eventType: Scalars['String']['output']
  pageId?: Maybe<Scalars['String']['output']>
  prevEventId?: Maybe<Scalars['String']['output']>
  prevPageId?: Maybe<Scalars['String']['output']>
  url?: Maybe<Scalars['String']['output']>
  userId: Scalars['Int']['output']
  userIp?: Maybe<Scalars['String']['output']>
}

export enum UserEventFilterField {
  EventType = 'eventType',
}

export type UserEventFilterInput = {
  field?: InputMaybe<UserEventFilterField>
  values: Array<InputMaybe<Scalars['String']['input']>>
}

export type UserEventResult = {
  __typename?: 'UserEventResult'
  count?: Maybe<Scalars['Int']['output']>
  endTs?: Maybe<Scalars['DateTime']['output']>
  events?: Maybe<Array<Maybe<UserEvent>>>
  startTs?: Maybe<Scalars['DateTime']['output']>
}

export type UserEventSearchRequest = {
  endTs?: InputMaybe<Scalars['DateTime']['input']>
  env?: InputMaybe<Environment>
  filterInputs?: InputMaybe<Array<InputMaybe<UserEventFilterInput>>>
  limit?: InputMaybe<Scalars['Int']['input']>
  startTs?: InputMaybe<Scalars['DateTime']['input']>
  userId: Scalars['Int']['input']
}

export type SearchStatuteCommetariesQueryVariables = Exact<{
  filterInputs?: InputMaybe<Array<InputMaybe<StatuteCommentaryFilterInput>> | InputMaybe<StatuteCommentaryFilterInput>>
  aggregateInputs?: InputMaybe<
    Array<InputMaybe<StatuteCommentaryAggregateInput>> | InputMaybe<StatuteCommentaryAggregateInput>
  >
}>

export type SearchStatuteCommetariesQuery = {
  __typename?: 'Query'
  searchStatuteCommentaries?: {
    __typename?: 'SearchResult'
    aggregates?: Array<Array<{
      __typename?: 'AggregatedType'
      docCount?: number | null
      key?: string | null
    } | null> | null> | null
  } | null
}

export type GetInterpretationsRespondingAgencyAggregatesQueryVariables = Exact<{
  documentPageType: DocumentPageType
  query: Scalars['String']['input']
}>

export type GetInterpretationsRespondingAgencyAggregatesQuery = {
  __typename?: 'Query'
  searchStatuteInterpretations?: {
    __typename?: 'SearchResult'
    aggregates?: Array<Array<{
      __typename?: 'AggregatedType'
      docCount?: number | null
      key?: string | null
    } | null> | null> | null
  } | null
}

export type GetTrialdecisionAggregatesQueryVariables = Exact<{
  page?: InputMaybe<Scalars['Int']['input']>
  query: Scalars['String']['input']
  aggregateInputs?: InputMaybe<Array<InputMaybe<CaseLawAggregateInput>> | InputMaybe<CaseLawAggregateInput>>
  sortInputs?: InputMaybe<Array<InputMaybe<CaseLawSortInput>> | InputMaybe<CaseLawSortInput>>
  filterInputs?: InputMaybe<Array<InputMaybe<CaseLawFilterInput>> | InputMaybe<CaseLawFilterInput>>
}>

export type GetTrialdecisionAggregatesQuery = {
  __typename?: 'Query'
  searchCaseLaws?: {
    __typename?: 'SearchResult'
    count?: number | null
    aggregates?: Array<Array<{
      __typename?: 'AggregatedType'
      docCount?: number | null
      key?: string | null
    } | null> | null> | null
  } | null
}

export type GetPrecedentRelatedLawwaveDocumentsQueryVariables = Exact<{
  docId: Scalars['String']['input']
}>

export type GetPrecedentRelatedLawwaveDocumentsQuery = {
  __typename?: 'Query'
  precedentRelatedLawwaveDocuments?: {
    __typename?: 'PrecedentRelatedLawwaveFetchedDocuments'
    relatedLawwavePosts?: Array<{
      __typename?: 'LawwavePost'
      docId?: string | null
      lawwavePostId?: string | null
      lboxLink?: string | null
      lawwaveLink?: string | null
      title?: string | null
      subtitle?: string | null
      author?: string | null
      tags?: Array<string | null> | null
    } | null> | null
  } | null
}

export type SearchIslaModelResultsWithVariablesQueryQueryVariables = Exact<{
  query: Scalars['String']['input']
  category: Scalars['String']['input']
  page?: InputMaybe<Scalars['Int']['input']>
  size?: InputMaybe<Scalars['Int']['input']>
  sortInputs?: InputMaybe<Array<InputMaybe<IeModelSearchSortInput>> | InputMaybe<IeModelSearchSortInput>>
  filterInputs?: InputMaybe<Array<InputMaybe<IeModelSearchFilterInput>> | InputMaybe<IeModelSearchFilterInput>>
}>

export type SearchIslaModelResultsWithVariablesQueryQuery = {
  __typename?: 'Query'
  searchIeModelResults?: {
    __typename?: 'IeSearchResult'
    count?: number | null
    data?: Array<{
      __typename?: 'IeSearchResponse'
      docId: string
      snippet?: string | null
      precedent?: {
        __typename?: 'PrecedentSearchResult'
        docId?: string | null
        title?: string | null
        viewCount?: number | null
        quotedCount?: number | null
        result?: string | null
        order?: string | null
        relief?: string | null
        reliefName?: string | null
        confirmedInfo?: { __typename?: 'ConfirmedInfo'; confirmed?: boolean | null } | null
      } | null
    } | null> | null
    aggregates?: Array<Array<{
      __typename?: 'AggregatedType'
      key?: string | null
      docCount?: number | null
    } | null> | null> | null
  } | null
}

export type SearchPrecedentsQueryVariables = Exact<{
  searchInput?: InputMaybe<SearchInput>
  highlightFields?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>> | InputMaybe<Scalars['String']['input']>>
  aggregateInputs?: InputMaybe<Array<InputMaybe<PrecedentAggregateInput>> | InputMaybe<PrecedentAggregateInput>>
  sortInputs?: InputMaybe<Array<InputMaybe<PrecedentSortInput>> | InputMaybe<PrecedentSortInput>>
  page?: InputMaybe<Scalars['Int']['input']>
  size?: InputMaybe<Scalars['Int']['input']>
}>

export type SearchPrecedentsQuery = {
  __typename?: 'Query'
  searchPrecedents?: {
    __typename?: 'SearchResult'
    count?: number | null
    data?: Array<{
      __typename?: 'SearchResultDocument'
      mainTitle: string
      snippet: string
      textSubInfo?: any | null
    } | null> | null
  } | null
}

export type SearchStatuteCommentariesQueryVariables = Exact<{
  size: Scalars['Int']['input']
  page: Scalars['Int']['input']
  query: Scalars['String']['input']
  filterInputs?: InputMaybe<Array<InputMaybe<StatuteCommentaryFilterInput>> | InputMaybe<StatuteCommentaryFilterInput>>
  aggregateInputs?: InputMaybe<
    Array<InputMaybe<StatuteCommentaryAggregateInput>> | InputMaybe<StatuteCommentaryAggregateInput>
  >
  sortInputs?: InputMaybe<Array<InputMaybe<StatuteCommentarySortInput>> | InputMaybe<StatuteCommentarySortInput>>
}>

export type SearchStatuteCommentariesQuery = {
  __typename?: 'Query'
  searchStatuteCommentaries?: {
    __typename?: 'SearchResult'
    count?: number | null
    data?: Array<{
      __typename?: 'SearchResultDocument'
      documentId: string
      mainTitle: string
      snippet: string
      documentPageType?: string | null
      textSubInfo?: any | null
    } | null> | null
  } | null
}

export type GetPdfViewerBookListQueryVariables = Exact<{
  filterInputs?: InputMaybe<Array<InputMaybe<StatuteCommentaryFilterInput>> | InputMaybe<StatuteCommentaryFilterInput>>
  aggregateInputs?: InputMaybe<
    Array<InputMaybe<StatuteCommentaryAggregateInput>> | InputMaybe<StatuteCommentaryAggregateInput>
  >
}>

export type GetPdfViewerBookListQuery = {
  __typename?: 'Query'
  searchStatuteCommentaries?: {
    __typename?: 'SearchResult'
    count?: number | null
    aggregates?: Array<Array<{
      __typename?: 'AggregatedType'
      docCount?: number | null
      key?: string | null
    } | null> | null> | null
  } | null
}

export type GetCommentaryStatuteFilterQueryVariables = Exact<{
  aggregateInputs?: InputMaybe<
    Array<InputMaybe<StatuteCommentaryAggregateInput>> | InputMaybe<StatuteCommentaryAggregateInput>
  >
}>

export type GetCommentaryStatuteFilterQuery = {
  __typename?: 'Query'
  searchStatuteCommentaryIndices?: {
    __typename?: 'SearchResult'
    aggregates?: Array<Array<{
      __typename?: 'AggregatedType'
      docCount?: number | null
      key?: string | null
    } | null> | null> | null
  } | null
}

export type GetSearchStatuteCommentaryIndicesQueryVariables = Exact<{
  size?: InputMaybe<Scalars['Int']['input']>
  filterInputs?: InputMaybe<Array<InputMaybe<StatuteCommentaryFilterInput>> | InputMaybe<StatuteCommentaryFilterInput>>
  sortInputs?: InputMaybe<Array<InputMaybe<StatuteCommentarySortInput>> | InputMaybe<StatuteCommentarySortInput>>
}>

export type GetSearchStatuteCommentaryIndicesQuery = {
  __typename?: 'Query'
  searchStatuteCommentaryIndices?: {
    __typename?: 'SearchResult'
    data?: Array<{ __typename?: 'SearchResultDocument'; textSubInfo?: any | null } | null> | null
  } | null
}

export type GetInterpretationsSearchResultQueryVariables = Exact<{
  page?: InputMaybe<Scalars['Int']['input']>
  documentPageType: DocumentPageType
  query: Scalars['String']['input']
  filterInputs?: InputMaybe<
    Array<InputMaybe<StatuteInterpretationFilterInput>> | InputMaybe<StatuteInterpretationFilterInput>
  >
  sortInputs?: InputMaybe<
    Array<InputMaybe<StatuteInterpretationSortInput>> | InputMaybe<StatuteInterpretationSortInput>
  >
}>

export type GetInterpretationsSearchResultQuery = {
  __typename?: 'Query'
  searchStatuteInterpretations?: {
    __typename?: 'SearchResult'
    count?: number | null
    data?: Array<{
      __typename?: 'SearchResultDocument'
      documentId: string
      documentPageType?: string | null
      mainTitle: string
      snippet: string
      textSubInfo?: any | null
      isPdfViewer?: boolean | null
    } | null> | null
  } | null
}

export type SearchStatuteNorthQueryVariables = Exact<{
  page?: InputMaybe<Scalars['Int']['input']>
  size?: InputMaybe<Scalars['Int']['input']>
  highlightFields?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>> | InputMaybe<Scalars['String']['input']>>
  searchInput?: InputMaybe<SearchInput>
}>

export type SearchStatuteNorthQuery = {
  __typename?: 'Query'
  searchStatuteNorth?: {
    __typename?: 'SearchResult'
    count?: number | null
    data?: Array<{
      __typename?: 'SearchResultDocument'
      documentId: string
      mainTitle: string
      isPdfViewer?: boolean | null
    } | null> | null
  } | null
}

export type GetSearchCaseLawsQueryVariables = Exact<{
  page?: InputMaybe<Scalars['Int']['input']>
  query: Scalars['String']['input']
  aggregateInputs?: InputMaybe<Array<InputMaybe<CaseLawAggregateInput>> | InputMaybe<CaseLawAggregateInput>>
  sortInputs?: InputMaybe<Array<InputMaybe<CaseLawSortInput>> | InputMaybe<CaseLawSortInput>>
  filterInputs?: InputMaybe<Array<InputMaybe<CaseLawFilterInput>> | InputMaybe<CaseLawFilterInput>>
}>

export type GetSearchCaseLawsQuery = {
  __typename?: 'Query'
  searchCaseLaws?: {
    __typename?: 'SearchResult'
    count?: number | null
    data?: Array<{
      __typename?: 'SearchResultDocument'
      documentId: string
      mainTitle: string
      snippet: string
      documentPageType?: string | null
      textSubInfo?: any | null
      isPdfViewer?: boolean | null
    } | null> | null
    aggregates?: Array<Array<{
      __typename?: 'AggregatedType'
      docCount?: number | null
      key?: string | null
    } | null> | null> | null
  } | null
}

export type StatuteNorthQueryVariables = Exact<{
  docId: Scalars['String']['input']
}>

export type StatuteNorthQuery = {
  __typename?: 'Query'
  statuteNorth?: {
    __typename?: 'FetchedDocument'
    docId: string
    documentId: string
    textViewer?: any | null
    pdfViewer?: any | null
  } | null
}

export type CaseLawQueryVariables = Exact<{
  docId: Scalars['String']['input']
}>

export type CaseLawQuery = {
  __typename?: 'Query'
  caseLaw?: {
    __typename?: 'FetchedDocument'
    documentId: string
    textViewer?: any | null
    pdfViewer?: any | null
  } | null
}

export type StatuteInterpretationQueryVariables = Exact<{
  docId: Scalars['String']['input']
}>

export type StatuteInterpretationQuery = {
  __typename?: 'Query'
  statuteInterpretation?: { __typename?: 'FetchedDocument'; textViewer?: any | null; pdfViewer?: any | null } | null
}

export type SearchStatuteTextViewerQueryVariables = Exact<{
  docId?: InputMaybe<Scalars['String']['input']>
}>

export type SearchStatuteTextViewerQuery = {
  __typename?: 'Query'
  searchStatuteTextViewer?: {
    __typename?: 'SearchResultStatuteTextViewer'
    count?: number | null
    data?: Array<{
      __typename?: 'StatuteTextViewer'
      docGroupId?: string | null
      docId?: string | null
      statuteName?: string | null
      statuteNameShort?: string | null
      statuteNameNoSpace?: string | null
      proclamationDate?: string | null
      effectiveDate?: string | null
      responsibleAgency?: string | null
      revisionType?: string | null
      statuteType?: string | null
      proclamationNumber?: string | null
      tempHistoryStatus?: string | null
      jsonUrl?: string | null
      relatedDepartments?: Array<{
        __typename?: 'RelatedDepartment'
        organization?: string | null
        department?: string | null
        contact?: string | null
      } | null> | null
    } | null> | null
  } | null
}

export type SearchStatuteTextViewerHistoryQueryVariables = Exact<{
  docGroupId?: InputMaybe<Scalars['String']['input']>
}>

export type SearchStatuteTextViewerHistoryQuery = {
  __typename?: 'Query'
  searchStatuteByName?: {
    __typename?: 'SearchResultStatuteByName'
    count?: number | null
    data?: Array<{
      __typename?: 'StatuteByName'
      statuteName?: string | null
      statuteNameShort?: string | null
      statuteNameNoSpace?: string | null
      statuteNameSet?: Array<string | null> | null
      children?: Array<{
        __typename?: 'StatuteHistory'
        docId?: string | null
        statuteName?: string | null
        statuteType?: string | null
        statuteNameShort?: string | null
        statuteNameNoSpace?: string | null
        revisionType?: string | null
        proclamationDate?: any | null
        proclamationNumber?: string | null
        effectiveDate?: any | null
        effectiveEndDate?: any | null
      } | null> | null
    } | null> | null
  } | null
}

export type SearchStatuteJomunDetailQueryVariables = Exact<{
  docGroupId: Scalars['String']['input']
}>

export type SearchStatuteJomunDetailQuery = {
  __typename?: 'Query'
  searchStatuteByJo?: {
    __typename?: 'SearchResultStatuteByJo'
    data?: Array<{
      __typename?: 'StatuteByJo'
      statuteName?: string | null
      statuteType?: string | null
      responsibleAgency?: string | null
      relatedDepartments?: Array<{
        __typename?: 'RelatedDepartment'
        organization?: string | null
        department?: string | null
        contact?: string | null
      } | null> | null
      children?: Array<{
        __typename?: 'StatuteHistoryJo'
        docId?: string | null
        revisionType?: string | null
        statuteType?: string | null
        proclamationDate?: any | null
        proclamationNumber?: string | null
        effectiveDate?: any | null
        effectiveEndDate?: any | null
        title?: string | null
        content?: string | null
      } | null> | null
    } | null> | null
  } | null
}

export const SearchStatuteCommetariesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'searchStatuteCommetaries' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'filterInputs' } },
          type: {
            kind: 'ListType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'StatuteCommentaryFilterInput' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'aggregateInputs' } },
          type: {
            kind: 'ListType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'StatuteCommentaryAggregateInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'searchStatuteCommentaries' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'request' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'query' },
                      value: { kind: 'StringValue', value: '', block: false },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'filterInputs' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'filterInputs' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'aggregateInputs' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'aggregateInputs' } },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'aggregates' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'docCount' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'key' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SearchStatuteCommetariesQuery, SearchStatuteCommetariesQueryVariables>
export const GetInterpretationsRespondingAgencyAggregatesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getInterpretationsRespondingAgencyAggregates' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'documentPageType' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'DocumentPageType' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'query' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'searchStatuteInterpretations' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'request' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'query' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'query' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'documentPageType' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'documentPageType' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'aggregateInputs' },
                      value: {
                        kind: 'ListValue',
                        values: [
                          {
                            kind: 'ObjectValue',
                            fields: [
                              {
                                kind: 'ObjectField',
                                name: { kind: 'Name', value: 'field' },
                                value: { kind: 'EnumValue', value: 'responding_agency__keyword' },
                              },
                              {
                                kind: 'ObjectField',
                                name: { kind: 'Name', value: 'type' },
                                value: { kind: 'EnumValue', value: 'term' },
                              },
                              {
                                kind: 'ObjectField',
                                name: { kind: 'Name', value: 'size' },
                                value: { kind: 'IntValue', value: '100' },
                              },
                            ],
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'page' },
                      value: { kind: 'IntValue', value: '1' },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'aggregates' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'docCount' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'key' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetInterpretationsRespondingAgencyAggregatesQuery,
  GetInterpretationsRespondingAgencyAggregatesQueryVariables
>
export const GetTrialdecisionAggregatesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getTrialdecisionAggregates' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'page' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'query' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'aggregateInputs' } },
          type: {
            kind: 'ListType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'CaseLawAggregateInput' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'sortInputs' } },
          type: { kind: 'ListType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'CaseLawSortInput' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'filterInputs' } },
          type: { kind: 'ListType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'CaseLawFilterInput' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'searchCaseLaws' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'request' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'page' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'page' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'query' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'query' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'aggregateInputs' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'aggregateInputs' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'sortInputs' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'sortInputs' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'filterInputs' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'filterInputs' } },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'count' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'aggregates' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'docCount' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'key' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetTrialdecisionAggregatesQuery, GetTrialdecisionAggregatesQueryVariables>
export const GetPrecedentRelatedLawwaveDocumentsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getPrecedentRelatedLawwaveDocuments' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'docId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'precedentRelatedLawwaveDocuments' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'docId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'docId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'relatedLawwavePosts' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'docId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'lawwavePostId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'lboxLink' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'lawwaveLink' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'subtitle' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'author' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'tags' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetPrecedentRelatedLawwaveDocumentsQuery,
  GetPrecedentRelatedLawwaveDocumentsQueryVariables
>
export const SearchIslaModelResultsWithVariablesQueryDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'searchIslaModelResultsWithVariablesQuery' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'query' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'category' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'page' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'size' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'sortInputs' } },
          type: {
            kind: 'ListType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'IeModelSearchSortInput' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'filterInputs' } },
          type: {
            kind: 'ListType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'IeModelSearchFilterInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'searchIeModelResults' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'request' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'query' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'query' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'category' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'category' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'page' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'page' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'size' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'size' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'sortInputs' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'sortInputs' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'filterInputs' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'filterInputs' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'aggregateInputs' },
                      value: {
                        kind: 'ListValue',
                        values: [
                          {
                            kind: 'ObjectValue',
                            fields: [
                              {
                                kind: 'ObjectField',
                                name: { kind: 'Name', value: 'type' },
                                value: { kind: 'EnumValue', value: 'term' },
                              },
                              {
                                kind: 'ObjectField',
                                name: { kind: 'Name', value: 'field' },
                                value: { kind: 'EnumValue', value: 'precedent__result__keyword' },
                              },
                            ],
                          },
                          {
                            kind: 'ObjectValue',
                            fields: [
                              {
                                kind: 'ObjectField',
                                name: { kind: 'Name', value: 'type' },
                                value: { kind: 'EnumValue', value: 'date_histogram' },
                              },
                              {
                                kind: 'ObjectField',
                                name: { kind: 'Name', value: 'field' },
                                value: { kind: 'EnumValue', value: 'precedent__announce_date' },
                              },
                              {
                                kind: 'ObjectField',
                                name: { kind: 'Name', value: 'calendarInterval' },
                                value: { kind: 'EnumValue', value: 'Year' },
                              },
                            ],
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'data' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'docId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'snippet' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'precedent' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'docId' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'viewCount' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'quotedCount' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'result' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'order' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'confirmedInfo' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [{ kind: 'Field', name: { kind: 'Name', value: 'confirmed' } }],
                              },
                            },
                            { kind: 'Field', name: { kind: 'Name', value: 'relief' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'reliefName' } },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'aggregates' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'key' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'docCount' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'count' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  SearchIslaModelResultsWithVariablesQueryQuery,
  SearchIslaModelResultsWithVariablesQueryQueryVariables
>
export const SearchPrecedentsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'searchPrecedents' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'searchInput' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'SearchInput' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'highlightFields' } },
          type: { kind: 'ListType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'aggregateInputs' } },
          type: {
            kind: 'ListType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'PrecedentAggregateInput' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'sortInputs' } },
          type: { kind: 'ListType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'PrecedentSortInput' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'page' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'size' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'searchPrecedents' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'request' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'searchInput' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'searchInput' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'highlightFields' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'highlightFields' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'aggregateInputs' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'aggregateInputs' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'sortInputs' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'sortInputs' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'page' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'page' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'size' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'size' } },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'count' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'data' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'mainTitle' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'snippet' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'textSubInfo' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SearchPrecedentsQuery, SearchPrecedentsQueryVariables>
export const SearchStatuteCommentariesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'searchStatuteCommentaries' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'size' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'page' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'query' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'filterInputs' } },
          type: {
            kind: 'ListType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'StatuteCommentaryFilterInput' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'aggregateInputs' } },
          type: {
            kind: 'ListType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'StatuteCommentaryAggregateInput' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'sortInputs' } },
          type: {
            kind: 'ListType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'StatuteCommentarySortInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'searchStatuteCommentaries' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'request' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'size' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'size' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'page' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'page' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'query' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'query' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'filterInputs' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'filterInputs' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'aggregateInputs' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'aggregateInputs' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'sortInputs' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'sortInputs' } },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'count' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'data' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'documentId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'mainTitle' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'snippet' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'documentPageType' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'textSubInfo' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SearchStatuteCommentariesQuery, SearchStatuteCommentariesQueryVariables>
export const GetPdfViewerBookListDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getPdfViewerBookList' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'filterInputs' } },
          type: {
            kind: 'ListType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'StatuteCommentaryFilterInput' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'aggregateInputs' } },
          type: {
            kind: 'ListType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'StatuteCommentaryAggregateInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'searchStatuteCommentaries' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'request' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'size' },
                      value: { kind: 'IntValue', value: '50' },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'page' },
                      value: { kind: 'IntValue', value: '1' },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'query' },
                      value: { kind: 'StringValue', value: '', block: false },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'filterInputs' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'filterInputs' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'aggregateInputs' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'aggregateInputs' } },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'count' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'aggregates' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'docCount' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'key' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetPdfViewerBookListQuery, GetPdfViewerBookListQueryVariables>
export const GetCommentaryStatuteFilterDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getCommentaryStatuteFilter' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'aggregateInputs' } },
          type: {
            kind: 'ListType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'StatuteCommentaryAggregateInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'searchStatuteCommentaryIndices' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'request' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'query' },
                      value: { kind: 'StringValue', value: '', block: false },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'aggregateInputs' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'aggregateInputs' } },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'aggregates' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'docCount' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'key' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetCommentaryStatuteFilterQuery, GetCommentaryStatuteFilterQueryVariables>
export const GetSearchStatuteCommentaryIndicesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getSearchStatuteCommentaryIndices' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'size' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'filterInputs' } },
          type: {
            kind: 'ListType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'StatuteCommentaryFilterInput' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'sortInputs' } },
          type: {
            kind: 'ListType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'StatuteCommentarySortInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'searchStatuteCommentaryIndices' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'request' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'query' },
                      value: { kind: 'StringValue', value: '', block: false },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'size' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'size' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'filterInputs' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'filterInputs' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'sortInputs' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'sortInputs' } },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'data' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'textSubInfo' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetSearchStatuteCommentaryIndicesQuery, GetSearchStatuteCommentaryIndicesQueryVariables>
export const GetInterpretationsSearchResultDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getInterpretationsSearchResult' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'page' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'documentPageType' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'DocumentPageType' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'query' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'filterInputs' } },
          type: {
            kind: 'ListType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'StatuteInterpretationFilterInput' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'sortInputs' } },
          type: {
            kind: 'ListType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'StatuteInterpretationSortInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'searchStatuteInterpretations' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'request' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'query' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'query' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'documentPageType' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'documentPageType' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'filterInputs' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'filterInputs' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'sortInputs' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'sortInputs' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'page' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'page' } },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'count' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'data' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'documentId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'documentPageType' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'mainTitle' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'snippet' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'textSubInfo' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'isPdfViewer' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetInterpretationsSearchResultQuery, GetInterpretationsSearchResultQueryVariables>
export const SearchStatuteNorthDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'searchStatuteNorth' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'page' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'size' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'highlightFields' } },
          type: { kind: 'ListType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'searchInput' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'SearchInput' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'searchStatuteNorth' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'request' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'page' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'page' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'size' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'size' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'highlightFields' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'highlightFields' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'searchInput' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'searchInput' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'sortInputs' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'field' },
                            value: { kind: 'EnumValue', value: '_score' },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'order' },
                            value: { kind: 'EnumValue', value: 'Desc' },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'count' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'data' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'documentId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'mainTitle' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'isPdfViewer' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SearchStatuteNorthQuery, SearchStatuteNorthQueryVariables>
export const GetSearchCaseLawsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getSearchCaseLaws' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'page' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'query' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'aggregateInputs' } },
          type: {
            kind: 'ListType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'CaseLawAggregateInput' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'sortInputs' } },
          type: { kind: 'ListType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'CaseLawSortInput' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'filterInputs' } },
          type: { kind: 'ListType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'CaseLawFilterInput' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'searchCaseLaws' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'request' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'page' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'page' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'query' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'query' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'aggregateInputs' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'aggregateInputs' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'sortInputs' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'sortInputs' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'filterInputs' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'filterInputs' } },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'count' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'data' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'documentId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'mainTitle' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'snippet' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'documentPageType' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'textSubInfo' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'isPdfViewer' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'aggregates' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'docCount' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'key' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetSearchCaseLawsQuery, GetSearchCaseLawsQueryVariables>
export const StatuteNorthDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'statuteNorth' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'docId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'statuteNorth' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'docId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'docId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'docId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'documentId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'textViewer' } },
                { kind: 'Field', name: { kind: 'Name', value: 'pdfViewer' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<StatuteNorthQuery, StatuteNorthQueryVariables>
export const CaseLawDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'CaseLaw' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'docId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'caseLaw' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'docId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'docId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'documentId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'textViewer' } },
                { kind: 'Field', name: { kind: 'Name', value: 'pdfViewer' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CaseLawQuery, CaseLawQueryVariables>
export const StatuteInterpretationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'StatuteInterpretation' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'docId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'statuteInterpretation' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'docId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'docId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'textViewer' } },
                { kind: 'Field', name: { kind: 'Name', value: 'pdfViewer' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<StatuteInterpretationQuery, StatuteInterpretationQueryVariables>
export const SearchStatuteTextViewerDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'searchStatuteTextViewer' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'docId' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'searchStatuteTextViewer' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'request' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'sortInputs' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'field' },
                            value: { kind: 'EnumValue', value: '_score' },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'order' },
                            value: { kind: 'EnumValue', value: 'Desc' },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'page' },
                      value: { kind: 'IntValue', value: '1' },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'size' },
                      value: { kind: 'IntValue', value: '10' },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'searchInput' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'type' },
                            value: { kind: 'EnumValue', value: 'and' },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'searchInputs' },
                            value: {
                              kind: 'ListValue',
                              values: [
                                {
                                  kind: 'ObjectValue',
                                  fields: [
                                    {
                                      kind: 'ObjectField',
                                      name: { kind: 'Name', value: 'type' },
                                      value: { kind: 'EnumValue', value: 'match' },
                                    },
                                    {
                                      kind: 'ObjectField',
                                      name: { kind: 'Name', value: 'fields' },
                                      value: {
                                        kind: 'ListValue',
                                        values: [{ kind: 'StringValue', value: 'doc_id', block: false }],
                                      },
                                    },
                                    {
                                      kind: 'ObjectField',
                                      name: { kind: 'Name', value: 'value' },
                                      value: { kind: 'Variable', name: { kind: 'Name', value: 'docId' } },
                                    },
                                  ],
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'count' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'data' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'docGroupId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'docId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'statuteName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'statuteNameShort' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'statuteNameNoSpace' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'proclamationDate' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'effectiveDate' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'responsibleAgency' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'revisionType' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'statuteType' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'proclamationNumber' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'tempHistoryStatus' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'relatedDepartments' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'organization' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'department' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'contact' } },
                          ],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'jsonUrl' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SearchStatuteTextViewerQuery, SearchStatuteTextViewerQueryVariables>
export const SearchStatuteTextViewerHistoryDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'searchStatuteTextViewerHistory' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'docGroupId' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'searchStatuteByName' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'request' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'page' },
                      value: { kind: 'IntValue', value: '1' },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'size' },
                      value: { kind: 'IntValue', value: '10' },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'searchInput' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'type' },
                            value: { kind: 'EnumValue', value: 'and' },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'searchInputs' },
                            value: {
                              kind: 'ListValue',
                              values: [
                                {
                                  kind: 'ObjectValue',
                                  fields: [
                                    {
                                      kind: 'ObjectField',
                                      name: { kind: 'Name', value: 'type' },
                                      value: { kind: 'EnumValue', value: 'match' },
                                    },
                                    {
                                      kind: 'ObjectField',
                                      name: { kind: 'Name', value: 'fields' },
                                      value: {
                                        kind: 'ListValue',
                                        values: [{ kind: 'StringValue', value: 'doc_group_id', block: false }],
                                      },
                                    },
                                    {
                                      kind: 'ObjectField',
                                      name: { kind: 'Name', value: 'value' },
                                      value: { kind: 'Variable', name: { kind: 'Name', value: 'docGroupId' } },
                                    },
                                  ],
                                },
                                {
                                  kind: 'ObjectValue',
                                  fields: [
                                    {
                                      kind: 'ObjectField',
                                      name: { kind: 'Name', value: 'type' },
                                      value: { kind: 'EnumValue', value: 'has_child' },
                                    },
                                    {
                                      kind: 'ObjectField',
                                      name: { kind: 'Name', value: 'hasChild' },
                                      value: {
                                        kind: 'ObjectValue',
                                        fields: [
                                          {
                                            kind: 'ObjectField',
                                            name: { kind: 'Name', value: 'type' },
                                            value: { kind: 'StringValue', value: 'history', block: false },
                                          },
                                          {
                                            kind: 'ObjectField',
                                            name: { kind: 'Name', value: 'searchInput' },
                                            value: {
                                              kind: 'ObjectValue',
                                              fields: [
                                                {
                                                  kind: 'ObjectField',
                                                  name: { kind: 'Name', value: 'type' },
                                                  value: { kind: 'EnumValue', value: 'match_all' },
                                                },
                                              ],
                                            },
                                          },
                                          {
                                            kind: 'ObjectField',
                                            name: { kind: 'Name', value: 'innerHitsInput' },
                                            value: {
                                              kind: 'ObjectValue',
                                              fields: [
                                                {
                                                  kind: 'ObjectField',
                                                  name: { kind: 'Name', value: 'size' },
                                                  value: { kind: 'IntValue', value: '100' },
                                                },
                                              ],
                                            },
                                          },
                                        ],
                                      },
                                    },
                                  ],
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'count' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'data' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'statuteName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'statuteNameShort' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'statuteNameNoSpace' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'statuteNameSet' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'children' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'docId' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'statuteName' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'statuteType' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'statuteNameShort' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'statuteNameNoSpace' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'revisionType' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'proclamationDate' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'proclamationNumber' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'effectiveDate' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'effectiveEndDate' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SearchStatuteTextViewerHistoryQuery, SearchStatuteTextViewerHistoryQueryVariables>
export const SearchStatuteJomunDetailDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'searchStatuteJomunDetail' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'docGroupId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'searchStatuteByJo' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'request' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'page' },
                      value: { kind: 'IntValue', value: '1' },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'size' },
                      value: { kind: 'IntValue', value: '1' },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'sortInputs' },
                      value: {
                        kind: 'ListValue',
                        values: [
                          {
                            kind: 'ObjectValue',
                            fields: [
                              {
                                kind: 'ObjectField',
                                name: { kind: 'Name', value: 'field' },
                                value: { kind: 'EnumValue', value: '_score' },
                              },
                              {
                                kind: 'ObjectField',
                                name: { kind: 'Name', value: 'order' },
                                value: { kind: 'EnumValue', value: 'Desc' },
                              },
                            ],
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'searchInput' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'type' },
                            value: { kind: 'EnumValue', value: 'and' },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'searchInputs' },
                            value: {
                              kind: 'ListValue',
                              values: [
                                {
                                  kind: 'ObjectValue',
                                  fields: [
                                    {
                                      kind: 'ObjectField',
                                      name: { kind: 'Name', value: 'type' },
                                      value: { kind: 'EnumValue', value: 'match' },
                                    },
                                    {
                                      kind: 'ObjectField',
                                      name: { kind: 'Name', value: 'fields' },
                                      value: {
                                        kind: 'ListValue',
                                        values: [{ kind: 'StringValue', value: 'doc_group_id', block: false }],
                                      },
                                    },
                                    {
                                      kind: 'ObjectField',
                                      name: { kind: 'Name', value: 'value' },
                                      value: { kind: 'Variable', name: { kind: 'Name', value: 'docGroupId' } },
                                    },
                                  ],
                                },
                                {
                                  kind: 'ObjectValue',
                                  fields: [
                                    {
                                      kind: 'ObjectField',
                                      name: { kind: 'Name', value: 'type' },
                                      value: { kind: 'EnumValue', value: 'has_child' },
                                    },
                                    {
                                      kind: 'ObjectField',
                                      name: { kind: 'Name', value: 'hasChild' },
                                      value: {
                                        kind: 'ObjectValue',
                                        fields: [
                                          {
                                            kind: 'ObjectField',
                                            name: { kind: 'Name', value: 'type' },
                                            value: { kind: 'StringValue', value: 'history', block: false },
                                          },
                                          {
                                            kind: 'ObjectField',
                                            name: { kind: 'Name', value: 'searchInput' },
                                            value: {
                                              kind: 'ObjectValue',
                                              fields: [
                                                {
                                                  kind: 'ObjectField',
                                                  name: { kind: 'Name', value: 'type' },
                                                  value: { kind: 'EnumValue', value: 'match' },
                                                },
                                                {
                                                  kind: 'ObjectField',
                                                  name: { kind: 'Name', value: 'fields' },
                                                  value: {
                                                    kind: 'ListValue',
                                                    values: [{ kind: 'StringValue', value: 'key_2', block: false }],
                                                  },
                                                },
                                                {
                                                  kind: 'ObjectField',
                                                  name: { kind: 'Name', value: 'value' },
                                                  value: { kind: 'StringValue', value: '2_5', block: false },
                                                },
                                              ],
                                            },
                                          },
                                          {
                                            kind: 'ObjectField',
                                            name: { kind: 'Name', value: 'innerHitsInput' },
                                            value: {
                                              kind: 'ObjectValue',
                                              fields: [
                                                {
                                                  kind: 'ObjectField',
                                                  name: { kind: 'Name', value: 'sort' },
                                                  value: {
                                                    kind: 'ListValue',
                                                    values: [
                                                      {
                                                        kind: 'ObjectValue',
                                                        fields: [
                                                          {
                                                            kind: 'ObjectField',
                                                            name: { kind: 'Name', value: 'field' },
                                                            value: {
                                                              kind: 'StringValue',
                                                              value: 'effective_date',
                                                              block: false,
                                                            },
                                                          },
                                                          {
                                                            kind: 'ObjectField',
                                                            name: { kind: 'Name', value: 'order' },
                                                            value: { kind: 'EnumValue', value: 'Desc' },
                                                          },
                                                        ],
                                                      },
                                                      {
                                                        kind: 'ObjectValue',
                                                        fields: [
                                                          {
                                                            kind: 'ObjectField',
                                                            name: { kind: 'Name', value: 'field' },
                                                            value: {
                                                              kind: 'StringValue',
                                                              value: 'proclamation_number_int',
                                                              block: false,
                                                            },
                                                          },
                                                          {
                                                            kind: 'ObjectField',
                                                            name: { kind: 'Name', value: 'order' },
                                                            value: { kind: 'EnumValue', value: 'Desc' },
                                                          },
                                                        ],
                                                      },
                                                      {
                                                        kind: 'ObjectValue',
                                                        fields: [
                                                          {
                                                            kind: 'ObjectField',
                                                            name: { kind: 'Name', value: 'field' },
                                                            value: {
                                                              kind: 'StringValue',
                                                              value: 'proclamation_date',
                                                              block: false,
                                                            },
                                                          },
                                                          {
                                                            kind: 'ObjectField',
                                                            name: { kind: 'Name', value: 'order' },
                                                            value: { kind: 'EnumValue', value: 'Desc' },
                                                          },
                                                        ],
                                                      },
                                                    ],
                                                  },
                                                },
                                                {
                                                  kind: 'ObjectField',
                                                  name: { kind: 'Name', value: 'size' },
                                                  value: { kind: 'IntValue', value: '1000' },
                                                },
                                              ],
                                            },
                                          },
                                        ],
                                      },
                                    },
                                  ],
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'data' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'statuteName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'statuteType' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'responsibleAgency' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'relatedDepartments' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'organization' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'department' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'contact' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'children' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'docId' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'revisionType' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'statuteType' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'proclamationDate' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'proclamationNumber' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'effectiveDate' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'effectiveEndDate' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'content' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SearchStatuteJomunDetailQuery, SearchStatuteJomunDetailQueryVariables>
