import { useMutation, useQueryClient } from '@tanstack/react-query'

import { postUserUpdateData } from '../../apis/user/api'
import { USER_QUERY_KEYS } from '../../apis/user/user-current/queries'

export default function usePostUserUpdateData() {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: postUserUpdateData,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: USER_QUERY_KEYS.current })
    },
  })
}
