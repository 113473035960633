import { useMatchMedia } from '../useMatchMedia'

/**
 * @todo
 * @author 준혁
 * BRACK_POINT_NUMBER Value 는 window.matchMedia 를 이용 혹은 mediaQuery 를 작성하는 다른 곳에서도 사용할 수 있다.
 * 외부로 내보내기 처리가 된 상수 값으로 관리할 필요가 있음
 */
const BREAK_POINT_TABLET = 768
const BREAK_POINT_DESKTOP = 960

interface UseMediaQueryParams {
  breakPointTablet?: number
  breakPointDesktop?: number
}

export function useMediaQuery({
  breakPointTablet = BREAK_POINT_TABLET,
  breakPointDesktop = BREAK_POINT_DESKTOP,
}: UseMediaQueryParams = {}) {
  const isMobile = useMatchMedia(`(max-width: ${breakPointTablet - 1}px)`)
  const isTablet = useMatchMedia(`(min-width: ${breakPointTablet}px) and (max-width: ${breakPointDesktop - 1}px)`)
  const isDesktop = useMatchMedia(`(min-width: ${breakPointDesktop}px)`)

  const isTabletOrDesktop = isTablet || isDesktop

  return {
    isMobile,
    isTablet,
    isDesktop,
    isTabletOrDesktop,
  }
}
