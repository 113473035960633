import { PagesProgressProvider as ProgressProvider } from '@bprogress/next'

import AppProvider from '@/components/shared/AppProvider'
import Head from '@/components/shared/meta/head/Head'
import type { AppPropsWithLayout } from '@/types/app'
import type { ServerSidePropsDataWithDehydrated } from '@/types/react-query'

export default function App({ Component, pageProps }: AppPropsWithLayout<ServerSidePropsDataWithDehydrated>) {
  const getLayout = Component.getLayout ?? ((page) => page)

  return (
    <>
      <Head
        title="LBOX - 판례 검색은 엘박스에서!"
        description="국내 최대 법률 데이터베이스 엘박스에서 원하는 법률문서를 자유롭게 검색해보세요."
        ogTitle="LBOX - 판례 검색은 엘박스에서!"
        ogDescription="국내 최대 법률 데이터베이스 엘박스에서 원하는 법률문서를 자유롭게 검색해보세요."
      />
      <AppProvider dehydratedState={pageProps.dehydratedState}>
        <ProgressProvider height="4px" color="rgb(var(--brand-600))" options={{ showSpinner: false }} shallowRouting>
          {getLayout(<Component {...pageProps} />)}
        </ProgressProvider>
      </AppProvider>
    </>
  )
}
