import {
  forwardRef,
  useEffect,
  useMemo,
  useRef,
  useState,
  createContext,
  useContext,
  createElement,
  Fragment,
} from 'react'
import type { PropsWithChildren, ReactNode } from 'react'

import { IconCheckCircle, IconWarning, Portal } from '@lbox/shared/components'
import { partition } from '@lbox/shared/utils'

import { usePreservedCallback } from '@toss/react'
import cn from 'classnames'
import { AnimatePresence, motion } from 'framer-motion'

const DEFAULT_AUTO_HIDE_DURATION = 5000
const MAX_SNACKBAR_COUNT = 3

type SnackbarItem = {
  id: string
  state?: 'success' | 'error' | 'normal'
  /** 메시지 */
  message: ReactNode
  /** 표시 시간 */
  autoHideDuration?: number | 'permanent'
  /** 메시지 우측에 들어갈 자유 컴포넌트 */
  rightTrailingComponent?: ReactNode
  /** 스낵바 클릭 시 닫히는지 여부 */
  closeOnClick?: boolean
  /** 노출 방향 => 화면 위로부터(top) or 아래로부터(bottom)  */
  slideFrom?: 'top' | 'bottom'
  /** 스낵바 닫힐 때 실행할 함수 */
  onClose?: () => void
}

export type SnackbarOptions = Omit<SnackbarItem, 'id'>
export type SnackbarMethodOptions = Omit<SnackbarItem, 'id' | 'state'>
export type SnackbarInstance = { id: string; close: () => void; get isClosed(): boolean }

const internalContext = createContext<{
  success: (options: SnackbarMethodOptions) => SnackbarInstance
  error: (options: SnackbarMethodOptions) => SnackbarInstance
  normal: (options: SnackbarMethodOptions) => SnackbarInstance
  close: (id?: string) => void
} | null>(null)

export function useSnackbar() {
  return useContext(internalContext)!
}

let id = 0

const STATE_ICON_COMPONENT = {
  normal: null,
  success: <IconCheckCircle className="h-6 w-6 shrink-0 text-green-400" weight="bold" />,
  error: <IconWarning className="h-6 w-6 shrink-0 text-rose-500" weight="bold" />,
}

export const SnackbarProvider = ({ children }: PropsWithChildren) => {
  const [items, setItems] = useState<SnackbarItem[]>([])
  const [topItems, bottomItems] = useMemo(() => partition(items, (item) => item.slideFrom === 'top'), [items])

  const remove = usePreservedCallback((id?: string) => {
    setItems((prev) => (id ? prev.filter((item) => item.id !== id) : prev.slice(1)))
  })

  const push = usePreservedCallback(
    ({ slideFrom = 'bottom', autoHideDuration = DEFAULT_AUTO_HIDE_DURATION, ...rest }: SnackbarOptions) => {
      const curId = String(id++)
      setItems((prev) => [...prev, { id: curId, slideFrom, autoHideDuration, ...rest }])
      return {
        id: curId,
        close() {
          remove(curId)
        },
        get isClosed() {
          return !items.some((item) => item.id === curId)
        },
      }
    }
  )

  const methods = useMemo(
    () => ({
      success(options: SnackbarMethodOptions) {
        return push({ state: 'success', ...options })
      },
      error(options: SnackbarMethodOptions) {
        return push({ state: 'error', ...options })
      },
      normal(options: SnackbarMethodOptions) {
        return push({ state: 'normal', ...options })
      },
      close(id?: string) {
        remove(id)
      },
    }),
    [remove, push]
  )

  return (
    <internalContext.Provider value={methods}>
      {children}
      <RenderSnackbarItems items={topItems} className="top-4 lds2-tablet:top-8" maxCount={MAX_SNACKBAR_COUNT} />
      <RenderSnackbarItems
        items={bottomItems}
        className="bottom-4 lds2-tablet:bottom-8"
        maxCount={MAX_SNACKBAR_COUNT}
      />
    </internalContext.Provider>
  )
}

function RenderSnackbarItems({
  items,
  className,
  maxCount = Infinity,
}: {
  items: SnackbarItem[]
  className?: string
  maxCount?: number
}) {
  const methods = useSnackbar()
  const [isHover, setIsHover] = useState(false)
  const heightsRef = useRef<Record<string, number>>({})

  useEffect(() => {
    if (items.length > maxCount) {
      methods.close(items[0].id)
    }
  }, [items, maxCount, methods])

  return (
    <Portal rootId="toast-root" isOpen>
      <div className={cn('fixed left-0 z-[9999] flex w-full justify-center px-4', className)}>
        <motion.div
          whileHover="hover"
          onHoverStart={() => setIsHover(true)}
          onHoverEnd={() => setIsHover(false)}
          className="relative box-border flex w-full lds2-tablet:max-w-[500px]"
        >
          <AnimatePresence mode="popLayout" initial={false}>
            {items.map((item, i) => (
              <RenderSnackbarItem items={items} index={i} hovered={isHover} heightsRef={heightsRef} key={item.id} />
            ))}
          </AnimatePresence>
        </motion.div>
      </div>
    </Portal>
  )
}

const RenderSnackbarItem = forwardRef<
  HTMLDivElement,
  {
    items: SnackbarItem[]
    index: number
    hovered: boolean
    heightsRef: React.MutableRefObject<Record<string, number>>
  }
>(function RenderSnackbarItem({ items, index, hovered, heightsRef }, ref) {
  const item = items[index]
  const methods = useSnackbar()

  function toY(to: number) {
    const absY = hovered
      ? items.slice(index + 1).reduce((acc, next) => acc + heightsRef.current[next.id] + 12, 0)
      : (items.length - index - 1) ** 0.7 * 8
    return item.slideFrom === 'top' ? absY - to : -absY + to
  }

  const onClose = usePreservedCallback(() => {
    item.onClose?.()
    methods.close(item.id)
  })

  useEffect(() => {
    if (item.autoHideDuration === 'permanent') {
      return
    }
    const tid = setTimeout(onClose, item.autoHideDuration ?? DEFAULT_AUTO_HIDE_DURATION)
    return () => clearTimeout(tid)
  }, [item, item.autoHideDuration, item.id, methods, onClose])

  return (
    <motion.div
      ref={ref}
      className={cn('absolute bottom-0 w-full pt-[12px]', item.slideFrom === 'top' ? 'top-0' : 'bottom-0')}
      key={item.id}
      layout
      variants={{
        initial: () => ({
          y: toY(20),
        }),
        animate: () => ({
          y: toY(0),
          scale: 0.9 + ((index + 1) / items.length) * 0.1,
        }),
        hover: () => ({
          y: toY(0),
          scale: 1,
          transition: {
            duration: 0.25,
          },
        }),
        exit: () => ({
          y: toY(20),
        }),
      }}
      transition={{ ease: 'linear' }}
      initial="initial"
      animate={hovered ? 'hover' : 'animate'}
      exit="exit"
    >
      <motion.div
        initial={{
          opacity: 0,
          backdropFilter: 'blur(0px)',
        }}
        animate={{
          opacity: 1,
          backdropFilter: 'blur(120px)',
        }}
        exit={{
          opacity: 0,
          backdropFilter: 'blur(0px)',
        }}
        className="flex items-center justify-between rounded-lg bg-zinc-900/70 p-4"
        ref={(el) => {
          if (el) {
            heightsRef.current[item.id] = el.getBoundingClientRect().height
          } else {
            delete heightsRef.current?.[item.id]
          }
        }}
        onClick={() => {
          if (item.closeOnClick) {
            onClose()
          }
        }}
      >
        <div className="flex items-center gap-x-2">
          <div className="shrink-0 empty:hidden">{STATE_ICON_COMPONENT[item.state ?? 'normal']}</div>
          <p className="line-clamp-2 break-keep text-lds2-body2-medium text-white">{item.message}</p>
        </div>
        <div className="shrink-0 empty:hidden">{item.rightTrailingComponent}</div>
      </motion.div>
    </motion.div>
  )
})

export function Snackbar(
  props: SnackbarOptions & {
    isOpen?: boolean
  }
) {
  // Provider 없이 사용할 경우, 임시 Provider를 만들어줍니다.
  // 쌓이는 효과없이 단일 스낵바만 노출됩니다.
  return createElement(useContext(internalContext) ? Fragment : SnackbarProvider, {}, <CallSnackbar {...props} />)
}

function CallSnackbar({
  state,
  message,
  rightTrailingComponent,
  closeOnClick,
  autoHideDuration,
  isOpen,
  onClose: onCloseProp,
}: SnackbarOptions & { isOpen?: boolean }) {
  const methods = useSnackbar()
  const onClose = usePreservedCallback(() => onCloseProp?.())
  useEffect(() => {
    if (!isOpen) {
      return
    }
    const current = methods[state ?? 'normal']({
      message,
      rightTrailingComponent,
      closeOnClick,
      autoHideDuration,
      onClose,
    })
    return () => {
      current.close()
    }
  }, [methods, autoHideDuration, closeOnClick, isOpen, message, rightTrailingComponent, state, onClose])
  return null
}
