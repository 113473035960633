import { useEffect, useState } from 'react'

import { isServer } from '@toss/utils'
import { Userpilot } from 'userpilot'

import { ENV } from '@/constants/env'

export default function useUserpilotInit() {
  const [isInitialized, setIsInitialized] = useState(false)

  useEffect(() => {
    // SSR이거나 로컬 개발환경에서는 실행하지 않음
    if (isServer() || ENV.NODE_ENV === 'development') {
      return
    }

    if (isInitialized) {
      return
    }

    Userpilot.initialize('NX-217646b5')
    setIsInitialized(true)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return { isInitialized }
}
