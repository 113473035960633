/* eslint-disable */
export function handleReadyLboxGtag() {
  if (location.origin === 'https://lbox.kr') {
    window.dataLayer = window.dataLayer || []
    
    window.gtag = function() {
      window.dataLayer.push(arguments)
    }

    window.gtag('js', new Date())

    window.gtag('config', 'UA-146890468-1')
    window.gtag('config', 'G-HD4PVKYJRK')
    window.gtag('config', 'AW-11153718063')
  }
}

export const handleReadyLboxAiGtag = () => {
  if (location.origin === 'https://lbox.kr') {
    window.dataLayer = window.dataLayer || []
    
    window.gtag = function() {
      window.dataLayer.push(arguments)
    }

    window.gtag('js', new Date())
    window.gtag('config', 'G-QCDNZ4G1EZ')
  }
}
