import { isServer } from '@toss/utils'
import type { AxiosRequestConfig, CreateAxiosDefaults } from 'axios'
import axios from 'axios'

import { TIME } from '../../constants/time'

const DefaultAxiosConfig: CreateAxiosDefaults = {
  timeout: TIME['30초'],
  withCredentials: true,
  paramsSerializer: {
    serialize: (params: Record<string, string>) => {
      return new URLSearchParams(params).toString()
    },
  },
  adapter: isServer() ? ['http', 'fetch'] : ['fetch', 'xhr'], // https://github.com/vercel/next.js/issues/68521#issuecomment-2270081109
}

export const createAxiosClient = (config?: AxiosRequestConfig) => {
  const axiosInstance = axios.create({ ...DefaultAxiosConfig, ...config })

  return axiosInstance
}
