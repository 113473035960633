import React from 'react'

const LiVector = ({ className }: { className?: string }) => {
  return (
    <svg
      width="20"
      height="24"
      viewBox="0 0 20 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <circle cx="10" cy="12" r="2" fill="#52525B" />
    </svg>
  )
}

export default LiVector
