import { useIsMounted } from '@toss/react'

import type { StrictPropsWithChildren } from '../../types/utils'

export interface CSROnlyProps {
  fallback?: React.ReactNode
}

export const CSROnly = ({ children, fallback }: StrictPropsWithChildren<CSROnlyProps>) => {
  const isMounted = useIsMounted()

  if (!isMounted) {
    return fallback ? <>{fallback}</> : null
  }

  return <>{children}</>
}
