import type { ForwardedRef, PropsWithChildren } from 'react'
import { forwardRef } from 'react'

import { useIsOverflowing, useScroll } from '@lbox/shared/hooks'

import { useCombinedRefs } from '@toss/react'
import cn from 'classnames'

import { twMergeLDS } from '../../../utils'
import { SpacingGradient } from '../spacing-gradient'

export const Body = forwardRef(function Body(
  {
    id,
    children,
    className,
    gradientClassName,
  }: PropsWithChildren<{ id?: string; className?: string; gradientClassName?: string }>,
  forwardedRef: ForwardedRef<HTMLDivElement>
) {
  const { ref: overflowRef, isOverflowing } = useIsOverflowing()
  const { ref: scrollRef, arrivedState } = useScroll()

  const ref = useCombinedRefs(overflowRef, scrollRef, forwardedRef)

  const isScrolled = !arrivedState.top

  return (
    <div
      id={id}
      ref={ref}
      className={twMergeLDS(
        cn(
          'relative grow overflow-y-auto break-all border-border-secondary',
          'text-lds2-body2-regular text-zinc-700',
          'px-[16px] pb-[24px]',
          'lds2-tablet:px-[24px]',
          { 'pb-0': isOverflowing, 'pb-[24px]': !isOverflowing },
          { 'border-t-[0.5px]': isScrolled, 'border-t-0': !isScrolled }
        ),
        className
      )}
    >
      {children}
      <SpacingGradient
        className={twMergeLDS(
          cn('sticky bottom-[-1px]', {
            visible: isOverflowing,
            hidden: !isOverflowing,
          }),
          gradientClassName
        )}
        size="medium"
        direction="down"
        color="white"
      />
    </div>
  )
})
