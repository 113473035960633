import { generateStorage } from '@toss/storage'

import type { WebStorage } from './storage'
import { createStorage } from './storage'

/**
 * toss slash의 safeLocalStorage를 사용한 Web Storage API 구현체
 */
const createSafeLocalStorage = (): WebStorage => {
  const safeLocalStorage = generateStorage()

  return {
    setItem(key, value) {
      return safeLocalStorage.set(key, value)
    },
    getItem(key) {
      return safeLocalStorage.get(key)
    },
    removeItem(key) {
      return safeLocalStorage.remove(key)
    },
    clear() {
      return safeLocalStorage.clear()
    },
  }
}

export const createWebLocalStorage = () => createStorage(createSafeLocalStorage())

export const webLocalStorage = createWebLocalStorage()
