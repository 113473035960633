import { lboxHttpClient } from '@lbox-kr/components/src/utils/api'

import { USER_INVOLVE_CASE_API_ENDPOINTS } from '@/apis/user-involve-case/endpoints'

export interface InvolveCaseCandidate {
  id: string
  doc_id: string
  serial: string
  court: string
  lawfirm: string
  announce_date: number
  side: string
  title: string | null
  lawyers: string | null
  judges: string | null
}

export type GetInvolveCaseCandidateResponse = InvolveCaseCandidate[]

export const getInvolveCaseCandidate = async () => {
  const { data } = await lboxHttpClient.get<GetInvolveCaseCandidateResponse>(
    USER_INVOLVE_CASE_API_ENDPOINTS.candidateList
  )
  return data
}

export type MarkInvolveCaseCandidateParams = { docId: string; type: 'Y' | 'N' }[]

export const markInvolveCaseCandidate = (params: MarkInvolveCaseCandidateParams) => {
  return lboxHttpClient.post(USER_INVOLVE_CASE_API_ENDPOINTS.mark, params)
}
