import { LBOX_USER_API_ENDPOINTS } from './endpoints'
import type { PatchLboxUserJobBody } from './types'
import type { LboxUser } from '../../types/user/lboxUser'
import { lboxHttpClient } from '../../utils/api'

export const getLboxUser = async () => {
  const { data } = await lboxHttpClient.get<LboxUser>(LBOX_USER_API_ENDPOINTS.base)

  return data
}

export const patchLboxUserJob = async (body: PatchLboxUserJobBody) => {
  const { data } = await lboxHttpClient.patch(LBOX_USER_API_ENDPOINTS.job, body)

  return data
}
