import type { BottomSheetHeightVariables } from '../bottom-sheet/BottomSheet'

interface GetBottomSheetHeightPropsParams {
  maxHeightFull?: boolean
  heightFull?: boolean
}

export const getBottomSheetHeightProps = ({
  maxHeightFull,
  heightFull,
}: GetBottomSheetHeightPropsParams): BottomSheetHeightVariables | undefined => {
  if (maxHeightFull) {
    return {
      maxHeightFull: true,
      heightFull: false,
    }
  }

  if (heightFull) {
    return {
      maxHeightFull: false,
      heightFull: true,
    }
  }

  return {
    maxHeightFull: false,
    heightFull: false,
  }
}
