import { generateSessionStorage } from '@toss/storage'

import type { WebStorage } from './storage'
import { createStorage } from './storage'

/**
 * toss slash의 safeSessionStorage를 사용한 Web Storage API 구현체
 */
const createSafeSessionStorage = (): WebStorage => {
  const safeSessionStorage = generateSessionStorage()

  return {
    setItem(key, value) {
      return safeSessionStorage.set(key, value)
    },
    getItem(key) {
      return safeSessionStorage.get(key)
    },
    removeItem(key) {
      return safeSessionStorage.remove(key)
    },
    clear() {
      return safeSessionStorage.clear()
    },
  }
}

export const createWebSessionStorage = () => createStorage(createSafeSessionStorage())

export const webSessionStorage = createWebSessionStorage()
