import Script from 'next/script'

import { LBOX_AI_PAGE_ROOT_PATH, V2_PREFIX } from '@lbox/shared/constants'

import { isClient } from '@toss/utils'

import { handleReadyLboxAiGtag, handleReadyLboxGtag } from '../../../public/scripts/gtag.min'

import { ENV } from '@/constants/env'

/** 외부 스크립트 */
function ExternalScripts() {
  switch (ENV.NODE_ENV) {
    case 'development':
      return <ScriptsOnDev />
    case 'production':
      return <ScriptsOnProd />
    default:
      return <></>
  }
}

function ScriptsOnDev() {
  return <></>
}

function ScriptsOnProd() {
  const isLboxAiPage = isClient() && location.pathname.includes(`/${V2_PREFIX}${LBOX_AI_PAGE_ROOT_PATH}`)

  return (
    <>
      <Script src="https://www.googletagmanager.com/gtag/js?id=UA-146890468-1" onReady={handleReadyLboxGtag} />
      <Script
        src={`/${V2_PREFIX}/scripts/clarity.min.js`}
        onReady={() => console.info('clarity service successfully initialized!')}
      />

      {isLboxAiPage && (
        <Script src="https://www.googletagmanager.com/gtag/js?id=G-QCDNZ4G1EZ" onReady={handleReadyLboxAiGtag} />
      )}
    </>
  )
}

export default ExternalScripts
