import type { PropsWithChildren } from 'react'

import cn from 'classnames'

import { twMergeLDS } from '../../../utils'

export const Headline = ({ children, className, ...props }: PropsWithChildren<{ className?: string }>) => {
  return (
    <h6
      className={twMergeLDS(
        cn(
          'flex shrink-0 items-center',
          'break-keep text-lds2-heading4-semibold text-text-primary',
          'min-h-[56px] px-[16px] py-[12px]',
          'lds2-tablet:min-h-[72px] lds2-tablet:px-[24px] lds2-tablet:py-[12px]'
        ),
        className
      )}
      {...props}
    >
      {children}
    </h6>
  )
}
