import type { NonNullableProperties } from '../../types'

/**
 * 객체에서 null 또는 undefined인 필드를 제거합니다.
 */
export const removeNullishField = <T extends Record<PropertyKey, unknown>>(params: T): NonNullableProperties<T> => {
  return Object.entries(params).reduce((acc, [key, value]) => {
    if (value === null || value === undefined) {
      return acc
    }

    return {
      ...acc,
      [key]: value,
    }
  }, {} as NonNullableProperties<T>)
}
