/**
 * Web Storage API와 호환되는 인터페이스
 * @see https://developer.mozilla.org/ko/docs/Web/API/Storage
 */
export interface WebStorage {
  getItem(key: string): string | null
  setItem(key: string, value: string): void
  removeItem(key: string): void
  clear(): void
}

/**
 * 엘박스 어플리케이션에서 사용하는 Storage 인터페이스
 */
export interface Storage {
  getItem(key: string, defaultValue: string): string
  getItem(key: string, defaultValue: number): number
  getItem(key: string, defaultValue: boolean): boolean
  getItem<T>(key: string, defaultValue: T): T
  setItem<T>(key: string, value: T): void
  removeItem(key: string): void
  clear(): void
  clearExcept(keys: string[]): void
  getArrayItem<T>(key: string): T[]
}

/**
 * Web Storage API 를 이용해 Storage 인터페이스를 반환하는 팩토리 함수
 */
export const createStorage = (storage: WebStorage): Storage => ({
  setItem(key, value) {
    if (typeof value === 'string') {
      return storage.setItem(key, value)
    }
    return storage.setItem(key, JSON.stringify(value))
  },
  getItem<T>(key: string, defaultValue: T) {
    const rawValue = storage.getItem(key)

    // undefined를 value로 저장할 수 있도록 한다.
    if (rawValue === 'undefined') {
      return undefined
    }

    if (rawValue == null) {
      return defaultValue
    }

    try {
      return JSON.parse(rawValue)
    } catch {
      // string 타입을 저장한 경우, JSON.parse 에서 에러가 발생한다. 이 경우 rawValue 그대로 반환한다.
      return rawValue
    }
  },
  getArrayItem<T>(key: string) {
    const value = storage.getItem(key)

    if (!value) {
      return []
    }

    try {
      const parsed = JSON.parse(value)
      if (Array.isArray(parsed)) {
        return parsed as T[]
      }
      return []
    } catch {
      return []
    }
  },
  removeItem(key) {
    return storage.removeItem(key)
  },
  clear() {
    return storage.clear()
  },
  clearExcept(keys) {
    try {
      const predicate = (arr: [string, string | null]): arr is [string, string] => typeof arr[1] === 'string'

      const exceptMap = new Map<string, string>(
        keys.map<[string, string | null]>((key) => [key, storage.getItem(key)]).filter(predicate)
      )

      storage.clear()

      exceptMap.forEach((value, key) => storage.setItem(key, value))
    } catch {
      // noop
    }
  },
})
