import { getCookie } from 'cookies-next'

import { TOKEN_FROM_LBOX_CLIENT } from '../constants/localStorage'

export interface UserFromCookie {
  id: string
  name: string
  email: string
  lboxUserData: boolean
  uuid: string
}

/**
 * @deprecated
 * lbox-client에서 만든 쿠키를 이용하기 때문에 lbox-client의 로직 재사용
 * 이 util 은 client-side 에서 cookie 를 가져올 용도로 작성됨.
 * */
export default function getUserFromCookie(): UserFromCookie | null {
  const cookieValue = getCookie(TOKEN_FROM_LBOX_CLIENT)
  if (typeof cookieValue !== 'string') {
    return null
  }

  try {
    const [, payload] = cookieValue.split('.')
    const decodedPayload = Buffer.from(payload, 'base64').toString('utf-8')
    const { id, name, email, lboxUserData, uuid } = JSON.parse(decodedPayload)
    return { id, name, email, lboxUserData, uuid }
  } catch (err) {
    console.error(err)
    return null
  }
}
