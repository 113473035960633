import { useEffect, useRef } from 'react'

import type { StrictPropsWithChildren } from '@lbox/shared/types'
import { logger, tracker } from '@lbox/shared/utils'

import useMe from '@lbox-kr/components/src/hooks/useMe'

export const UserLog = ({ children }: StrictPropsWithChildren) => {
  const { isLoggedIn, userId } = useMe()
  const isInitialized = useRef(false)

  if (isLoggedIn && !isInitialized.current) {
    logger.setUser({ id: userId })
    tracker.setUserId(userId)
    isInitialized.current = true
  }

  useEffect(() => () => logger.clearUser(), [])

  return <>{children}</>
}
