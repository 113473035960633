import { useCallback } from 'react'

import type { Position as ToastPosition } from '@lbox-kr/libra/v1'
import { useAtom } from 'jotai'

import { toastStatusAtom } from '@/atoms/toast'

interface OpenToastParams {
  message: string
  position?: ToastPosition
  actionLabel?: string
  autoHideDuration?: number
}

const useToast = () => {
  const [{ isOpen, message, actionLabel, autoHideDuration, position: defaultPosition }, setToastStatus] =
    useAtom(toastStatusAtom)

  const openToast = useCallback(({ message, actionLabel = '', position, autoHideDuration = 6000 }: OpenToastParams) => {
    setToastStatus((prevToastStatus) => ({
      ...prevToastStatus,
      isOpen: true,
      message,
      actionLabel,
      position: position ?? defaultPosition,
      autoHideDuration,
    }))
  }, [])

  const closeToast = useCallback(() => {
    setToastStatus((prevToastStatus) => ({
      ...prevToastStatus,
      isOpen: false,
      message: '',
      actionLabel: '',
    }))
  }, [])

  return { isOpen, message, actionLabel, position: defaultPosition, autoHideDuration, openToast, closeToast }
}

export default useToast
