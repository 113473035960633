import type { ReactNode } from 'react'

import { noop } from '@lbox/shared/utils'

import type { ModalProps } from '@lbox-kr/libra/v1'
import { atom } from 'jotai'

export type GlobalErrorModalStatusAtom = {
  isOpen: boolean
  title: ModalProps['title']
  content: ReactNode
  rightButtonInformation?: ModalProps['rightButtonInformation']
}

export const globalErrorModalStatusAtom = atom<GlobalErrorModalStatusAtom>({
  isOpen: false,
  title: '',
  content: null,
  rightButtonInformation: {
    type: 'button',
    handleClick: noop,
  },
})
