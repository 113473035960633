import { lboxHttpClient } from '@lbox-kr/components/src/utils/api'

import { API_ENDPOINT } from '../../constants/api/endpoint'

export interface LboxUserTrackingRequestPayload {
  clientId: string | null
  url: string
  pageId: string
  eventId: string
  prevEventId?: string
  prevPageId?: string
  eventProperties?: Record<string, unknown>
}

export interface LboxUserTrackingResponseData {
  clientId: string | null
  userId: number | null
  url: string
  event: Record<string, unknown>
  pageId: string
  eventId: string
  prevPageId: string | null
  prevEventId: string | null
  id: string
  date: string
  userIp: string | null
  testGroupName: string | null
}

export const postLBoxUserTracking = async ({
  clientId,
  url,
  pageId,
  eventId,
  prevEventId,
  prevPageId,
  eventProperties,
}: LboxUserTrackingRequestPayload): Promise<LboxUserTrackingResponseData> => {
  const { data } = await lboxHttpClient.post(API_ENDPOINT.clientTracking, eventProperties, {
    headers: {
      Clientid: clientId,
    },
    params: {
      url,
      pageId,
      eventId,
      prevEventId,
      prevPageId,
    },
  })
  return data
}
