import { TIME } from '@lbox/shared/constants'

import type { UseQueryOptions } from '@tanstack/react-query'
import { useQuery } from '@tanstack/react-query'

import { USER_API_ENDPOINTS } from './endpoints'
import { getUserCurrent } from './fetchers'
import type { User } from '../../../types/user/user'

export const USER_QUERY_KEYS = {
  base: [USER_API_ENDPOINTS.base],
  current: [USER_API_ENDPOINTS.current],
}

export const useGetUserCurrent = (options?: Omit<UseQueryOptions<User>, 'queryKey' | 'queryFn'>) => {
  return useQuery({
    queryKey: USER_QUERY_KEYS.current,
    queryFn: getUserCurrent,

    staleTime: TIME['1일'], // 검색어 목록 업데이트된 경우 invalidateQueries 호출 중
    ...options,
  })
}
