import type { PropsWithChildren } from 'react'
import { useEffect } from 'react'

import { LBOX_PAGE_PATH, V2_PREFIX } from '@lbox/shared/constants'

import { useSnackbar } from '@lbox-kr/libra/v2'
import { isServer } from '@toss/utils'
import { isAxiosError } from 'axios'

import { lboxHttpClient, logoutProcess } from '../../utils/api'

const LBOXHttpClientInitializer = ({ children }: PropsWithChildren) => {
  const snackbar = useSnackbar()

  useEffect(() => {
    lboxHttpClient.interceptors.response.use(
      (response) => response,
      (error: unknown) => {
        /** CSR인 경우에만 대응 */
        if (!isAxiosError(error) || isServer()) {
          return Promise.reject(error)
        }

        if (error.response?.data === 'token_error') {
          logoutProcess()

          location.reload()

          return Promise.reject(error)
        }

        if (error.response?.data === 'login_required') {
          logoutProcess()

          location.assign(`/${V2_PREFIX}${LBOX_PAGE_PATH.login.index()}`)

          snackbar.error({ message: '로그인이 필요합니다.' })

          return Promise.reject(error)
        }

        return Promise.reject(error)
      }
    )
  }, [])

  return <>{children}</>
}

export default LBOXHttpClientInitializer
