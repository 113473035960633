import { useEffect, useState } from 'react'

import { isServer } from '@toss/utils'

export function useMatchMedia(query: string) {
  const [matches, setMatches] = useState(isServer() ? false : window.matchMedia(query).matches)

  useEffect(() => {
    const matchMedia = window.matchMedia(query)
    const onChange = () => setMatches(matchMedia.matches)

    if (matchMedia.addEventListener && matchMedia.removeEventListener) {
      matchMedia.addEventListener('change', onChange)

      return () => {
        matchMedia.removeEventListener('change', onChange)
      }
    }

    /** DESC : Safari 13 버전 이하 버전에서는 addEventListener는 존재하지 않습니다. deprecated 된 addListener를 사용합니다. (onchange 프로퍼티 역시 Safari 13 이하에서는 지원되지 않는 것으로 보입니다.) */
    matchMedia.addListener?.(onChange)

    return () => {
      matchMedia.removeListener?.(onChange)
    }
  }, [query])

  return matches
}
