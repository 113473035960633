import { useEffect } from 'react'

import { useRouter } from 'next/compat/router'

import { clearAllBodyScrollLocks } from '@lbox/shared/utils'

export const useGlobalRouterSetting = () => {
  const router = useRouter()

  useEffect(() => {
    if (!router?.events) {
      return
    }

    function handleRouteChangeComplete() {
      clearAllBodyScrollLocks()
    }

    function handleRouteChangeError() {
      clearAllBodyScrollLocks()
    }

    router.events.on('routeChangeComplete', handleRouteChangeComplete)
    router.events.on('routeChangeError', handleRouteChangeError)

    return () => {
      router.events.off('routeChangeComplete', handleRouteChangeComplete)
      router.events.off('routeChangeError', handleRouteChangeError)
    }
  }, [router?.events])
}
