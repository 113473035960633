interface LoadingSpinnerIconProps {
  size?: number
  className?: string
}

export const LoadingSpinnerIcon = ({ size = 16, className }: LoadingSpinnerIconProps) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <g opacity="0.3">
        <path
          d="M9.84306 2.94409C13.8838 2.94409 17.1646 6.22489 17.1646 10.2657C17.1646 14.3065 13.8838 17.5873 9.84306 17.5873C5.80228 17.5873 2.52148 14.3065 2.52148 10.2657C2.52148 6.22489 5.80228 2.94409 9.84306 2.94409Z"
          strokeWidth="2.61062"
        />
      </g>
      <path
        d="M5.77281 4.18149C7.18296 3.237 8.92568 2.77518 10.7406 2.9993C13.7887 3.37571 16.1688 5.57939 16.918 8.37604"
        strokeWidth="2.61062"
        strokeLinecap="round"
      />
    </svg>
  )
}
