import { isServer } from '@toss/utils'

import { consoleTracker } from './consoleTracker'
import { kinesisTracker } from './kinesisTracker'
import type { TrackerInitOptions } from './type'

export let tracker = consoleTracker

export const initTracker = (options: TrackerInitOptions) => {
  tracker = isServer() || options.env === 'local' ? consoleTracker : kinesisTracker
  return tracker.init(options)
}
