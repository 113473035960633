import { useState } from 'react'

import { useRefEffect } from '@toss/react'

/**
 * We have to check if the scroll amount is close enough to some threshold in order to
 * more accurately calculate arrivedState. This is because scrollTop/scrollLeft are non-rounded
 * numbers, while scrollHeight/scrollWidth and clientHeight/clientWidth are rounded.
 * https://developer.mozilla.org/en-US/docs/Web/API/Element/scrollHeight#determine_if_an_element_has_been_totally_scrolled
 */
const ARRIVED_STATE_THRESHOLD_PIXELS = 1

export const useScroll = () => {
  const [position, setPosition] = useState({ scrollTop: 0, scrollLeft: 0 })
  const [arrivedState, setArrivedState] = useState({ top: true, left: true, bottom: false, right: false })

  const ref = useRefEffect<HTMLDivElement>((el) => {
    const handleScroll = (event: Event) => {
      if (!event.target) {
        return
      }

      const { scrollTop, scrollLeft, clientWidth, scrollWidth, clientHeight, scrollHeight } =
        event.target as HTMLElement

      const leftArrived = Math.abs(scrollLeft) <= 0
      const rightArrived = Math.abs(scrollLeft) + clientWidth >= scrollWidth - ARRIVED_STATE_THRESHOLD_PIXELS
      const topArrived = Math.abs(scrollTop) <= 0
      const bottomArrived = Math.abs(scrollTop) + clientHeight >= scrollHeight - ARRIVED_STATE_THRESHOLD_PIXELS

      setPosition({ scrollTop, scrollLeft })
      setArrivedState({ top: topArrived, left: leftArrived, bottom: bottomArrived, right: rightArrived })
    }

    el.addEventListener('scroll', handleScroll)

    return () => el.removeEventListener('scroll', handleScroll)
  }, [])

  return { ref, arrivedState, scrollLeft: position.scrollLeft, scrollTop: position.scrollTop }
}
