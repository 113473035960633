import { useEffect } from 'react'

import useMe from '@lbox-kr/components/src/hooks/useMe'
import { Userpilot } from 'userpilot'

import useUserpilotInit from '@/hooks/userpilot/useUserpilotInit'

export const IdentifyUserpilot = () => {
  const { isInitialized } = useUserpilotInit()
  const { userId, name, email } = useMe()

  useEffect(() => {
    if (isInitialized && userId && name && email) {
      Userpilot.identify(userId, {
        name,
        email,
      })
    }
  }, [isInitialized, email, userId, name])

  return null
}
