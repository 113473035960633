import { KinesisClient, PutRecordCommand } from '@aws-sdk/client-kinesis'
import { fromCognitoIdentityPool } from '@aws-sdk/credential-providers'

import { webLocalStorage } from '../storage'
import { noop } from '../toolkit'
import { createEventProperty } from './eventProperty'
import { storageKey } from './storageKey'
import type { Tracker, TrackerInitOptions, TrackerTrackErrorHandler } from './type'

class KinesisTracker implements Tracker {
  #identityId: string | null = null
  #kinesisClient: KinesisClient | null = null
  #streamName: string | null = null
  #onTrackError: TrackerTrackErrorHandler = noop
  #eventProperty = createEventProperty()
  #readyDefer = createDeferred<null>()

  public readonly key = storageKey

  setUserId = (userId: string) => {
    this.#eventProperty.userId = userId
  }

  setDeviceId = (deviceId: string) => {
    this.#eventProperty.deviceId = deviceId
  }

  setUserIp = (userIp: string) => {
    this.#eventProperty.userIp = userIp
  }

  setUserProperties = (userProperties: Record<string, unknown>) => {
    this.#eventProperty.userProperties = userProperties
  }

  init = async ({
    aws,
    amplitude,
    onTrackError = noop,
    onInitError = noop,
    ipGetter,
    userPropertiesGetter,
  }: TrackerInitOptions) => {
    try {
      this.#eventProperty.amplitudeProject = amplitude.project
      this.#onTrackError = onTrackError

      const setIpPromise = ipGetter?.()
        .then((ip) => ip && this.setUserIp(ip))
        .catch(noop)

      const setUserPropertiesPromise = userPropertiesGetter?.()
        .then((userProperties) => this.setUserProperties(userProperties))
        .catch(noop)

      const cognitoIdentityCredentialProvider = fromCognitoIdentityPool({
        identityPoolId: aws.cognitoIdentityPoolId,
        clientConfig: {
          region: aws.region,
          maxAttempts: 1,
        },
      })

      const [{ identityId }] = await Promise.all([
        cognitoIdentityCredentialProvider(),
        setIpPromise,
        setUserPropertiesPromise,
      ])

      // deviceId를 설정하지 못하는 환경에서는 fallback으로 identityId를 사용한다.
      if (!this.#eventProperty.deviceId) {
        this.#eventProperty.deviceId = identityId
      }

      this.#identityId = identityId
      this.#streamName = aws.kinesisStreamName
      this.#kinesisClient = new KinesisClient({
        region: aws.region,
        credentials: cognitoIdentityCredentialProvider,
      })
    } catch (error) {
      onInitError(error)
    } finally {
      this.#readyDefer.resolve(null)
    }
  }

  track = async (
    eventType: string,
    eventProperties: Record<string, unknown> = {},
    onTrackError: TrackerTrackErrorHandler = noop
  ) => {
    await this.#readyDefer.promise

    // 초기화 중 cognito 요청이 실패하면 kinesisClient 인스턴스가 만들어지지 않는다.
    if (!this.#kinesisClient || !this.#streamName) {
      return
    }

    try {
      const properties = this.#eventProperty.format(eventType, eventProperties)

      webLocalStorage.setItem(this.key.PREV_EVENT_TYPE, eventType)
      webLocalStorage.setItem(this.key.PREV_EVENT_ID, properties.eventId)

      const command = new PutRecordCommand({
        StreamName: this.#streamName,
        PartitionKey: this.#identityId || '',
        Data: new TextEncoder().encode(JSON.stringify({ ...properties, event: JSON.stringify(properties.event) })),
      })

      await this.#kinesisClient.send(command)
    } catch (error) {
      this.#onTrackError({ error, eventProperties })
      onTrackError({ error, eventProperties })
    }
  }
}

export const kinesisTracker = new KinesisTracker()

function createDeferred<T>() {
  let resolve!: (value: T) => void
  let reject!: (error: unknown) => void
  const promise = new Promise<T>((r, j) => {
    resolve = r
    reject = j
  })
  return { promise, resolve, reject }
}
