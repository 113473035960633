import { disableBodyScroll } from 'body-scroll-lock-upgrade'

export { clearAllBodyScrollLocks } from 'body-scroll-lock-upgrade'

export const ALLOW_SCROLL_DATA_ATTR = 'data-allow-scroll'

/**
 * body-scroll-lock 을 사용하여 body element 의 scroll 을 막는다
 *
 * `allowTouchMove` 는 iOS Safari 에서 body-scroll-lock enable 되었을때,
 * 특정 attribute 값을 가진 요소만 scroll 이 가능하도록 해주기 위한 method
 */
export const disableBodyScrollWithAllowingTouchMove = () => {
  disableBodyScroll(document.body, {
    allowTouchMove: (el) => {
      return !!(el as HTMLElement).closest(`[${ALLOW_SCROLL_DATA_ATTR}]`)
    },
  })
}
