export const API_ENDPOINT = {
  case: {
    index: '/case',
  },
  myCaseRegister: {
    base: '/myCaseRegister',
  },
  upload: {
    myCase: '/upload/myCase',
    lawSchool: '/upload/lawSchool',
  },
  lboxUser: {
    base: '/lboxUser',
    verifyLawyer: '/lboxUser/verifyLawyer',
  },
  user: {
    logout: '/user/logout',
    updateData: '/user/updateData',
  },
  lawSchoolAuthorization: {
    base: '/lawSchoolAuthorization',
  },
  userHasPlan: {
    base: '/userHasPlan',
    last: '/userHasPlan/last',
    membershipInfo: '/userHasPlan/membershipInfo',
    registered: '/userHasPlan/registered',
  },
  judge: {
    list: '/judge/list',
  },
  routeHandler: {
    searchCommandRequestNotified: '/api/get-search-command-request-notified',
  },
  clientTracking: '/clientTracking',
} as const

export const LFIND_API_ENDPOINT = {
  lawyer: {
    myCases: {
      base: '/lawyer/my-cases',
      byDocId: (docId: string) => `/lawyer/my-cases/${docId}`,
      commentByDocId: (docId: string) => `/lawyer/my-cases/${docId}/comment`,
    },
    profile: {
      base: '/lawyer/profile',
      byId: (lawyerId: string | number) => `/lawyer/profile/${lawyerId}`,
      done: '/lawyer/profile/done',
    },
    myCaseRegister: {
      base: '/lawyer/my-case-register',
      upload: '/lawyer/my-case-register/upload',
    },
  },
  cases: {
    categoriesByDocId: (docId: string) => `/cases/${docId}/categories`,
    summaryByDocId: (docId: string) => `/cases/${docId}/summary`,
    categories: {
      nested: '/cases/categories/nested',
    },
  },
  specialities: '/specialities',
} as const
