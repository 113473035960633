'use client'

import { useState } from 'react'

import { ErrorBoundaryWithFallback, FeatureFlagProvider } from '@lbox/shared/components'
import { FEATURE_FLAG_APP_NAME } from '@lbox/shared/constants'
import { useCustomViewportUnit } from '@lbox/shared/hooks'
import { initLogger, webSessionStorage } from '@lbox/shared/utils'

import LBOXHttpClientInitializer from '@lbox-kr/components/src/components/lbox-http-client-intializer/LBOXHttpClientInitializer'
import { Modal as GlobalErrorModal, Toast } from '@lbox-kr/libra/v1'
import { Modal as GlobalModal, SnackbarProvider } from '@lbox-kr/libra/v2'
import type { DehydratedState } from '@tanstack/react-query'
import { HydrationBoundary, QueryCache, QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { isServer } from '@toss/utils'
import { OverlayProvider } from 'overlay-kit'

import EzwelAppBottomSheet from '@/components/index/ezwel-app-bottom-sheet/EzwelAppBottomSheet'
import ExternalScripts from '@/components/scripts/ExternalScripts'
import GlobalErrorBoundary from '@/components/shared/global-error-boundary/GlobalErrorBoundary'
import { IdentifyUserpilot } from '@/components/shared/IdentifyUserpilot'
import { MyCaseCandidatePopup } from '@/components/shared/mycase-candidate/MyCaseCandidatePopup'
import { UserLog } from '@/components/shared/UserLog'
import { ENV } from '@/constants/env'
import { useFingerprintInit } from '@/hooks/fingerprint/useFingerprintInit'
import { useGlobalRouterSetting } from '@/hooks/router/useGlobalRouterSetting'
import { useGlobalErrorModal } from '@/hooks/useGlobalErrorModal'
import { useGlobalModal } from '@/hooks/useGlobalModal'
import { useInvalidUrlRedirection } from '@/hooks/useInvalidUrlRedirection'
import { useOpenExternalBrowser } from '@/hooks/useOpenExternalBrowser'
import { useAmplitudeInit } from '@/hooks/userTracking/useAmplitudeInit'
import { useHandleRouteChangeComplete } from '@/hooks/userTracking/useHandleRouteChangeComplete'
import useToast from '@/hooks/useToast'
import { initTrackers } from '@/utils/trackers'

import '@/styles/globals.css'
// react-datepicker custom styling
import '@/styles/lbox-react-datepicker.css'
import '@/styles/reactRangeSliderInputCustomStyle.css'
// 서버에서 내려주는 html 태그 styling 용 css 파일
import '@/styles/serverRenderedContent.css'
// text viewer에 사용되는 css 파일
import '@/styles/textViewer.css'
import 'react-range-slider-input/dist/style.css'

import '@/components/pricing/pricing/hero-section/plan-card/plan-card.css'
import '@/types/react-query-extends'

initLogger({
  env: ENV.NEXT_PUBLIC_ENV,
  datadog: {
    clientToken: ENV.NEXT_PUBLIC_DATADOG_CLIENT_TOKEN,
    env: ENV.NEXT_PUBLIC_DATADOG_ENV,
    service: ENV.NEXT_PUBLIC_DATADOG_SERVICE,
    releaseVersion: ENV.NEXT_PUBLIC_DATADOG_RELEASE_VERSION,
  },
})
initTrackers()

export default function AppProvider({
  children,
  dehydratedState,
}: {
  children: React.ReactNode
  dehydratedState?: DehydratedState | null
}) {
  const [queryClient] = useState(
    () =>
      new QueryClient({
        defaultOptions: {
          queries: {
            refetchOnWindowFocus: false,
          },
          mutations: {},
        },
        queryCache: new QueryCache({
          onSuccess: (data, query) => {
            const onSuccessCallback = query.options.meta?.onSuccess
            if (typeof onSuccessCallback === 'function') {
              onSuccessCallback(data)
            }
          },
          onError: (error, query) => {
            const onErrorCallback = query.options.meta?.onError
            if (typeof onErrorCallback === 'function') {
              onErrorCallback(error)
            }
          },
          onSettled: (data, error, query) => {
            const onSettledCallback = query.options.meta?.onSettled
            if (typeof onSettledCallback === 'function') {
              onSettledCallback(data, error)
            }
          },
        }),
      })
  )

  useCustomViewportUnit()
  useAmplitudeInit()
  useFingerprintInit()
  useHandleRouteChangeComplete()
  useGlobalRouterSetting()
  useOpenExternalBrowser()
  useInvalidUrlRedirection()

  return (
    <SnackbarProvider>
      <LBOXHttpClientInitializer>
        <ExternalScripts />

        <QueryClientProvider client={queryClient}>
          <GlobalErrorBoundary>
            <OverlayProvider>
              <FeatureFlagProvider
                url={ENV.NEXT_PUBLIC_UNLEASH_ENDPOINT}
                clientKey={ENV.NEXT_PUBLIC_UNLEASH_API_TOKEN}
                appName={FEATURE_FLAG_APP_NAME.lboxClientNextjs}
              >
                <HydrationBoundary state={dehydratedState}>
                  <UserLog>
                    <IdentifyUserpilot />
                    {/* FIXME: 임시로 추가한 error boundary */}
                    <ErrorBoundaryWithFallback fallback={<></>}>
                      <MyCaseCandidatePopup />
                    </ErrorBoundaryWithFallback>
                    {children}
                  </UserLog>
                </HydrationBoundary>

                <GlobalAlerts />

                <GlobalPopUps />

                <ReactQueryDevtools initialIsOpen={false} />
              </FeatureFlagProvider>
            </OverlayProvider>
          </GlobalErrorBoundary>
        </QueryClientProvider>
      </LBOXHttpClientInitializer>
    </SnackbarProvider>
  )
}

const GlobalAlerts = () => {
  const { isOpen, message, actionLabel, position, autoHideDuration, closeToast } = useToast()
  const {
    isOpen: isGlobalErrorModalOpen,
    title: globalErrorModalTitle,
    rightButtonInformation: globalErrorModalRightButtonInformation,
    content: globalErrorModalRightButtonInformationContent,
    close: closeGlobalErrorModal,
  } = useGlobalErrorModal()
  const { globalModalStatus } = useGlobalModal()

  return (
    <>
      <Toast
        isOpen={isOpen}
        message={message}
        actionLabel={actionLabel}
        autoHideDuration={autoHideDuration}
        position={position}
        closeToast={closeToast}
      />
      <GlobalErrorModal
        isOpen={isGlobalErrorModalOpen}
        title={globalErrorModalTitle}
        rightButtonInformation={globalErrorModalRightButtonInformation}
        onClickBackdrop={closeGlobalErrorModal}
      >
        {globalErrorModalRightButtonInformationContent}
      </GlobalErrorModal>
      <GlobalModal {...globalModalStatus} />
    </>
  )
}

const GlobalPopUps = () => {
  const isEzwelBottomSheetClosed =
    webSessionStorage.getItem<boolean | null>('isEzwelAppBottomSheetClosed', null) === true
  const isEzwelApp = !isServer() && !isEzwelBottomSheetClosed && navigator.userAgent.includes('ezwelpayios')

  return <>{isEzwelApp && <EzwelAppBottomSheet />}</>
}
