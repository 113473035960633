export const LBOX_AI_PAGE_ROOT_PATH = '/ai'
export const LBOX_AI_PAGE_PATH = {
  ai: {
    landing: {
      index: `${LBOX_AI_PAGE_ROOT_PATH}/landing`,
      notice: `${LBOX_AI_PAGE_ROOT_PATH}/landing/notice`,
      guide: `${LBOX_AI_PAGE_ROOT_PATH}/landing/guide`,
      start: `${LBOX_AI_PAGE_ROOT_PATH}/landing/blog/index`,
      blog: ({ name, index }: { name: string; index: number }) =>
        `${LBOX_AI_PAGE_ROOT_PATH}/landing/blog/${name}#${index}`,
    },
    main: `${LBOX_AI_PAGE_ROOT_PATH}`,
    apply: `${LBOX_AI_PAGE_ROOT_PATH}/apply`,
    chat: `${LBOX_AI_PAGE_ROOT_PATH}/chat`,
    specificChat: (id: number) => `${LBOX_AI_PAGE_ROOT_PATH}/chat/${id}`,
  },
} as const
